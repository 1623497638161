import { isString, mapDate, isDate, FilterType } from 'lib/table'
// import { textFieldFactory, pickerFactory, datePickerFactory } from 'components/Table/FilterFactories'

const filters: FilterType[] = [
  // not working in backend yet
  // {
  //   display: 'Username',
  //   name: 'customer',
  //   filter: textFieldFactory({
  //     label: 'Username',
  //     placeholder: 'Enter username...'
  //   }),
  //   mapping: (value:string):string => value,
  //   validator: isString
  // },
]

export default filters
