import config from 'config'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { Breadcrumbs, Item } from 'components/modules/Breadcrumbs'
import { TextField, Flex, ActionButton } from '@adobe/react-spectrum'
import { useRoutes } from 'routes'
import { useParams } from 'react-router-dom'
import { withProfile } from './withProfile'
import { useAuth } from 'context/authContext'
import { useToast } from 'components/modules/Toast'

interface Profile {
  username: string
  email: string
}

interface ProfileProps {
  profile: Profile
}

const ProfilesVerifyEmailLayout = (props: ProfileProps) => {
  const { profile } = props
  const { username } = useParams<{ username: string }>()
  const { profilePath, profilesPath, profileVerifyEmailPath } = useRoutes()

  return (
    <Layout>
      <Content variant="article" heading="Verify Email">
        <Breadcrumbs>
          <Item to={profilesPath()}> Profiles </Item>
          <Item to={profilePath({ username })}> {username} </Item>
          <Item to={profileVerifyEmailPath({ username })}> Verify Email </Item>
        </Breadcrumbs>
        <ProfileVerifyEmail profile={profile} />
      </Content>
    </Layout>
  )
}

const ProfileVerifyEmail = (props: ProfileProps) => {
  const { profile } = props
  const { username, email } = profile
  const { accessToken } = useAuth()
  const { addToast } = useToast()

  const onSubmit = async () => {
    try {
      const rawResponse = await fetch(config.verifyEmailUrl, {
        method: 'PUT',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ username, email }),
      })

      const content = await rawResponse.json()

      if (rawResponse.status === 200) {
        addToast({
          variant: 'positive',
          message: 'Email has been verified',
          timeout: 3000,
        })
      } else {
        addToast({
          variant: 'negative',
          message: content.message,
          timeout: 3000,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <p>
        Verify the email of this user <strong>{username}</strong>.
      </p>
      <Flex direction="column" gap="size-200">
        <TextField
          label="Username"
          labelPosition="top"
          value={username}
          isDisabled={true}
        />
        <TextField
          label="Email Address"
          labelPosition="top"
          value={email}
          isDisabled={true}
        />
        <ActionButton width={150} onPress={() => onSubmit()} type="submit">
          Verify Email
        </ActionButton>
      </Flex>
    </div>
  )
}

export default withProfile(ProfilesVerifyEmailLayout)
