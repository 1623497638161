import { useParams } from 'react-router-dom'
import { useTransactionData } from 'api/mesh/transactions'
import { ProgressBar } from '@adobe/react-spectrum'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { useRoutes } from 'routes'
import { Breadcrumbs, Item } from 'components/modules/Breadcrumbs'
import AddressTable from 'components/containers/AddressTable'
import TransactionDataTable from 'components/containers/TransactionDataTable'
import OrderItemsBasicTable from 'components/Tables/OrderItemsBasicTable'

export default function Order() {
  const { id } = useParams<{ id: string }>()
  const { orderPath, ordersPath } = useRoutes()
  const { loading, error, data: transaction } = useTransactionData({ id })

  const renderErrors = (transaction: any) => {
    const errors = transaction.items.map((item: any) => item.error).filter((item: string) => item);
    const infos = transaction.items.map((item: any) => item.info).filter((item: string) => item);
    const messages: string[] = [...new Set(errors.concat(infos))].map(x => x?.toString() || "");
    if (messages.length > 0) {
      return (
        <>
          <h3>Errors</h3>
          {
            messages.slice(0, 1).map((error: string) => {
              return (
                <p style={{color: "red"}}>{error}</p>
              )
            }
          )}
        </>
      )
    }
  }

  const renderTransactionData = () => {
    if (loading) return <ProgressBar label='Loading…' isIndeterminate />
    if (error) return <div>Error fetching data</div>
    if (!transaction) return <div>Order not found.</div>

    return (
      <>
        <TransactionDataTable transaction={ transaction } />
        <AddressTable address={ transaction.address } />
        { renderErrors(transaction) }
        <OrderItemsBasicTable orderItems={ transaction.items } />
      </>
    )
  }

  return (
    <Layout>
      <Content variant='article' heading='Order Details'>

        <Breadcrumbs>
          <Item to={ ordersPath() }> Orders </Item>
          <Item to={ orderPath({ id }) }> { id } </Item>
        </Breadcrumbs>

        { renderTransactionData() }
      </Content>
    </Layout>
  )
}
