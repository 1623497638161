import React from 'react'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { ProgressBar } from '@adobe/react-spectrum'
import { useQuery } from '@apollo/client'
import { CREDITS_BY_DATE_QUERY, DashboardData } from 'api/credits'

import {
  CreditsLineChart,
  MonthlySalesChart,
  MonthlyCreditsChart,
  MonthlyCreditsByTypeChart
} from 'components/Charts'

import {
  Grid,
  View,
  repeat
} from '@adobe/react-spectrum'


const DashboardLayout = () => (
  <Layout>
    <Content heading='Dashboard'>
      <Dashboard />
    </Content>
  </Layout>
)

const Dashboard = () => {
  const { loading, error, data } = useQuery<DashboardData>(CREDITS_BY_DATE_QUERY, {
    variables: {
    }
  })

  if (loading) return <ProgressBar label='Loading…' isIndeterminate />
  if (error || !data) return <div>Error fetching data</div>

  return (
    <>
      <Grid columns={repeat('auto-fit', '1fr')} gap="size-100">
        <View><MonthlySalesChart data={ data.monthlySalesReport } /></View>
        <View><CreditsLineChart data={ data } /></View>
        <View><MonthlyCreditsChart data={ data.creditsByDate } /></View>
        <View><MonthlyCreditsByTypeChart data={ data.creditsByDate } /></View>
      </Grid>
    </>
  )
}

export default DashboardLayout
