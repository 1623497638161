import { gql } from '@apollo/client'

const TRANSACTION_LIST_QUERY = gql`
  query Transactions(
    $skip: Int
    $limit: Int
    $id: Int
    $transactionId: Int
    $transactionCode: String
    $amount: Float
    $amountMin: Float
    $amountMax: Float
    $paymentMethod: String
    $invoiceId: Int
    $invoiceKey: String
    $username: String
    $createdAtMin: DateTime
    $createdAtMax: DateTime
    $paymentStatus: String
    $sort: SortableField
    $sortOrder: SortOrder
  ) {
    transactions(
      id: $id
      transactionId: $transactionId
      transactionCode: $transactionCode
      amount: $amount
      amountMin: $amountMin
      amountMax: $amountMax
      paymentMethod: $paymentMethod
      invoiceId: $invoiceId
      invoiceKey: $invoiceKey
      username: $username
      createdAtMin: $createdAtMin
      createdAtMax: $createdAtMax
      paymentStatus: $paymentStatus
      skip: $skip
      limit: $limit
      sort: $sort
      sortOrder: $sortOrder
    ) {
      transactions {
        id
        transactionId
        transactionCode
        amount
        shippingTotal
        currencyCode
        paymentMethod
        invoiceId
        invoiceKey
        username
        createdAt
        paymentStatus
      }
      total
    }
  }
`

const TRANSACTION_DETAILS_QUERY = gql`
  query Transactions($id: Int) {
    transactions(id: $id) {
      transactions {
        id
        transactionId
        transactionCode
        amount
        shippingTotal
        currencyCode
        paymentMethod
        invoiceId
        invoiceKey
        username
        createdAt
        paymentStatus
        shipping
        profile {
          email
        }
        address {
          fullName
          companyName
          addressLine1
          addressLine2
          city
          state
          country
          zipCode
          telephone
          email
        }
        items {
          id
          edition
          status
          title
          ISBN13
          cover
          slug
          type
          supplier
          trackingId
          trackingURL
          dispatchedDate
          expectedDeliveryDate
          actualDeliveryDate
          quantity
          currencyCode
          currencySymbol
          currentPrice
          taxRate
          tax
          netTotal
          info
          message
          error
          totalAmount
        }
      }
    }
  }
`

const ORDER_ITEMS_LIST_QUERY = gql`
  query OrderItems(
    $skip: Int
    $limit: Int
    $id: Int
    $orderId: Int
    $username: String
    $isbn: String
    $status: String
    $createdAtMin: DateTime
    $createdAtMax: DateTime
    $sort: OrderItemSortableField
    $sortOrder: SortOrder
  ) {
    orderItems(
      skip: $skip
      limit: $limit
      id: $id
      orderId: $orderId
      username: $username
      isbn: $isbn
      status: $status
      createdAtMin: $createdAtMin
      createdAtMax: $createdAtMax
      sort: $sort
      sortOrder: $sortOrder
    ) {
      orderItems {
        id
        orderId
        username
        edition
        status
        title
        ISBN13
        cover
        slug
        type
        supplier
        trackingId
        trackingURL
        dispatchedDate
        expectedDeliveryDate
        actualDeliveryDate
        quantity
        currencyCode
        currencySymbol
        currentPrice
        taxRate
        tax
        netTotal
        totalAmount
        createdAt
      }
      total
    }
  }
`

export {
  TRANSACTION_LIST_QUERY,
  TRANSACTION_DETAILS_QUERY,
  ORDER_ITEMS_LIST_QUERY,
}
