import { useParams } from 'react-router-dom'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { fetchEpub } from 'api/details/catalog'
import { ProgressBar } from '@adobe/react-spectrum'
import { useAuth } from 'context/authContext'
import { useEffect, useState } from 'react'
import { Table, Item } from 'components/modules/KeyValueTable'
import { useRoutes } from 'routes'
import config from 'config'

export function Epub() {
  const { id } = useParams<{ id: string }>()
  const { accessToken } = useAuth()
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any | undefined>(undefined);
  const [error, setError] = useState<string | undefined>()
  const { CatalogEditionLink, CatalogSupplierLink, CatalogProductLink } = useRoutes()

  useEffect(() => {
    (async () => {
      try {
        const result: any = await fetchEpub(id, accessToken);
        setData(result)
        setLoading(false)
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message)
        } else {
          setError("Error loading data");
        }
        setLoading(false)
      }
    })()
  }, [accessToken, id])

  const render = () => {
    if (loading) return <ProgressBar label='Loading…' isIndeterminate />
    if (error) return <p>{ error }</p>
    if (!data?.epub) return <p>No record found</p>
    return <div>
    <section>
      <Table>
        <Item label='Epub Id'>{ data.epub.id } </Item>
        <Item label='Edition Id'><CatalogEditionLink id={ data.epub.edition_id }>{ data.epub.edition_id }</CatalogEditionLink></Item>
        <Item label='Supplier Id'><CatalogSupplierLink id={ data.epub.supplier_id }>{ data.epub.supplier_id }</CatalogSupplierLink></Item>
        <Item label='Product Id'><CatalogProductLink id={ data.epub.supplier_product_id }>{ data.epub.supplier_product_id }</CatalogProductLink></Item>
        <Item label='Source'>{ data.epub.source } </Item>
        <Item label='Key'>{ data.epub.key } </Item>
      </Table>
    </section>
    <section>
      <form action={ `${config.apiUrl}/admin-api/download/epub` } method="POST">
        <input type="hidden" name="auth-token" value={ accessToken }></input>
        <input type="hidden" name="key" value={ data.epub.key }></input>
        <input type="submit" value="Download"></input>
      </form>
    </section>
    </div>

  }

  return (
    <Layout>
      <Content variant='article' heading='Epub'>
        { render() }
      </Content>
    </Layout>
  )
}
