import React, { useState, useEffect } from 'react'
import './Login.css'
import { Layout, Content } from 'components/layouts/LoggedOut'
import SubmitButton from './SubmitButton'
import { Flex, TextField, ButtonGroup } from '@adobe/react-spectrum'
import { signin, me, SigninSuccess, MeSuccess, AuthFailure } from 'api/auth'
import { User } from 'types/user';
import { useLoginForm } from './LoginForm'

interface LoginProps {
  setUser: (user: User) => void
  setAccessToken: (accessToken: string) => void
  accessToken: string
}

export default function Login(props: LoginProps) {
  const {formData, setFormData} = useLoginForm()
  const [signingIn, setSigningIn] = useState(false)
  const [isDisabled, setIsDisabled] = useState<Boolean>(true)
  const [infoMessage, setInfoMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const { setUser, setAccessToken, accessToken } = props

  useEffect(() => {
    const valid = [
      formData?.username.trim(),
      formData?.password.trim()
    ].every(Boolean)
    setIsDisabled(!valid)
  }, [formData])

  const signinFailed = (errorMessage: string) => {
    setSigningIn(false)
    setInfoMessage('')
    setErrorMessage(errorMessage)
    setIsDisabled(true)
  }

  const startSignin = (infoMessage: string) => {
    setSigningIn(true)
    setInfoMessage(infoMessage)
    setErrorMessage('')
  }

  const handleSignin = async (accessToken: string) => {
    startSignin('Fetching user..')
    const result = await me(accessToken)

    if (result instanceof MeSuccess) {
      if (result.isAdmin) {
        setAccessToken(accessToken)
        setUser({
          username: result.username,
          accessToken: accessToken,
          isAdmin: result.isAdmin
        })
      } else {
        signinFailed('user is not an administrator')
        setAccessToken('')
      }
    }

    if (result instanceof AuthFailure) {
      signinFailed(result.message || 'accessToken invalid')
      setAccessToken('')
    }
  }

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    startSignin('Signing in..')
    const result = await signin(formData.username, formData.password)
    if (result instanceof AuthFailure) {
      signinFailed(result.message)
    }

    if (result instanceof SigninSuccess) {
      handleSignin(result.accessToken)
    }
  }

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setErrorMessage('')
    setFormData({
      name: event.currentTarget.name,
      value: event.currentTarget.value,
    });
  }

  if (!signingIn && accessToken) {
    handleSignin(accessToken)
  }

  // if (signingIn) {
  //   return <div>Loading..</div>
  // }

  return(
    <Layout>
      <Content variant="article">
        <h1>Log In</h1>
        <form onSubmit={ handleLogin } >
          <Flex direction="column" gap="size-100">
            <TextField name="username" label="Username" isDisabled={ signingIn } onInput={ handleChange } />
            <TextField name="password" label="Password" isDisabled={ signingIn } onInput={ handleChange } type="password" />

            <span className="error">{ errorMessage }</span>

            <ButtonGroup>
              <SubmitButton isDisabled={ isDisabled } isLoading={ signingIn } />
            </ButtonGroup>

            <span className="info">{ infoMessage }</span>
          </Flex>
        </form>
      </Content>
    </Layout>
  )
}
