import Card from 'components/modules/Card'
import { DashboardData } from 'api/credits'
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { format, getMonth } from 'date-fns'

import {
  chartProps,
  styleProps,
  hGridProps,
  numberAxisProps,
  intervalAxisProps,
  lineProps,
  labelFormatter,
} from 'components/Charts/charts'

export const CreditsLineChart = ({ data }: { data: DashboardData }) => {
  const mydata = data.cumulativeCredits.buckets.map(({ date, credits }: { date: string, credits: number }) => ({
    date,
    credits,
  }))

  const tickFormatterXAxis = (tick: string) => {
    const date = new Date(tick)
    const m = getMonth(date)
    if (m % 6 !== 0) return ''
    return format(date, 'MMM yy')
  }

  return (
    <Card heading="Credits">
      <ResponsiveContainer height={300}>
        <ComposedChart data={mydata} {...chartProps}>
          <style {...styleProps} />
          <CartesianGrid {...hGridProps} />
          <Tooltip labelFormatter={labelFormatter.months} />
          <XAxis
            dataKey="date"
            tickFormatter={tickFormatterXAxis}
            {...intervalAxisProps}
          />
          <YAxis dataKey="credits" {...numberAxisProps} />
          {/* @ts-ignore */}
          <Line dataKey="credits" stroke="#8884d8" {...lineProps} />
        </ComposedChart>
      </ResponsiveContainer>
    </Card>
  )
}

export default CreditsLineChart
