import { gql } from '@apollo/client';

const UPDATE_CASH_OUT_MUTATION = gql`
  mutation UpdateCashOut($status: CashOutStatusEnum!, $id: Int!) {
    updateCashOut(status: $status, id: $id) {
      id
      amount
      credits
      creditLog
      status
      createdAt
      updatedAt
    }
  }
`;

const CASHOUT_LIST_QUERY = gql`
  query CashOuts($skip: Int,
                 $limit: Int,
                 $filter: JSON,
                 $sort: String,
                 $type: FromType) {
    getCashOuts(
             skip: $skip,
             limit: $limit,
             sort: $sort,
             filter: $filter,
             type: $type) {
      count
      list {
        id
        amount
        credits
        creditLog
        status
        profile {
          username
        }
        createdAt
        updatedAt
      }
    }
  }
`;

const CASHOUT_DETAILS_QUERY = gql`
  query CashOuts($skip: Int,
                 $limit: Int,
                 $filter: JSON,
                 $type: FromType) {
    getCashOuts(
             skip: $skip,
             limit: $limit,
             filter: $filter,
             type: $type) {
      count
      list {
        id
        amount
        credits
        creditLog
        status
        profile {
          username
          email
          displayName
        }
        address {
          credits
          amount
          fullName
          companyName
          taxNumber
          address
          country
          state
          city
          zipCode
          telephone
          vatId
          iban
          bic
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export {
  CASHOUT_LIST_QUERY,
  CASHOUT_DETAILS_QUERY,
  UPDATE_CASH_OUT_MUTATION
}

