
import React from 'react'
import { Table, Item } from 'components/modules/KeyValueTable'
import { formatDate, formatCurrency } from 'lib/format'
import { useLazyQuery } from '@apollo/client'
import { INVOICE_URL_QUERY } from 'api/transactions'
import { Link } from '@adobe/react-spectrum'

const ExternalInvoiceLink = ({ invoiceId, invoiceKey }: { invoiceId: number, invoiceKey: string }) => {
  const [openInvoice] = useLazyQuery(INVOICE_URL_QUERY, {
    variables: { invoiceId },
    onCompleted: (data) => window.open(data.invoiceDownloadUrl)
  })
  if (invoiceKey) {
    return <Link onPress={ (_e) => openInvoice() } children={ invoiceKey } />
  } else {
    return <div>No invoice key</div>
  }
}

export const TransactionDataTable = ({ transaction }: any) => {
  const email: string = transaction.profile ? transaction.profile.email : 'User not found'

  return (
    <section>
      <Table>
        <Item label='Amount'>{ formatCurrency(transaction.amount, transaction.currencyCode) }</Item>
        <Item label='Date'>{ formatDate(transaction.createdAt) }</Item>
        <Item label='Invoice Id'>{ transaction.invoiceId }</Item>
        <Item label='Order Id'>{ transaction.id }</Item>
        <Item label='Transaction Code'>{ transaction.transactionCode }</Item>
        <Item label='Payment Method'>{ transaction.paymentMethod }</Item>
        <Item label='Payment Status'>{ transaction.paymentStatus }</Item>
        <Item label='invoiceKey'>
          <ExternalInvoiceLink { ...transaction } />
        </Item>
        <Item label='shippingTotal'>{ formatCurrency(transaction.shippingTotal, transaction.currencyCode) }</Item>
        <Item label='username'>{ transaction.username }</Item>
        <Item label='email'>{ email }</Item>
      </Table>
    </section>
  )
}

export default TransactionDataTable
