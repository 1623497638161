import React from 'react'
import Table from 'components/Table/Table'
import columnsConfiguration from './columns'
import filterTypes from './filters'
import { useApolloClient } from '@apollo/client'
import { TRANSACTION_LIST_QUERY } from 'api/transactions'
import { FetchDataProps } from 'lib/table'

const TransactionsTable: React.FC<{ username?: string; tableState: any }> = ({
  username,
  tableState,
}) => {
  const client = useApolloClient()
  const columns = React.useMemo(() => columnsConfiguration, [])

  const {
    setSortBy,
    setPage,
    fetchIdRef,
    setLoading,
    setData,
    setPageCount,
    setError,
  } = tableState

  const fetchData = React.useCallback(
    async ({ pageIndex, sortBy, filters }: FetchDataProps) => {
      const pageSize = 50

      setSortBy(sortBy)
      setPage(pageIndex)
      const fetchId = ++fetchIdRef.current
      setLoading(true)
      const sorting = sortBy[0]
      try {
        const results = await client.query({
          query: TRANSACTION_LIST_QUERY,
          variables: {
            skip: pageSize * pageIndex,
            limit: pageSize,
            sortOrder: sorting ? (sorting.desc ? 'desc' : 'asc') : null,
            ...filters,
          },
        })
        if (fetchId === fetchIdRef.current) {
          setData(results.data.transactions.transactions)
          setPageCount(Math.ceil(results.data.transactions.total / pageSize))
          setLoading(false)
        }
      } catch (e) {
        console.error('Error fetching data', e)
        setError(true)
      }
    },
    [
      client,
      setSortBy,
      setPageCount,
      setPage,
      setLoading,
      setError,
      setData,
      fetchIdRef,
    ]
  )

  if (!tableState) return null

  return (
    <Table
      filterTypes={filterTypes}
      columns={columns}
      data={tableState.data}
      loading={tableState.loading}
      fetchData={fetchData}
      pageCount={tableState.pageCount}
      page={tableState.page}
      filters={tableState.filters}
      setFilters={tableState.setFilters}
      sortBy={tableState.sortBy}
      error={tableState.error}
    />
  )
}

export const StatefulTransactionsTable: React.FC<{ username?: string }> = ({
  username,
}) => {
  const [data, setData] = React.useState<any[]>([])
  const [filters, setFilters] = React.useState<object>(
    username ? { username } : {}
  )
  const [sortBy, setSortBy] = React.useState<any[]>([])
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [pageCount, setPageCount] = React.useState(0)
  const [page, setPage] = React.useState(0)
  const fetchIdRef = React.useRef(0)

  const tableState = {
    data,
    setData,
    filters,
    setFilters,
    sortBy,
    setSortBy,
    loading,
    setLoading,
    pageCount,
    setPageCount,
    fetchIdRef,
    page,
    setPage,
    error,
    setError,
  }

  return <TransactionsTable tableState={tableState} username={username} />
}

export default TransactionsTable
