import React from 'react'
import { ActionButton } from '@adobe/react-spectrum'
import ChevronLeft from '@spectrum-icons/ui/ChevronLeftMedium'
import ChevronRight from '@spectrum-icons/ui/ChevronRightMedium'
import '@spectrum-css/calendar/dist/index-vars.css'

import { useCalendar, useEachWeekOfMonth } from './hooks'
import CalendarTHead from './CalendarTHead'
import CalendarTCell from './CalendarTCell'

interface IPrevMonthButton {
  onPress?: () => void
}

const PrevMonthButton = ({ onPress }: IPrevMonthButton) => (
  <ActionButton
    onPress={onPress}
    isQuiet
    UNSAFE_className="spectrum-Calendar-prevMonth"
  >
    <ChevronLeft />
  </ActionButton>
)

interface INextMonthButton {
  onPress?: () => void
}

const NextMonthButton = ({ onPress }: INextMonthButton) => (
  <ActionButton
    onPress={onPress}
    isQuiet
    UNSAFE_className="spectrum-Calendar-nextMonth"
  >
    <ChevronRight />
  </ActionButton>
)

const CalendarTBody = ({ date, selected, setSelected }: { date: Date, selected: Date, setSelected: (date: Date) => void }) => {
  const weeks = useEachWeekOfMonth(date)

  return (
    <tbody role="presentation">
      {weeks.map((week) => (
        <tr role="row">
          {week.map((day) => (
            <CalendarTCell
              day={day}
              month={date}
              selected={selected}
              setSelected={setSelected}
            />
          ))}
        </tr>
      ))}
    </tbody>
  )
}

interface IProps {
  selectedDay: Date
  onSelectionChange: (val: any) => void
}

export const Calendar = ({ selectedDay, onSelectionChange }: IProps) => {
  const calendar = useCalendar({ selectedDay, onSelectionChange })

  const { date, month, year, prevMonth, nextMonth, selected } = calendar

  const selectionChangeHandler = (selected: Date) => {
    onSelectionChange(selected)
  }

  return (
    <div className="spectrum-Calendar">
      <div className="spectrum-Calendar-header">
        <div
          className="spectrum-Calendar-title"
          role="heading"
          aria-level={3}
          aria-live="assertive"
          aria-atomic="true"
        >
          {month} {year}
        </div>

        <PrevMonthButton onPress={prevMonth} />
        <NextMonthButton onPress={nextMonth} />
      </div>

      <div
        className="spectrum-Calendar-body"
        role="grid"
        tabIndex={0}
        aria-readonly="true"
        aria-disabled="false"
      >
        <table role="presentation" className="spectrum-Calendar-table">
          <CalendarTHead />
          <CalendarTBody
            date={date}
            selected={selected}
            setSelected={selectionChangeHandler}
          />
        </table>
      </div>
    </div>
  )
}

export default Calendar
