import React from 'react';
import BasicTable from 'components/Table/BasicTable'
import columns from './columns'

export const ProductRelatedProductsTable = ({ relatedProducts }: { relatedProducts: any[] }) => {
  if (!relatedProducts || relatedProducts.length == 0) {
    return (
      <section>
        <p>No related product</p>
      </section>
    )
  }
  return (
    <section>
      <BasicTable columns={ columns } data={ relatedProducts } />
    </section>
  )
}
