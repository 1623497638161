import { useParams } from 'react-router-dom'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { fetchImage } from 'api/details/catalog'
import { ProgressBar } from '@adobe/react-spectrum'
import { useAuth } from 'context/authContext'
import { useEffect, useState } from 'react'
import { Table, Item } from 'components/modules/KeyValueTable'
import { useRoutes } from 'routes'

export function Image() {
  const { id } = useParams<{ id: string }>()
  const { accessToken } = useAuth()
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any | undefined>(undefined);
  const [error, setError] = useState<string | undefined>()
  const { CatalogEditionLink, CatalogSupplierLink, CatalogProductLink } = useRoutes()

  useEffect(() => {
    (async () => {
      try {
        const result: any = await fetchImage(id, accessToken);
        setData(result)
        setLoading(false)
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message)
        } else {
          setError("Error loading data");
        }
        setLoading(false)
      }
    })()
  }, [accessToken, id])

  const render = () => {
    if (loading) return <ProgressBar label='Loading…' isIndeterminate />
    if (error) return <p>{ error }</p>
    if (!data?.image) return <p>No record found</p>
    return <div>
    <section>
      <Table>
        <Item label='Image Id'>{ data.image.id } </Item>
        <Item label='Edition Id'><CatalogEditionLink id={ data.image.edition_id }>{ data.image.edition_id }</CatalogEditionLink></Item>
        <Item label='Supplier Id'><CatalogSupplierLink id={ data.image.supplier_id }>{ data.image.supplier_id }</CatalogSupplierLink></Item>
        <Item label='Product Id'><CatalogProductLink id={ data.image.supplier_product_id }>{ data.image.supplier_product_id }</CatalogProductLink></Item>
        <Item label='Source'>{ data.image.source } </Item>
        <Item label='Key'>{ data.image.key } </Item>
      </Table>
      <img src={ `${data.image.media_url}/${data.image.key}` } ></img>
    </section>
    </div>

  }

  return (
    <Layout>
      <Content variant='article' heading='Image'>
        { render() }
      </Content>
    </Layout>
  )
}
