import config from 'config'

const headers = (accessToken: string) => {
  return {
    'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ accessToken }`
  }
}

export const fetchFtp = async (accessToken: string): Promise<string> => {
  const url = `${config.ftpUrl}`
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: headers(accessToken)
  })
  const data  = await(response.json())
  return data
}

export const fetchSuppliers = async (accessToken: string): Promise<string> => {
  const url = `${config.suppliersUrl}`
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: headers(accessToken)
  })
  const data  = await(response.json())
  return data
}

