import ReportPostsTable from 'components/Tables/ReportPostsTable'
import { Layout, Content } from 'components/layouts/LoggedIn'


const ReportedPosts = () => {

  return (
    <Layout>
      <Content heading='Reported Posts'>
        <ReportPostsTable/>
      </Content>
    </Layout>
  )
}

export default ReportedPosts
