import { isString, mapDate, isDate, FilterType } from 'lib/table'
import { textFieldFactory, datePickerFactory, pickerFactory } from 'components/Table/FilterFactories'

const eventTypes = [
 'LANDINGPAGE_VISIT',
 'TIMELINE_VISIT',
 'POST_VISIT',
 'BOOK_VISIT',
 'SHOP_VISIT',
 'READER_VISIT',
 'AUTHOR_VISIT',
 'PUBLISHER_VISIT',
 'PROFILE_VISIT',
 'GROUP_VISIT',
 'EVENT_VISIT',
 'INSTALL_CHROME_PLUGIN',
 'FOLLOW_ON_TWITTER',
 'FOLLOW_ON_FB',
 'SIGNUP',
 'SIGNIN',
 'RECOMMEND',
 'REVIEW',
 'RATE',
 'SIGNUP_BONUS',
 'PROFILE_UPDATE',
 'PROFILE_COMPLETION',
 'INSTALL_ANDROID_APP',
 'INSTALL_IOS_APP',
 'SHARE',
 'INVITE_SUCCESS',
 'REDEEM_VOUCHER',
 'PURCHASE',
 'SHIPPING',
 'COUPON_APPLIED',
 'MOJO',
 'CASH_OUT',
]

const filters: FilterType[] = [
  {
    display: 'Username',
    name: 'actor',
    filter: textFieldFactory({
      label: 'Username',
      placeholder: 'Enter username...'
    }),
    mapping: (value:string):string => value,
    validator: isString
  },
  {
    display: 'Event',
    name: 'action',
    filter: pickerFactory({
      label: 'Pick event',
      items: eventTypes 
    }),
    mapping: (value:string):string => value,
    validator: isString
  },
  {
    display: 'Created After',
    name: 'since',
    field: 'createdAt',
    operation: 'gte',
    filter: datePickerFactory({
      label: 'Choose a date',
      placeholder: 'dd.mm.yyyy'
    }),
    mapping: mapDate,
    validator: isDate
  },
  {
    display: 'Created Before',
    name: 'after',
    field: 'createdAt',
    operation: 'lte',
    filter: datePickerFactory({
      label: 'Choose a date',
      placeholder: 'dd.mm.yyyy'
    }),
    mapping: mapDate,
    validator: isDate
  }
]

export default filters
