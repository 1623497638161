import React from 'react';
import BasicTable from 'components/Table/BasicTable'
import columns from './columns'

const ReportedPostTable = ({ post }: { post: any[] }) => {
  return (
    <section>
      <BasicTable columns={ columns } data={ post } />
    </section>
  )
}

export default ReportedPostTable;
