import * as Cells from 'components/Table/Cells'

const columns = [
  {
    Header: 'id',
    accessor: 'id',
    disableSortBy: true,
    cellRenderer: Cells.EpubLinkCell
  },
  {
    Header: 'Supplier Id',
    accessor: 'supplier_id',
    disableSortBy: true,
    cellRenderer: Cells.SupplierLinkCell
  },
  {
    Header: 'Product Id',
    accessor: 'product_id',
    disableSortBy: true,
    cellRenderer: Cells.ProductLinkCell
  },
  {
    Header: 'Edition Id',
    accessor: 'edition_id',
    disableSortBy: true,
    cellRenderer: Cells.EditionLinkCell
  },
  {
    Header: 'Source',
    accessor: 'source',
    disableSortBy: true,
  },
  {
    Header: 'Key',
    accessor: 'key',
    disableSortBy: true,
  },
]

export default columns
