import { Breadcrumbs, Item } from 'components/modules/Breadcrumbs'
import { Layout, Content } from 'components/layouts/LoggedIn'
import OrderItemsTable from 'components/Tables/OrderItemsTable'
import { useParams } from 'react-router-dom'
import { useRoutes } from 'routes'

const ProfileOrderItemsLayout = () => {
  const { username } = useParams<{ username: string }>()
  const { profilePath, profilesPath, profileOrderItemsPath } = useRoutes()
  return (
    <Layout>
      <Content variant='article' heading='Order Items'>
        <Breadcrumbs>
          <Item to={ profilesPath() }> Profiles </Item>
          <Item to={ profilePath({ username }) }> { username } </Item>
          <Item to={ profileOrderItemsPath({ username }) }> order items </Item>
        </Breadcrumbs>
        <ProfileOrderItems />
      </Content>
    </Layout>
  )
}

const ProfileOrderItems = () => {
  const { username } = useParams<{ username: string }>()
  return (
    <div>
      <p />
      <OrderItemsTable username={ username } />
    </div>
  )
}

export default ProfileOrderItemsLayout
