import React, { useState, useMemo } from 'react'
import config from 'config'
import Table from 'components/Table/Table'
import columnsConfiguration from './columns'
import { useAuth } from 'context/authContext'
import { FetchDataProps } from 'lib/table'
import { useToast } from 'components/modules/Toast'
import { ActionButton } from '@adobe/react-spectrum'
import DeleteIcon from '@spectrum-icons/workflow/Delete'

const ReportPostsTable = () => {
  const { accessToken } = useAuth()
  const [data, setData] = useState<any[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sortBy, setSortBy] = useState<any[]>([])
  const [filters, setFilters] = useState<any[]>([])
  const [page, setPage] = useState(0)
  const [error, setError] = useState(false)
  const [pageCount, setPageCount] = useState(1)
  const [loading, setLoading] = useState(false)

  const columns = useMemo(() => columnsConfiguration, [])

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sortBy, filters }: FetchDataProps) => {
      setFilters(filters)
      setSortBy(sortBy)
      setPage(pageIndex)
      setLoading(true)
      try {
        const url = `${config.networkUrl}/v1/report/?limit=${pageSize}&skip=${
          pageIndex * pageSize
        }`
        const res = await fetch(url, {
          method: 'GET',
          mode: 'cors',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
        const json = await res.json()
        const posts = json?.posts || []
        setData(posts)
        if (posts.length === pageSize) setPageCount(pageCount + 1)
      } catch (e) {
        console.error('Error fetching data', e)
        setError(true)
      }
      setLoading(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  )

  const QuickActions = ({ row, table }: any) => {
    const { isDeleted, eventId } = row.original
    const { addToast } = useToast()

    const deletePost = async () => {
      setLoading(true)
      try {
        await fetch(`${config.networkUrl}/v1/event/${eventId}`, {
          method: 'PUT',
          mode: 'cors',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
        // @ts-ignore
        await fetchData(table.state)
        addToast({ message: 'Post deleted successfully!', variant: 'positive' })
      } catch (error) {
        console.log(error)
        addToast({ message: 'Ups something went wrong!', variant: 'negative' })
      }
      setLoading(false)
    }

    return (
      <ActionButton isDisabled={isDeleted || loading} onPress={deletePost}>
        <DeleteIcon />
      </ActionButton>
    )
  }

  const renderQuickActions = (row: any, table: any) => {
    return <QuickActions row={row} table={table} />
  }

  return (
    <div>
      <Table
        columns={columns}
        page={page}
        pageCount={pageCount}
        data={data}
        sortBy={[]}
        loading={loading}
        filterTypes={[]}
        fetchData={fetchData}
        quickactions={renderQuickActions}
        error={error}
        filters={filters}
        setFilters={setFilters}
      />
    </div>
  )
}

export default ReportPostsTable
