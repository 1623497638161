import { formatDate, formatCurrency } from 'lib/format'
import '@spectrum-css/link/dist/index-vars.css'
import { useRoutes, externalLinks } from 'routes'
import { Checkbox } from 'components/modules/Checkbox'
import _ from 'lodash'


export function DateCell(cell: any) {
  return formatDate(cell.value)
}

export function MillisDateCell(cell: any) {
  try {
    const date = new Date(parseInt(cell.value))
    return formatDate(date.toISOString())
  } catch (error) {
    return 'error'
  }
}

export function CurrencyCell(cell: any) {
  return formatCurrency(cell.value, cell.row.original.currencyCode)
}

export function TransactionLinkCell(cell: any) {
  const { OrderLink } = useRoutes()
  return <OrderLink id={cell.value} children={cell.value} />
}

export function OrderItemLinkCell(cell: any) {
  const { OrderItemLink } = useRoutes()
  return <OrderItemLink id={cell.value} children={cell.value} />
}

export function EditionLinkCell(cell: any) {
  const { CatalogEditionLink } = useRoutes()
  return <CatalogEditionLink id={cell.value} children={cell.value} />
}

export function CashOutLinkCell(cell: any) {
  const { CashOutLink } = useRoutes()
  return <CashOutLink id={cell.value} children={cell.value} />
}

export function UserLinkCell(cell: any) {
  const { ProfileLink } = useRoutes()
  return <ProfileLink username={cell.value} children={cell.value} />
}

export function OldProductLinkCell(cell: any) {
  const { EditionLink } = useRoutes()
  return <EditionLink id={cell.value} children={cell.value} />
}

export function SupplierLinkCell(cell: any) {
  const { CatalogSupplierLink } = useRoutes()
  return <CatalogSupplierLink id={cell.value} children={cell.value} />
}

export function ImportLinkCell(cell: any) {
  const { CatalogBackupJobLink } = useRoutes()
  return <CatalogBackupJobLink id={cell.value} children={cell.value} />
}

export function ImageLinkCell(cell: any) {
  const { CatalogImageLink } = useRoutes()
  return <CatalogImageLink id={cell.value} children={cell.value} />
}

export function EpubLinkCell(cell: any) {
  const { CatalogEpubLink } = useRoutes()
  return <CatalogEpubLink id={cell.value} children={cell.value} />
}

export function ProductLinkCell(cell: any) {
  const { CatalogProductLink } = useRoutes()
  return <CatalogProductLink id={cell.value} children={cell.value} />
}

export function BackupJobLinkCell(cell: any) {
  const { CatalogBackupJobLink } = useRoutes()
  return <CatalogBackupJobLink id={cell.value} children={cell.value} />
}

export function ExternalEditionLinkCell(cell: any) {
  const { ExternalEditionLink } = externalLinks
  return (
    <ExternalEditionLink id={cell.row.original.edition.id}>
      {cell.value}
    </ExternalEditionLink>
  )
}

export function BooleanCell(cell: any) {
  return (
    <Checkbox
      aria-label={`checkbox for ${cell.value}`}
      isSelected={cell.value}
    />
  )
}

export function ProcessedCell(cell: any) {
  if (_.isNull(cell.value) || _.isUndefined(cell.value)) {
    return "Unprocessed"
  }
  if (cell.value === true) {
    return "Success"
  }
  if (cell.value === false) {
    return "Failed"
  }
}


export function BookstoreCreditsLinkCell(cell: any) {
  const { ProfileLink } = useRoutes()
  const renderLink = cell.row.original.isOnboarded

  if (!renderLink) {
    return <div>{cell.value}</div>
  }
  return <ProfileLink username={cell.value} children={cell.value} />
}

export function ReportedPostLinkCell(cell: any) {
  const { ReportedPostLink } = useRoutes()
  return <ReportedPostLink id={cell.value} children={cell.value} />
}

export function ArticleLinkCell(cell: any) {
  const { ArticleLink } = useRoutes()
  return <ArticleLink id={cell.value} children={cell.value} />
}
