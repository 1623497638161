import React from 'react'
import Table from 'components/Table/Table'
import columnsConfiguration from './columns'
import filterTypes from './filters'
import { useApolloClient } from '@apollo/client'
import { PROFILES_QUERY } from 'api/profiles'
import { FetchDataProps } from 'lib/table'

const ProfilesTable = () => {
  const client = useApolloClient()

  const [data, setData] = React.useState<any[]>([])
  const [filters, setFilters] = React.useState<any[]>([])
  const [sortBy, setSortBy] = React.useState<any[]>([])
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [pageCount, setPageCount] = React.useState(0)
  const [page, setPage] = React.useState(0)
  const fetchIdRef = React.useRef(0)

  const columns = React.useMemo(() => columnsConfiguration, [])

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sortBy, filters }: FetchDataProps) => {
      setFilters(filters)
      setSortBy(sortBy)
      setPage(pageIndex)

      const fetchId = ++fetchIdRef.current
      setLoading(true)
      try {
        const sort = sortBy[0]
        const sortString = sort
          ? `${sort.id} ${sort.desc ? 'DESC' : 'ASC'}`
          : null

        const results = await client.query({
          query: PROFILES_QUERY,
          variables: {
            skip: pageSize * pageIndex,
            limit: pageSize,
            sort: sortString,
            where: filters,
          },
        })
        if (fetchId === fetchIdRef.current) {
          setData(results.data.profilesForAdmin.profiles)
          const total = results.data.profilesForAdmin.total
          setPageCount(Math.ceil(total / pageSize))
          setLoading(false)
        }
      } catch (e) {
        console.error('Error fetching data', e)
        setError(true)
      }
    },
    [client]
  )

  return (
    <div>
      <Table
        columns={columns}
        filterTypes={filterTypes}
        data={data}
        loading={loading}
        fetchData={fetchData}
        pageCount={pageCount}
        page={page}
        filters={filters}
        setFilters={setFilters}
        sortBy={sortBy}
        error={error}
      />
    </div>
  )
}

export default ProfilesTable
