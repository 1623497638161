import * as Cells from 'components/Table/Cells';

const columns = [
  {
    Header: 'id',
    accessor: 'id',
  },
  {
    Header: 'username',
    accessor: 'actor',
    cellRenderer: Cells.UserLinkCell,
  },
  {
    Header: 'action',
    accessor: 'action',
  },
  {
    Header: 'created',
    accessor: 'createdAt',
    cellRenderer: Cells.DateCell
  },
]

export default columns;
