import { Layout, Content } from 'components/layouts/LoggedIn'
import OrderItemsTable from 'components/Tables/OrderItemsTable'

export default function OrderItems() {
  return (
    <Layout>
      <Content variant='article' heading='Order Items'>
        <OrderItemsTable />
      </Content>
    </Layout>
  )
}