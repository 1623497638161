import { Layout, Content } from 'components/layouts/LoggedIn'
import ImagesTable from 'components/Tables/ImagesTable'


export function Images() {
  return (
    <Layout>
      <Content variant='article' heading='Images'>
        <ImagesTable></ImagesTable>
      </Content>
    </Layout>
  )
}
