import { isString, mapDate, isDate, FilterType } from 'lib/table'
import { textFieldFactory, pickerFactory, datePickerFactory } from 'components/Table/FilterFactories'

const filters: FilterType[] = [
  {
    display: 'Username',
    name: 'inviter',
    filter: textFieldFactory({
      label: 'Username',
      placeholder: 'Enter username...'
    }),
    mapping: (value:string):string => value,
    validator: isString
  },
  {
    display: 'Email',
    name: 'email',
    filter: textFieldFactory({
      label: 'Email',
      placeholder: 'Enter email...'
    }),
    mapping: (value:string):string => value,
    validator: isString
  },
  {
    display: 'Status',
    name: 'status',
    filter: pickerFactory({
      label: 'Pick status',
      items: [
				'PENDING',
				'SUCCESS',
			]
    }),
    mapping: (value:string):string => value,
    validator: isString
  },
  {
    display: 'Created After',
    name: 'since',
    field: 'createdAt',
    operation: 'gte',
    filter: datePickerFactory({
      label: 'Choose a date',
      placeholder: 'dd.mm.yyyy'
    }),
    mapping: mapDate,
    validator: isDate
  },
  {
    display: 'Created Before',
    name: 'after',
    field: 'createdAt',
    operation: 'lte',
    filter: datePickerFactory({
      label: 'Choose a date',
      placeholder: 'dd.mm.yyyy'
    }),
    mapping: mapDate,
    validator: isDate
  }
]

export default filters
