import { Table, Item } from 'components/modules/KeyValueTable'
import { formatDate } from 'lib/format'
import { ExternalArticleLink } from 'routes/helper/externalLinks'

export default function ArticleTable({ article }: any) {
  const { id, title, username, publishedAt, createdAt, deletedAt, text } =
    article

  return (
    <section>
      <Table>
        <Item label="Id">{id}</Item>
        <Item label="title">{title}</Item>
        <Item label="Username">{username}</Item>
        <Item label="Text">{text}</Item>
        <Item label="Published?">{formatDate(publishedAt)}</Item>
        <Item label="Created at">{formatDate(createdAt)}</Item>
        <Item label="Deleted at">{formatDate(deletedAt)}</Item>
        <Item label="Article">
          {/* @ts-ignore */}
          <ExternalArticleLink id={id}>open article</ExternalArticleLink>
        </Item>
      </Table>
    </section>
  )
}
