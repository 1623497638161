import * as Cells from 'components/Table/Cells'

const columns = [
  {
    Header: 'Id',
    accessor: 'id',
    cellRenderer: Cells.ProductLinkCell
  },
  {
    Header: 'Supplier Id',
    accessor: 'supplier_id',
    cellRenderer: Cells.SupplierLinkCell
  },
  {
    Header: 'Notification Type',
    accessor: 'notification_type',
    cellRenderer: Cells.SupplierLinkCell
  },
  {
    Header: 'Active',
    accessor: 'is_active',
    cellRenderer: Cells.BooleanCell
  },
  {
    Header: 'Data Type',
    accessor: 'data_type',
  },
  {
    Header: 'Created',
    accessor: 'created_at',
    cellRenderer: Cells.DateCell
  },
]

export default columns
