import * as Cells from 'components/Table/Cells'

const columns = [
  {
    Header: 'id',
    accessor: 'id',
    cellRenderer: Cells.CashOutLinkCell,
  },
  {
    Header: 'username',
    accessor: (originalRow: any) => {
      return originalRow.profile?.username || null
    },
    disableSortBy: true,
    cellRenderer: Cells.UserLinkCell,
  },
  {
    Header: 'amount',
    accessor: 'amount',
  },
  {
    Header: 'credits',
    accessor: 'credits',
  },
  {
    Header: 'status',
    accessor: 'status',
  },
  {
    Header: 'created',
    accessor: 'createdAt',
    cellRenderer: Cells.DateCell,
  },
  {
    Header: 'updated',
    accessor: 'updatedAt',
    cellRenderer: Cells.DateCell,
  },
]

export default columns
