import React from 'react';
import BasicTable from 'components/Table/BasicTable'
import columns from './columns'

const OrderItemsBasicTable = ({ orderItems }: { orderItems: any[] }) => {
  return (
    <section>
      <h3>Order Items</h3>
      <BasicTable columns={ columns } data={ orderItems } />
    </section>
  )
}

export default OrderItemsBasicTable;
