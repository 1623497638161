import config from 'config'

import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Switch, RadioGroup, Radio, Flex } from '@adobe/react-spectrum'
import { useQuery } from '@apollo/client'

import { useRoutes } from 'routes'
import { useAuth } from 'context/authContext'
import { PROFILE_QUERY } from 'api/mesh/profiles/profilesQueries'
import { useToast } from 'components/modules/Toast'
import { Breadcrumbs, Item } from 'components/modules/Breadcrumbs'
import { Content, Layout } from 'components/layouts/LoggedIn'
import { withProfile } from './withProfile'

export type ProfileRole =
  | 'bookstore'
  | 'reader'
  | 'author'
  | 'publisher'
  | 'affiliate_partners'

interface Profile {
  role: ProfileRole
  username: string
}

interface ProfileProps {
  profile: Profile
}

const ProfileChangeRoleLayout = (props: ProfileProps) => {
  const { profile } = props
  const { username } = useParams<{ username: string }>()
  const { profilePath, profilesPath, profileChangeRolePath } = useRoutes()

  return (
    <Layout>
      <Content variant="article" heading="Change Role">
        <Breadcrumbs>
          <Item to={profilesPath()}> Profiles </Item>
          <Item to={profilePath({ username })}> {username} </Item>
          <Item to={profileChangeRolePath({ username })}> Change Role</Item>
        </Breadcrumbs>
        <ProfileChangeRole profile={profile} />
      </Content>
    </Layout>
  )
}

const ProfileChangeRole = (props: ProfileProps) => {
  const { profile } = props
  const { username, role } = profile
  const { accessToken } = useAuth()
  const { addToast } = useToast()

  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    const dataFetch = async () => {
      try {
        const rawResponse = await fetch(`${config.userInfoUrl}/${username}`, {
          method: 'GET',
          mode: 'cors',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })

        const content = await rawResponse.json()
        const roles = content.user.roles

        if (content.user.roles.length === 0) {
          setIsAdmin(false)
        } else if (roles.includes('ADMIN')) {
          setIsAdmin(true)
        }
      } catch (error) {
        addToast({ variant: 'negative', message: error, timeout: 3000 })
      }
    }
    dataFetch()
  }, [])

  const onAdminChange = async (val: boolean) => {
    const endPoint = isAdmin ? config.removeAdminUrl : config.makeAdminUrl

    try {
      const rawResponse = await fetch(endPoint, {
        method: 'PUT',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ username }),
      })

      const content = await rawResponse.json()

      if (content.error === false) {
        setIsAdmin(!isAdmin)
        addToast({
          variant: 'positive',
          message: content.message,
        })
      }

      if (content.error === true) {
        addToast({
          variant: 'negative',
          message: content.message,
        })
      }
    } catch (error) {
      addToast({ variant: 'negative', message: error, timeout: 3000 })
    }
  }

  const { refetch } = useQuery(PROFILE_QUERY, { variables: { username } })

  const onChangeRole = async (val: string) => {
    try {
      const rawResponse = await fetch(config.changeRoleUrl, {
        method: 'PUT',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ username, role: val }),
      })

      const content = await rawResponse.json()

      if (content.error === false) {
        refetch()
        addToast({
          variant: 'positive',
          message: content.message,
        })
      }

      if (content.error === true) {
        addToast({
          variant: 'negative',
          message: content.message,
        })
      }
    } catch (error) {
      addToast({ variant: 'negative', message: error, timeout: 3000 })
    }
  }

  return (
    <div>
      <h2>Change roles for {username} </h2>
      <Flex direction="column" gap="size-200">
        <h3>Change Profile Role</h3>
        <RadioGroup defaultValue={role} onChange={onChangeRole}>
          <Radio value="bookstore">Bookstore</Radio>
          <Radio value="reader">Reader</Radio>
          <Radio value="author">Author</Radio>
          <Radio value="publisher">Publishes</Radio>
          <Radio value="affiliate_partners">Affiliate Partners</Radio>
        </RadioGroup>
      </Flex>
      <Flex direction="column" gap="size-200">
        <h3>Change User role</h3>
        <Switch isSelected={isAdmin} onChange={onAdminChange}>
          Admin
        </Switch>
      </Flex>
    </div>
  )
}

export default withProfile(ProfileChangeRoleLayout)
