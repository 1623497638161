import React from 'react'
import Card from 'components/modules/Card'
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { format, getMonth } from 'date-fns'

import { formatCurrency } from 'lib/format'

import {
  chartProps,
  chartConfig,
  styleProps,
  hGridProps,
  numberAxisProps,
  intervalAxisProps,
  labelFormatter,
} from './charts'

interface MonthlySalesRow {
  date: string
  amount: number
}

export const MonthlySalesChart: React.FC<{ data: MonthlySalesRow[] }> = ({
  data,
}) => {
  const { smallTickCount } = chartConfig

  const tickNumberFormatter = (tick: number, index: number): string => {
    if (index % smallTickCount !== 0) return ''
    if (tick < 1000) return tick.toString()
    return tick / 1000 + 'K'
  }

  const tickFormatterXAxis = (tick: string, index: number) => {
    const date = new Date(tick)
    const m = getMonth(date)
    if (m % 6 !== 0) return ''
    return format(date, 'MMM yy')
  }

  const tooltipFormatter: any = (value: string, name: string, props: any) => {
    return [formatCurrency(props.payload.amount, 'EUR'), 'Sales']
  }

  return (
    <Card heading="Monthly Sales (€)">
      <ResponsiveContainer height={300}>
        <ComposedChart data={data} {...chartProps}>
          <style {...styleProps} />
          <CartesianGrid {...hGridProps} />
          <Tooltip
            formatter={tooltipFormatter}
            labelFormatter={labelFormatter.months}
          />
          <XAxis
            dataKey="date"
            tickFormatter={tickFormatterXAxis}
            {...intervalAxisProps}
          />
          <YAxis
            dataKey="amount"
            tickFormatter={tickNumberFormatter}
            {...numberAxisProps}
          />
          <Bar dataKey="amount" fill="#8884d8" />
        </ComposedChart>
      </ResponsiveContainer>
    </Card>
  )
}
