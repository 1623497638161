import React from 'react'
import classNames from 'classnames'

export const Table = ({ children, isQuiet, UNSAFE_className, ...props }: { children: React.ReactNode, isQuiet: boolean, UNSAFE_className: string }) =>
  <table
    className={ classNames('spectrum-Table', { 'spectrum-Table--quiet': isQuiet }, UNSAFE_className) }
    children={ children }
    { ...props } />

export const TableHeader = ({ children, UNSAFE_className, ...props }: { children: React.ReactNode, UNSAFE_className: string }) =>
  <thead
    className={ classNames('spectrum-Table-head', UNSAFE_className) }
    children={ <tr>{ children }</tr> }
    { ...props } />

export const TableBody = ({ children, UNSAFE_className, ...props }: { children: React.ReactNode, UNSAFE_className: string }) =>
  <tbody
    className={ classNames('spectrum-Table-body', UNSAFE_className) }
    children={ children }
    { ...props } />

export const TableFooter = ({ children, UNSAFE_className, ...props }: { children: React.ReactNode, UNSAFE_className: string }) =>
  <tfoot
    className={ classNames('spectrum-Table-footer', UNSAFE_className) }
    children={ children }
    { ...props } />

export const Column = ({ children, UNSAFE_className, ...props }: { children: React.ReactNode, UNSAFE_className: string }) =>
  <th
    className={ classNames('spectrum-Table-headCell', UNSAFE_className) }
    children={ children }
    { ...props } />

export const Row = ({ children, UNSAFE_className, ...props }: { children: React.ReactNode, UNSAFE_className: string }) =>
  <tr
    className={ classNames('spectrum-Table-row', UNSAFE_className)}
    style={{ verticalAlign: 'middle' }}
    children={ children }
    { ...props } />

export const Cell = ({ children, UNSAFE_className, ...props }: { children: React.ReactNode, UNSAFE_className: string }) =>
  <td
    className={ classNames('spectrum-Table-cell', UNSAFE_className) }
    children={ children }
    { ...props } />
