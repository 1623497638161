import * as Cells from 'components/Table/Cells';

const columns = [
  {
    Header: 'id',
    accessor: 'id',
  },
  {
    Header: 'username',
    accessor: 'username',
    cellRenderer: Cells.UserLinkCell,
  },
  {
    Header: 'credits',
    accessor: 'credits',
  },
  {
    Header: 'event',
    accessor: 'event',
  },
  {
    Header: 'created',
    accessor: 'createdAt',
    cellRenderer: Cells.DateCell,
  },
]

export default columns;
