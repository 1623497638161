import { Layout, Content } from 'components/layouts/LoggedIn'
import EditionsTable from 'components/Tables/EditionsTable'


export function Editions() {
  return (
    <Layout>
      <Content variant='article' heading='Editions'>
      <EditionsTable />
      </Content>
    </Layout>
  )
}
