import React from 'react'

interface TransactionTableContextValue {
  setFilters: any
  setSortBy: any
  setPageCount: any
  setPage: any,
  page: number,
  filters: any[]
  pageCount: number,
  sortBy: any[]
  setData: any
  data: any[]
  loading: any
  fetchIdRef: any
  setLoading: any
}

const TransactionTableContext = React.createContext<TransactionTableContextValue | null>(null)

function TransactionTableProvider(props: any) {
  const [data, setData] = React.useState<any[]>([])
  const [filters, setFilters] = React.useState<object>({})
  const [sortBy, setSortBy] = React.useState<any[]>([])
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [pageCount, setPageCount] = React.useState(0)
  const [page, setPage] = React.useState(0)
  const fetchIdRef = React.useRef(0)

  const tableState = {
    data,
    setData,
    filters,
    setFilters,
    sortBy,
    setSortBy,
    loading,
    setLoading,
    pageCount,
    setPageCount,
    fetchIdRef,
    page,
    setPage,
    error,
    setError
  }

  return (
    <TransactionTableContext.Provider value={ tableState } {...props} />
  )
}

const useTransactionTable = () => React.useContext(TransactionTableContext)

export {TransactionTableProvider, useTransactionTable}
