import config from 'config'
import { Edition } from 'types/edition'

export const searchISBN = async (isbn: String): Promise<Edition[]> => {
  const url = `${config.apiUrl}/search/v1/edition?query=${isbn}&field=isbn&limit=1&skip=0`
  const response = await fetch(url)
  const data  = await(response.json())
  const editions: Edition[] = data.editions as Edition[]
  return editions
}
