import * as Cells from 'components/Table/Cells';

const columns = [
  {
    Header: 'username',
    accessor: 'username',
    cellRenderer: Cells.BookstoreCreditsLinkCell
  },
  {
    Header: 'Supporter Credit',
    accessor: 'supporterCredits'
  },
  {
    Header: 'Affiliate Credit',
    accessor: 'affiliateCredits'
  },
  {
    Header: 'Total Credits',
    accessor: 'totalCredits'
  },
  {
    Header: 'Is Onboarded',
    accessor: 'isOnboarded',
    cellRenderer: Cells.BooleanCell
  }
]

export default columns
