import * as Cells from 'components/Table/Cells'

const columns = [
  {
    Header: 'Blocked User',
    accessor: 'blockedProfile',
    cellRenderer: Cells.UserLinkCell,
  },
  {
    Header: 'Blocked by',
    accessor: 'blockedBy',
    cellRenderer: Cells.UserLinkCell,
  },
  {
    Header: 'Blocked On',
    accessor: 'blockedOn',
    cellRenderer: Cells.DateCell,
  },
  {
    Header: 'Blocked Until',
    accessor: 'blockedUntil',
    cellRenderer: Cells.DateCell,
  },
]

export default columns