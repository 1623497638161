import React, { useState, useRef, useMemo, useCallback } from 'react'
import Table from 'components/Table/Table'
import columnsConfiguration from './columns'
import filterTypes from './filters'
import { useApolloClient } from '@apollo/client'
import { INVITES_QUERY } from 'api/invites'
import { mapFilters } from 'lib/table'

interface FetchDataProps {
  pageSize: number
  pageIndex: number
  sortBy: { id: string; desc: boolean }[]
  filters: any[]
}

const InvitesTable: React.FC<{ username?: string }> = ({ username }) => {
  const client = useApolloClient()

  const [data, setData] = useState<any[]>([])
  const [filters, setFilters] = useState<any>(
    username ? { inviter: username } : {}
  )
  const [sortBy, setSortBy] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [page, setPage] = useState(0)
  const fetchIdRef = useRef(0)

  const columns = useMemo(() => columnsConfiguration, [])

  const fetchData = useCallback(
    async ({ pageSize, pageIndex, sortBy, filters }: FetchDataProps) => {
      const sort = sortBy[0] ? sortBy[0].id : 'updatedAt'
      const sortOrder = sortBy[0] ? (sortBy[0].desc ? 'DESC' : 'ASC') : 'DESC'
      const mappedFilters = mapFilters(filters, filterTypes)

      setFilters(filters)
      setSortBy(sortBy)
      setPage(pageIndex)
      const fetchId = ++fetchIdRef.current
      setLoading(true)
      try {
        const variables = {
          skip: pageSize * pageIndex,
          limit: pageSize,
          filter: mappedFilters,
          sort,
          sortOrder,
        }
        const results = await client.query({
          query: INVITES_QUERY,
          variables,
        })
        if (fetchId === fetchIdRef.current) {
          setData(results.data.invitesForAdmin.invites)
          setPageCount(Math.ceil(results.data.invitesForAdmin.count / pageSize))
          setLoading(false)
        }
      } catch (e) {
        console.error('Error fetching data', e)
        setError(true)
      }
    },
    [client]
  )

  return (
    <Table
      filterTypes={filterTypes}
      columns={columns}
      data={data}
      loading={loading}
      fetchData={fetchData}
      pageCount={pageCount}
      page={page}
      filters={filters}
      setFilters={setFilters}
      sortBy={sortBy}
      error={error}
    />
  )
}

export default InvitesTable
