import { Breadcrumbs, Item } from 'components/modules/Breadcrumbs'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { StatefulTransactionsTable } from 'components/Tables/TransactionsTable'
import { useParams } from 'react-router-dom'
import { useRoutes } from 'routes'

const ProfileOrdersLayout = () => {
  const { username } = useParams<{ username: string }>();
  const { profilePath, profilesPath, profileOrdersPath } = useRoutes()
  return (
    <Layout>
      <Content variant='article' heading='Orders'>
        <Breadcrumbs>
          <Item to={ profilesPath() }> Profiles </Item>
          <Item to={ profilePath({ username }) }> { username } </Item>
          <Item to={ profileOrdersPath({ username }) }> orders </Item>
        </Breadcrumbs>
        <ProfileOrders />
      </Content>
    </Layout>
  )
}

const ProfileOrders = () => {
  const { username } = useParams<{ username: string }>();
  return (
    <div>
      <p />
      <StatefulTransactionsTable username={ username } />
    </div>
  )
}

export default ProfileOrdersLayout
