import * as Cells from 'components/Table/Cells';

const columns = [
  {
    Header: 'id',
    accessor: 'id',
    cellRenderer: Cells.TransactionLinkCell,
  },
  {
    Header: 'Code',
    accessor: 'transactionCode',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    cellRenderer: Cells.CurrencyCell,
  },
  {
    Header: 'Method',
    accessor: 'paymentMethod',
    disableSortBy: false,
  },
  {
    Header: 'Username',
    accessor: 'username',
    cellRenderer: Cells.UserLinkCell,
  },
  {
    Header: 'Payment Status',
    accessor: 'paymentStatus',
    disableSortBy: false,
  },
  {
    Header: 'Date',
    accessor: 'createdAt',
    cellRenderer: Cells.DateCell,
  }
]

export default columns
