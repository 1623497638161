import { mapDate, isInteger, isString, isDate, FilterType } from 'lib/table'

import {
  datePickerFactory,
  textFieldFactory,
} from 'components/Table/FilterFactories'
import { pickerFactory } from 'components/Table/FilterFactories/pickerFactory'

const filters: FilterType[] = [
  {
    display: 'Id',
    name: 'id',
    filter: textFieldFactory({
      label: 'Id',
      placeholder: 'Enter number...',
      type: 'number',
    }),
    mapping: parseInt,
    validator: isInteger,
  },
  {
    display: 'Order Id',
    name: 'orderId',
    filter: textFieldFactory({
      label: 'Order Id',
      placeholder: 'Enter number...',
      type: 'number',
    }),
    mapping: parseInt,
    validator: isInteger,
  },
  {
    display: 'After',
    name: 'createdAtMin',
    filter: datePickerFactory({
      label: 'Choose a date',
      placeholder: 'dd.mm.yyyy',
    }),
    mapping: mapDate,
    validator: isDate,
  },
  {
    display: 'Before',
    name: 'createdAtMax',
    filter: datePickerFactory({
      label: 'Choose a date',
      placeholder: 'dd.mm.yyyy',
    }),
    mapping: mapDate,
    validator: isDate,
  },
  {
    display: 'Username',
    name: 'username',
    filter: textFieldFactory({
      label: 'Username',
      placeholder: 'Enter name...',
    }),
    mapping: (value: string): string => value,
    validator: isString,
  },
  {
    display: 'ISBN',
    name: 'isbn',
    filter: textFieldFactory({
      label: 'ISBN',
      placeholder: 'Enter ISBN...',
    }),
    mapping: (value: string): string => value,
    validator: isString,
  },
  {
    display: 'Sender',
    name: 'sender',
    filter: textFieldFactory({
      label: 'Sender',
      placeholder: 'Enter SenderId...',
      type: 'number',
    }),
    mapping: parseInt,
    validator: isInteger,
  },
  {
    display: 'Status',
    name: 'status',
    filter: pickerFactory({
      label: 'status',
      items: ['delivered', 'pending', 'failed'],
    }),
    mapping: (value: string): string => value,
    validator: isString,
  },
]

export default filters
