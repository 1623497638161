import React from 'react'
import { NavLink } from 'react-router-dom'
import SideBarHeader from './SideBarHeader'
import Logout from 'components/Logout/Logout'
import '@spectrum-css/sidenav/dist/index-vars.css'
import './Sidebar.css'
import { useAuth } from 'context/authContext'

const checkRootPath = (match: any, location: any) => {
  return match.isExact
}

export default function Sidebar() {
  const { user } = useAuth()
  if (!user) {
    return (
      <div id="site-sidebar" className="spectrum-Site-sideBar">
        <p>Not logging in</p>
      </div>
    )
  }
  return (
    <div id="site-sidebar" className="spectrum-Site-sideBar">
      <SideBarHeader to="/" />
      <div className="spectrum-Site-nav">
        <nav>
          <ul className="spectrum-SideNav spectrum-SideNav">
            <li className="spectrum-SideNav-item">
              <NavLink
                to="/"
                isActive={checkRootPath}
                className="spectrum-SideNav-itemLink"
                activeClassName="is-selected"
              >
                Dashboard
              </NavLink>
            </li>
            <li className="spectrum-SideNav-item">
              <h2
                className="spectrum-SideNav-heading"
                id="nav-heading-category-1"
              >
                Payments
              </h2>
              <ul
                className="spectrum-SideNav"
                aria-labelledby="nav-heading-category-1"
              >
                <li className="spectrum-SideNav-item">
                  <NavLink
                    to="/orders"
                    className="spectrum-SideNav-itemLink"
                    activeClassName="is-selected"
                  >
                    Orders
                  </NavLink>
                </li>
                <li className="spectrum-SideNav-item">
                  <NavLink
                    to="/order-items"
                    className="spectrum-SideNav-itemLink"
                    activeClassName="is-selected"
                  >
                    Order Items
                  </NavLink>
                </li>
                <li className="spectrum-SideNav-item">
                  <NavLink
                    to="/cashouts"
                    className="spectrum-SideNav-itemLink"
                    activeClassName="is-selected"
                  >
                    Cash-outs
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="spectrum-SideNav-item">
              <h2
                className="spectrum-SideNav-heading"
                id="nav-heading-category-1"
              >
                Users
              </h2>
              <ul
                className="spectrum-SideNav"
                aria-labelledby="nav-heading-category-1"
              >
                <li className="spectrum-SideNav-item">
                  <NavLink
                    to="/profiles"
                    className="spectrum-SideNav-itemLink"
                    activeClassName="is-selected"
                  >
                    Profiles
                  </NavLink>
                </li>
                <li className="spectrum-SideNav-item">
                  <NavLink
                    to="/invites"
                    className="spectrum-SideNav-itemLink"
                    activeClassName="is-selected"
                  >
                    Invites
                  </NavLink>
                </li>
                <li className="spectrum-SideNav-item">
                  <NavLink
                    to="/credits"
                    className="spectrum-SideNav-itemLink"
                    activeClassName="is-selected"
                  >
                    Credits
                  </NavLink>
                </li>
                <li className="spectrum-SideNav-item">
                  <NavLink
                    to="/events"
                    className="spectrum-SideNav-itemLink"
                    activeClassName="is-selected"
                  >
                    Events
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="spectrum-SideNav-item">
              <h2
                className="spectrum-SideNav-heading"
                id="nav-heading-category-1"
              >
                Moderation
              </h2>
              <ul
                className="spectrum-SideNav"
                aria-labelledby="nav-heading-category-1"
              >
                <li className="spectrum-SideNav-item">
                  <NavLink
                    to="/articles"
                    className="spectrum-SideNav-itemLink"
                    activeClassName="is-selected"
                  >
                    Articles
                  </NavLink>
                </li>
                <li className="spectrum-SideNav-item">
                  <NavLink
                    to="/reported-articles"
                    className="spectrum-SideNav-itemLink"
                    activeClassName="is-selected"
                  >
                    Reported Articles
                  </NavLink>
                </li>
                <li className="spectrum-SideNav-item">
                  <NavLink
                    to="/reported-posts"
                    className="spectrum-SideNav-itemLink"
                    activeClassName="is-selected"
                  >
                    Reported Posts
                  </NavLink>
                </li>
                <li className="spectrum-SideNav-item">
                  <NavLink
                    to="/block-users"
                    className="spectrum-SideNav-itemLink"
                    activeClassName="is-selected"
                  >
                    Block Users
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="spectrum-SideNav-item">
              <h2
                className="spectrum-SideNav-heading"
                id="nav-heading-category-1"
              >
                Bookshops
              </h2>
              <ul
                className="spectrum-SideNav"
                aria-labelledby="nav-heading-category-1"
              >
                <li className="spectrum-SideNav-item">
                  <NavLink
                    to="/bookshop-credits"
                    className="spectrum-SideNav-itemLink"
                    activeClassName="is-selected"
                  >
                    Credits
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="spectrum-SideNav-item">
              <h2
                className="spectrum-SideNav-heading"
                id="nav-heading-category-1"
              >
                Catalog
              </h2>
              <ul
                className="spectrum-SideNav"
                aria-labelledby="nav-heading-category-1"
              >
                <li className="spectrum-SideNav-item">
                  <NavLink
                    to="/catalog/imports"
                    className="spectrum-SideNav-itemLink"
                    activeClassName="is-selected"
                  >
                    Imports
                  </NavLink>
                </li>
                <li className="spectrum-SideNav-item">
                  <NavLink
                    to="/catalog/images"
                    className="spectrum-SideNav-itemLink"
                    activeClassName="is-selected"
                  >
                    Images
                  </NavLink>
                </li>
                <li className="spectrum-SideNav-item">
                  <NavLink
                    to="/catalog/epubs"
                    className="spectrum-SideNav-itemLink"
                    activeClassName="is-selected"
                  >
                    Epubs
                  </NavLink>
                </li>
                <li className="spectrum-SideNav-item">
                  <NavLink
                    to="/catalog/editions"
                    className="spectrum-SideNav-itemLink"
                    activeClassName="is-selected"
                  >
                    Editions
                  </NavLink>
                </li>
                <li className="spectrum-SideNav-item">
                  <NavLink
                    to="/catalog/products"
                    className="spectrum-SideNav-itemLink"
                    activeClassName="is-selected"
                  >
                    Products
                  </NavLink>
                </li>
                <li className="spectrum-SideNav-item">
                  <NavLink
                    to="/related-identifiers"
                    className="spectrum-SideNav-itemLink"
                    activeClassName="is-selected"
                  >
                    Related Identifiers
                  </NavLink>
                </li>
                <li className="spectrum-SideNav-item">
                  <NavLink
                    to="/catalog/suppliers"
                    className="spectrum-SideNav-itemLink"
                    activeClassName="is-selected"
                  >
                    Suppliers
                  </NavLink>
                </li>
                <li className="spectrum-SideNav-item">
                  <NavLink
                    to="/catalog/ftp"
                    className="spectrum-SideNav-itemLink"
                    activeClassName="is-selected"
                  >
                    FTP
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
          <Logout className={'spectrum-SideNav-logOut'} />
          <div>Currently logged in as {user.username}</div>
        </nav>
      </div>
    </div>
  )
}
