import * as Cells from 'components/Table/Cells';

const columns = [
  {
    Header: 'id',
    accessor: 'id',
  },
  {
    Header: 'inviter',
    accessor: 'inviter',
    cellRenderer: Cells.UserLinkCell,
  },
  {
    Header: 'invitee',
    accessor: 'invitee',
  },
  {
    Header: 'email',
    accessor: 'email',
  },
  {
    Header: 'status',
    accessor: 'status',
  },
  {
    Header: 'created',
    accessor: 'createdAt',
    cellRenderer: Cells.DateCell
  },
]

export default columns;
