import * as Cells from 'components/Table/Cells'

const columns = [
  {
    Header: 'id',
    accessor: 'id',
    cellRenderer: Cells.SupplierLinkCell
  },
  {
    Header: 'name',
    accessor: 'name',
  },
  {
    Header: 'active',
    accessor: 'active',
    cellRenderer: Cells.BooleanCell
  },
  {
    Header: 'payment',
    accessor: 'active_for_payment',
    cellRenderer: Cells.BooleanCell
  },
  {
    Header: 'priority',
    accessor: 'product_priority',
  },
  {
    Header: 'created',
    accessor: 'created_at',
    cellRenderer: Cells.DateCell
  },
]

export default columns
