import { useParams } from 'react-router-dom'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { fetchImport } from 'api/details/catalog'
import { ProgressBar } from '@adobe/react-spectrum'
import { useAuth } from 'context/authContext'
import { useEffect, useState } from 'react'
import { Table, Item } from 'components/modules/KeyValueTable'
import { useRoutes } from 'routes'
import _ from 'lodash'
import ProductsTable from 'components/Tables/ProductsTable'
import config from 'config'

export function BackupJob() {
  const { id } = useParams<{ id: string }>()
  const { accessToken } = useAuth()
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any | undefined>(undefined);
  const [error, setError] = useState<string | undefined>()
  const { CatalogSupplierLink, CatalogFtpLink } = useRoutes()

  useEffect(() => {
    (async () => {
      try {
        const result: any = await fetchImport(id, accessToken);
        setData(result)
        setLoading(false)
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message)
        } else {
          setError("Error loading data");
        }
        setLoading(false)
      }
    })()
  }, [accessToken, id])

  const showStatus = (status: boolean | undefined): string => {
    if (_.isNull(status) || _.isUndefined(status)) {
      return "Unprocessed"
    }
    if (status) {
      return "Success"
    }
    return "Failed"
  }

  const render = () => {
    if (loading) return <ProgressBar label='Loading…' isIndeterminate />
    if (error) return <p>{ error }</p>
    if (!data?.import) return <p>No record found</p>
    return <div>
    <section>
      <Table>
        <Item label='Id'>{ data.import.id } </Item>
        <Item label='FTP Account'>
          <CatalogFtpLink id="data.import.dropbox_id">
            { data.import.dropbox_id }
          </CatalogFtpLink>
        </Item>
        <Item label='Supplier'>
          <CatalogSupplierLink id={ data.import.supplier_id }>
            { data.import.supplier_id }
          </CatalogSupplierLink>
        </Item>
        <Item label='Type'>{ data.import.type } </Item>
        <Item label='Status'>{ showStatus(data.import.processed) } </Item>
        <Item label='Key'>{ data.import.filename } </Item>
        <Item label='Date'>{ data.import.download_time } </Item>
      </Table>
    </section>
    <section>
      <form action={ `${config.apiUrl}/admin-api/download/import` } method="POST">
        <input type="hidden" name="auth-token" value={ accessToken }></input>
        <input type="hidden" name="key" value={ data.import.filename }></input>
        <input type="submit" value="Download"></input>
      </form>
    </section>
    <section>
      <h3>Products</h3>
      <ProductsTable backup_job_id={ data.import.id }></ProductsTable>
    </section>
    </div>

  }

  return (
    <Layout>
      <Content variant='article' heading='Import'>
        { render() }
      </Content>
    </Layout>
  )
}
