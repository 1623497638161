import * as Cells from 'components/Table/Cells'

const columns = [
  {
    Header: 'id',
    accessor: 'id',
    disableSortBy: true,
  },
  {
    Header: 'Article id',
    accessor: 'article_id',
    disableSortBy: true,
    cellRenderer: Cells.ArticleLinkCell,
  },
  {
    Header: 'Reporter Username',
    accessor: 'username',
    disableSortBy: true,
  },
  {
    Header: 'Created At',
    accessor: 'created_at',
    disableSortBy: true,
    cellRenderer: Cells.DateCell,
  },
]

export default columns
