import { useQuery } from '@apollo/client'
import { PROFILE_QUERY } from './profilesQueries'

export const useProfileData = (variables: any) => {
  const { data, ...rest } = useQuery(PROFILE_QUERY, { variables })
  return {
    data: {
      profile: data?.profiles[0],
      credits: data?.credits?.count,
    },
    ...rest
  }
}