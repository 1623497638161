
import React, { useRef } from 'react'
import { format } from 'date-fns'
import { uniqueId } from 'lib/utils'
import CalendarIcon from '@spectrum-icons/workflow/Calendar'
import Calendar from 'components/modules/Calendar'
import '@spectrum-css/inputgroup/dist/index-vars.css'
import '@spectrum-css/picker/dist/index-vars.css'
import '@spectrum-css/fieldlabel/dist/index-vars.css'
import styles from './DatePicker.module.css'

import {
  Flex,
  TextField,
  ActionButton,
  DialogTrigger,
  Dialog,
  Content
} from '@adobe/react-spectrum'

interface IProps {
  label: string,
  placeholder: string,
  selectedDay: Date,
  onSelectionChange: any
}

const DatePicker = (props: IProps) => {

  const {
    label,
    placeholder,
    selectedDay,
    onSelectionChange
  } = props

  const { current: id } = useRef(uniqueId())

  const selectionChangeHandler = (close: () => void) => (selected: Date) => {
    onSelectionChange(selected)
    close()
  }

  return (
    <Flex direction='column' UNSAFE_className={ styles.datepicker }>
      <label htmlFor={id} className='spectrum-FieldLabel spectrum-FieldLabel--sizeM'>
        { label }
      </label>
      <div aria-disabled='false' aria-invalid='false' className='spectrum-InputGroup spectrum-Datepicker' aria-readonly='false' aria-required='false' aria-haspopup='true'>
        <TextField
          id={id}
          value={ selectedDay && format(selectedDay, "dd.MM.yyyy") }
          placeholder={ placeholder }
          UNSAFE_className={ styles.inputWrapper}
          isReadOnly />
        <DialogTrigger type='popover' placement='bottom right' hideArrow>
          <ActionButton UNSAFE_className='spectrum-Picker spectrum-Picker--sizeM spectrum-InputGroup-button'>
            <CalendarIcon size='S' />
          </ActionButton>
          {close =>
            <Dialog UNSAFE_className={ styles.dialog }>
              <Content>
                <Calendar selectedDay={ selectedDay } onSelectionChange={ selectionChangeHandler(close) } />
              </Content>
            </Dialog>
          }
        </DialogTrigger>
      </div>
    </Flex>
  )
}

export default DatePicker
