import React from 'react'
import ArrowDown from '@spectrum-icons/ui/ArrowDownSmall'
import './HeadingCell.css'

const HeadingCell = ({ column }: { column: any }) => {

  const headerClasses = [
    "spectrum-Table-headCell",
    column.canSort ? "is-sortable" : null,
    column.isSorted
      ? column.isSortedDesc
        ? "is-sorted-desc"
        : "is-sorted-asc"
      : null,
  ].filter(Boolean).join(" ")

  return (
    <th {...column.getHeaderProps()} className={headerClasses}>
      <span {...column.getSortByToggleProps()}>
        {column.render('Header')}
        <span className={"spectrum-Table-sortedIcon"}>
          <ArrowDown aria-label="Arrow Down" />
        </span>
      </span>
    </th>
  )
}

export default HeadingCell
