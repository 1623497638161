import React, { useState, useMemo } from 'react'
import config from 'config'
import Table from 'components/Table/Table'
import columnsConfiguration from './columns'
import { FetchDataProps } from 'lib/table'
import { useAuth } from 'context/authContext'

const BlockedUsers = () => {
  const { accessToken } = useAuth()
  const [data, setData] = useState<any[]>([])
  const [, setSortBy] = useState<any[]>([])
  const [filters, setFilters] = useState<any[]>([])
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(1)
  const [error, setError] = useState(false)

  const columns = useMemo(() => columnsConfiguration, [])

  const fetchBlockedUsers = React.useCallback(
    async (
      offset: Number,
      pageSize: Number,
      sortBy: String,
      sortOrder: String
    ) => {
      const url = `${config.blockUrl}/?limit=${pageSize}&skip=${offset}&sortBy=${sortBy}&sortOrder=${sortOrder}`
      const res = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      const json = await res.json()
      return json
    },
    [accessToken]
  )

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sortBy, filters }: FetchDataProps) => {
      setFilters(filters)
      setSortBy(sortBy)
      setPage(pageIndex)
      setLoading(true)
      try {
        const sort = sortBy[0] ? sortBy[0].id : 'createdAt'
        const sortOrder = sortBy[0] ? (sortBy[0].desc ? 'DESC' : 'ASC') : 'DESC'
        const data = await fetchBlockedUsers(
          pageIndex * pageSize,
          pageSize,
          sort,
          sortOrder
        )
        const users = data.blockedUsers || []
        setData(users)
        setPageCount(Math.ceil(data.total / pageSize))
      } catch (e) {
        console.error('Error fetching data', e)
        setError(true)
      }
      setLoading(false)
    },
    [fetchBlockedUsers]
  )

  return (
    <div>
      <Table
        columns={columns}
        page={page}
        pageCount={pageCount}
        data={data}
        sortBy={[]}
        loading={loading}
        filterTypes={[]}
        fetchData={fetchData}
        error={error}
        filters={filters}
        setFilters={setFilters}
      />
    </div>
  )
}

export default BlockedUsers
