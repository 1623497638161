import { Layout, Content } from 'components/layouts/LoggedIn'
import CashOutTable from 'components/Tables/CashOutTable'

export default function CashOuts() {
  return (
    <Layout>
      <Content variant='article' heading='Cash-outs'>
        <CashOutTable />
      </Content>
    </Layout>
  )
}