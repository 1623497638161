import config from 'config'

export interface Address {
  addressLine1: string
  addressLine2: string
  city: string
  companyName: string
  country: string
  fullName: string
  state: string
  telephone: string
  zipCode: string
}

interface OrderItem {
  editionId: Number
  quantity: Number
}

interface PaymentDetails {
  paymentMode: String
}

interface Order {
  customer: String
  shippingAddress: Address
  edition: Number[]
  quantity: OrderItem[]
  paymentDetails: PaymentDetails
  shipping: String
  paymentType: String
  useCart: Boolean
}

interface MakeOrderParams {
  username: String,
  address: Address,
  items: OrderItem[]
}

export const makeOrder = ({username, address, items}: MakeOrderParams): Order => {
  const order = {
    customer: username,
    shippingAddress: address,
    edition: items.map(item => item.editionId),
    quantity: items,
    paymentDetails: {
        paymentMode: 'Credits'
    },
    paymentType: 'vc',
    shipping: 'standard',
    useCart: false
  }
  return order
}

export const checkout = async (accessToken: string, order: Order): Promise<object> => {
  const url = config.checkoutUrl
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },
    body: JSON.stringify(order)
  })
  return await(response.json())
}

