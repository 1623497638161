import { isInteger, isString, FilterType } from 'lib/table'

import { textFieldFactory } from 'components/Table/FilterFactories'

const filters: FilterType[] = [
  {
    display: 'Related Format Id',
    name: 'related_format_id',
    filter: textFieldFactory({
      label: 'Related Format Id',
      placeholder: 'Enter Related Format Id',
      type: 'number',
    }),
    mapping: parseInt,
    validator: isInteger,
  },
  {
    display: 'Value',
    name: 'value',
    filter: textFieldFactory({
      label: 'Value',
      placeholder: 'Enter a value',
    }),
    mapping: (value: string): string => value,
    validator: isString,
  },
  {
    display: 'Type',
    name: 'type',
    filter: textFieldFactory({
      label: 'Type',
      placeholder: 'Enter a type',
    }),
    mapping: (value: string): string => value,
    validator: isString,
  },
]

export default filters
