import React from 'react';
import BasicTable from 'components/Table/BasicTable'
import columns from './columns'

const SuppliersTable = ({ data }: { data: any[] }) => {
  return (
    <BasicTable columns={ columns } data={ data } />
  )
}

export default SuppliersTable;
