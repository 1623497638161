import React from 'react'
import { User } from 'types/user';
import Login from 'components/pages/Login'
import useAccessToken from 'lib/useAccessToken'

interface AuthContextValue {
  user: User | undefined,
  setUser: (user: User) => void,
  accessToken: string,
  setAccessToken: (accessToken: string) => void,
  logout: () => void
}

const defaultValue: AuthContextValue = {
  user: undefined,
  setUser: (user:User) => undefined,
  accessToken: '',
  setAccessToken: (accessToken:string) => undefined,
  logout: () => undefined
}

const AuthContext = React.createContext<AuthContextValue>(defaultValue)

function AuthProvider(props: any) {
	const [user, setUser] = React.useState<User>()
  const { accessToken, setAccessToken } = useAccessToken()
  const logout = () => {
    setUser(undefined)
    setAccessToken('')
  }

  const authContextValue:AuthContextValue = {
    user: user,
    setUser: (user: User) => { setUser(user) },
    accessToken: accessToken,
    setAccessToken: setAccessToken,
    logout: logout
  }

  if (!user) {
    return (
      <Login
        setUser={ (user: User) => setUser(user) }
        setAccessToken={ setAccessToken }
        accessToken= { accessToken }
      />
    )
  }

  return (
    <AuthContext.Provider value={ authContextValue } {...props} />
  )
}

const useAuth = () => React.useContext(AuthContext)

export {AuthProvider, useAuth}
