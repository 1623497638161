const mapInteger = ({ id, value }: { id: string; value: any }): {} => {
  const integer = parseInt(value)
  if (!integer) return []
  return {
    [id]: integer,
  }
}

const mapDefault = ({ id, value }: { id: string; value: any }): {} => {
  return {
    [id]: value,
  }
}

const mapAmountRange = ({ id, value }: { id: string; value: any }): {} => {
  return {
    amountMin: Number(value?.min),
    amountMax: Number(value?.max),
  }
}

const mapDate = (dateString: any) => {
  const millis = Date.parse(dateString)
  return millis ? new Date(millis) : null
}

const isInteger = (value: string): boolean =>
  value === parseInt(value, 10).toString()
const isString = (value: string): boolean => !!(value && value.length > 0)
const isDate = (value: Date): boolean => value instanceof Date

const applyFilterMappings = (filters: any, columns: any) => {
  let variables: any = {}
  for (const filter of filters) {
    const column = columns[0].columns.find(
      (column: any) => column.accessor === filter.id
    )
    if (column?.filterMapping) {
      const vars = column.filterMapping(filter)
      variables = { ...variables, ...vars }
    }
  }
  return variables
}

export interface FilterType {
  display: string
  username?: string
  name: string
  field?: string
  operation?: string
  filter: any
  mapping: any
  validator: any
}

const mapFilters = (filters: object, filterTypes: FilterType[]) => {
  let mappedFilters: any = {}
  for (const [key, value] of Object.entries(filters)) {
    const filterType = filterTypes.find((ft) => ft.name === key)

    if (filterType) {
      const field = filterType.field || key
      const operation = filterType.operation

      if (operation) {
        if (!mappedFilters[field]) mappedFilters[field] = {}
        mappedFilters[field][operation] = value
      } else {
        mappedFilters[field] = value
      }
    }
  }
  return mappedFilters
}

export interface FetchDataProps {
  pageSize: number
  pageIndex: number
  sortBy: { id: string; desc: boolean }[]
  filters: any[]
}

export {
  mapInteger,
  mapDefault,
  mapAmountRange,
  mapDate,
  applyFilterMappings,
  isInteger,
  isString,
  isDate,
  mapFilters,
}
