import { useToast } from 'components/modules/Toast'
import { useMutation } from '@apollo/client'
import { ActionButton, Flex } from '@adobe/react-spectrum'
import ProfileTable from 'components/containers/ProfileTable'
import { CONVERT_TO_BOOKSTORE_MUTATION } from 'api/profiles'
import { useRoutes } from 'routes'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { Breadcrumbs, Item } from 'components/modules/Breadcrumbs'
import { withProfile } from './withProfile'
import { fetchUserData } from 'api/user'
import { useAuth } from 'context/authContext'

const ProfilesDetail = ({ profile, credits }: any) => {
  const { accessToken } = useAuth();
  const { addToast } = useToast()
  const { username } = profile
  const [convertToBookstore] = useMutation(CONVERT_TO_BOOKSTORE_MUTATION)

  const {
    profilePath,
    profilesPath,
    ProfileInvitesLink,
    ProfileCreditsLink,
    ProfileOrdersLink,
    ProfileOrderItemsLink,
    ProfileEventsLink,
    ProfileAddCreditsLink,
    ProfileChangeEmailLink,
    ProfileDeleteAccountLink,
    ProfileVerifyEmailLink,
    ProfileGetAddressLink,
    ProfileChangeRoleLink,
  } = useRoutes()

  const downloadUserData = async () => {
    addToast({
      variant: 'info',
      message: 'Starting to download',
    })

    const results = await fetchUserData(username, accessToken)
    if (!results.data) {
      addToast({
        variant: 'negative',
        message: 'Download failed ',
      })
      return
    }

    const file = new Blob([JSON.stringify(results.data, null, 2)], { type: 'application/json' })
    const element = document.createElement('a')
    element.href = URL.createObjectURL(file)
    element.download = `user-data-${username}-${Date.now()}.json`
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()

    addToast({
      variant: 'positive',
      message: 'Download is ready',
    })
  }

  const renderDownloadDataButton = () => {
    return (
      <Flex direction="row">
        <ActionButton
          onPress={ downloadUserData } >
          Download data
        </ActionButton>
      </Flex>
    )
  }

  const renderConvertToBookstoreButton = (profile: any) => {
    if (profile.role === 'bookstore') {
      return null
    }
    return (
      <Flex direction="row">
        <ActionButton
          onPress={() => convertToBookstore({ variables: { username } })}
        >
          Convert to bookstore
        </ActionButton>
      </Flex>
    )
  }

  const renderUserContent = () => {
    return (
      <>
        <ProfileTable profile={profile} credits={credits} />
        <ul>
          {/*
          <li>
            <CreateOrderLink username={ username }>
              Create order
            </CreateOrderLink>
          </li>
          */}
          <li>
            <ProfileInvitesLink username={username}>Invites</ProfileInvitesLink>
          </li>
          <li>
            <ProfileCreditsLink username={username}>Credits</ProfileCreditsLink>
          </li>
          <li>
            <ProfileAddCreditsLink username={username}>
              Add Credits
            </ProfileAddCreditsLink>
          </li>
          {/*
          <li>
            <ProfileCashoutsLink username={ username }>
              Cashouts
            </ProfileCashoutsLink>
          </li>
					*/}
          <li>
            <ProfileOrdersLink username={username}>Orders</ProfileOrdersLink>
          </li>
          <li>
            <ProfileOrderItemsLink username={username}>
              Order Items
            </ProfileOrderItemsLink>
          </li>
          <li>
            <ProfileEventsLink username={username}>Events</ProfileEventsLink>
          </li>
          <li>
            <ProfileChangeEmailLink username={username}>
              Change Email
            </ProfileChangeEmailLink>
          </li>
          <li>
            <ProfileVerifyEmailLink username={username}>
              Verify Email
            </ProfileVerifyEmailLink>
          </li>
          <li>
            <ProfileGetAddressLink username={username}>
              Get Address
            </ProfileGetAddressLink>
          </li>
          <li>
            <ProfileDeleteAccountLink username={username}>
              Delete User
            </ProfileDeleteAccountLink>
          </li>
          <li>
            <ProfileChangeEmailLink username={username}>
              Change Email
            </ProfileChangeEmailLink>
          </li>
          <li>
            <ProfileVerifyEmailLink username={username}>
              Verify Email
            </ProfileVerifyEmailLink>
          </li>
          <li>
            <ProfileDeleteAccountLink username={username}>
              Delete User
            </ProfileDeleteAccountLink>
          </li>
          <li>
            <ProfileChangeRoleLink username={username}>
              Change Role
            </ProfileChangeRoleLink>
          </li>
        </ul>
        <table>
          <tr>
            <td>
              {renderConvertToBookstoreButton(profile)}
            </td>
          </tr>
          <tr>
            <td>
              {renderDownloadDataButton()}
            </td>
          </tr>
        </table>
      </>
    )
  }

  return (
    <Layout>
      <Content variant="article" heading={username}>
        <Breadcrumbs>
          <Item to={profilesPath()}> Profiles </Item>
          <Item to={profilePath({ username })}> {username} </Item>
        </Breadcrumbs>
        {renderUserContent()}
      </Content>
    </Layout>
  )
}

export default withProfile(ProfilesDetail)
