import * as Cells from 'components/Table/Cells'

const columns = [
  {
    Header: 'id',
    accessor: 'id',
    disableSortBy: true,
  },
  {
    Header: 'Related format id',
    accessor: 'related_format_id',
    disableSortBy: true,
  },
  {
    Header: 'Type',
    accessor: 'type',
    disableSortBy: true,
  },
  {
    Header: 'Value',
    accessor: 'value',
    disableSortBy: true,
  },
  {
    Header: 'Created',
    accessor: 'created_at',
    disableSortBy: false,
    cellRenderer: Cells.DateCell,
  },
]

export default columns
