
import { format } from 'date-fns'

export function currencyFormatter(currencyCode: string) {
  try {
    const formatter = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: currencyCode || 'EUR',
    });
    return (value: number) => {
      try {
        return formatter.format(value || 0);
      } catch (_) {
        return value || 0;
      }
    };
  } catch (_) {
    return (value:number) => value;
  }
}

export function formatCurrency(value: number, currencyCode: string): string {
  return currencyFormatter(currencyCode)(value).toString();
}

export function formatDate(date: string) {
  if (!date) return '';
  try {
    return format(new Date(date), "dd.MM.yyy HH:mm")
  } catch (_) {
    return date;
  }
}

export function formatValue(value: any) {
  if (value instanceof Date) {
    return format(value, "dd.MM.yyy HH:mm")
  } else {
    return value
  }
}
