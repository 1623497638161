import config from 'config'

const headers = (accessToken: string) => {
  return {
    'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ accessToken }`
  }
}

export const fetchEdition = async (edition_id: string, accessToken: string): Promise<string> => {
  const url = `${config.apiUrl}/admin-api/details/edition/${edition_id}`
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: headers(accessToken)
  })
  const data  = await(response.json())
  return data
}

export const fetchProduct = async (product_id: string, accessToken: string): Promise<string> => {
  const url = `${config.apiUrl}/admin-api/details/product/${product_id}`
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: headers(accessToken)
  })
  const data  = await(response.json())
  return data
}


export const fetchImage = async (image_id: string, accessToken: string): Promise<string> => {
  const url = `${config.apiUrl}/admin-api/details/image/${image_id}`
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: headers(accessToken)
  })
  const data  = await(response.json())
  return data
}

export const fetchImport = async (import_id: string, accessToken: string): Promise<string> => {
  const url = `${config.apiUrl}/admin-api/details/import/${import_id}`
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: headers(accessToken)
  })
  const data  = await(response.json())
  return data
}

export const fetchEpub = async (epub_id: string, accessToken: string): Promise<string> => {
  const url = `${config.apiUrl}/admin-api/details/epub/${epub_id}`
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: headers(accessToken)
  })
  const data  = await(response.json())
  return data
}

export const fetchSupplier = async (supplier_id: string, accessToken: string): Promise<string> => {
  const url = `${config.apiUrl}/admin-api/details/supplier/${supplier_id}`
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: headers(accessToken)
  })
  const data  = await(response.json())
  return data
}
