import config from 'config'
import UserAddressTable from 'components/Tables/UserAddressTable/UserAddressTable'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { Breadcrumbs, Item } from 'components/modules/Breadcrumbs'
import { useAsyncList } from '@react-stately/data'
import { useParams } from 'react-router-dom'
import { useAuth } from 'context/authContext'
import { useRoutes } from 'routes'
import { Loading, Error } from 'components/pages/Status'
import { View } from '@react-spectrum/view'

const useAddressList = (username: string) => {
  const { accessToken } = useAuth()

  return useAsyncList({
    async load({ signal }) {
      let res = await fetch(`${config.addressURL}?username=${username}`, {
        signal,
        method: 'GET',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${ accessToken }`
          },
      });
      let json = await res.json();
  
      return {
        items: json,
      };
    }
  })
}

const ProfilesGetAddress = () => {
  const { username } = useParams<{ username: string }>()
  const { profilesPath, profilePath, profileGetAddressPath } = useRoutes()
  const { items, isLoading, error } = useAddressList(username)

  if (isLoading) return <Loading />
  if (error) return <Error error={error} />

  return (
    <Layout>
      <Content heading='User Address'>
        <Breadcrumbs>
          <Item to={ profilesPath() }> Profiles </Item>
          <Item to={ profilePath({ username }) }> {username} </Item>
          <Item to={ profileGetAddressPath({username}) } children='User Address' />
        </Breadcrumbs>
        <View marginTop='size-250'>
          { items.length === 0
            ? 'This user does not have a saved address.'
            : <UserAddressTable address={ items }/> }
        </View>
      </Content>
    </Layout>
  )
}

export default ProfilesGetAddress
