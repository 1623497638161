import { useState } from 'react'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { Heading, SearchField, View } from '@adobe/react-spectrum'
import BlockUsersTable from 'components/Tables/BlockUsersTable'
import BlockedUsers from 'components/Tables/BlockedUsersTable'

const BlockUsers = () => {
  const [ username, setUsername ] = useState('')
  const [ showUserTable, setShowUserTable ] = useState(false)
  
  const onSubmitSearch = (e: string) => {
    setUsername(e)
    setShowUserTable(true)
  } 

  return (
    <Layout>
      <Content heading='Block Users'>
        <SearchField
          label='Search for User'
          placeholder='Enter Username'
          onSubmit={ onSubmitSearch }
          onClear={ ()=> setShowUserTable(false) } />
        { showUserTable &&
          <View marginTop='size-300' marginBottom='size-100' >
            <BlockUsersTable username={ username } />
          </View> }
        <Heading level={ 4 } marginTop='size-300'>
          Currently Blocked Users:
        </Heading>
        <BlockedUsers />
      </Content>   
    </Layout>
  )
}

export default BlockUsers
