import { useRoutes } from 'routes'
import {
  Breadcrumbs,
  Item as BreadcrumbsItem,
} from 'components/modules/Breadcrumbs'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { View } from '@adobe/react-spectrum'
import ReportedArticlesTable from 'components/Tables/ReportedArticlesTable'

const Articles = () => {
  const { reportedArticlesPath } = useRoutes()
  return (
    <Layout>
      <Content heading="Articles">
        <View>
          <Breadcrumbs>
            <BreadcrumbsItem
              to={reportedArticlesPath}
              children={'Reported articles'}
            />
          </Breadcrumbs>
          <ReportedArticlesTable />
        </View>
      </Content>
    </Layout>
  )
}

export default Articles
