
import { useRef, useEffect } from 'react'

const setupRoot = (id: string, className: string, dir: string): HTMLElement => {
  let root = document.getElementById(id)
  if (root) return root
  root = document.createElement('div')
  root.setAttribute('id', id)
  if (className) root.setAttribute('class', className)
  if (dir) root.setAttribute('dir', dir)
  document.body.appendChild(root)
  return root
}

export const usePortal = ({ id, className, dir }: { id: string, className: string, dir: string }) => {
  const rootRef = useRef<HTMLElement | null>(null)
  const portalRef = useRef<HTMLElement | null>(null)

  useEffect(() => {
    if (!rootRef.current) rootRef.current = setupRoot(id, className, dir)
    if (!portalRef.current) portalRef.current = document.createElement('div')

    rootRef.current.appendChild(portalRef.current)

    return () => {
      if (!portalRef.current) return
      portalRef.current.remove()
      if (!rootRef.current?.childElementCount) rootRef.current?.remove()
    }
  }, [id, className, dir])

  return portalRef.current
}

export default usePortal
