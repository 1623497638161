import { useState, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { Layout, Content } from 'components/layouts/LoggedIn'
import {
  Address,
  checkout,
  makeOrder
} from 'api/checkout'
import { useAuth } from 'context/authContext'
import { useRoutes } from 'routes'
import { Breadcrumbs, Item } from 'components/modules/Breadcrumbs'
import { Button, ButtonGroup } from '@adobe/react-spectrum'

import { AddressComponent, addressReducer } from './Address'
import { OrderItem } from './types'
import { ItemsTable } from './ItemsTable'

const initialAddress: Address = {
  addressLine1: 'Oranienstraße',
  addressLine2: '',
  city: 'Berlin',
  companyName: '',
  country: 'Deutschland',
  fullName: 'Freddy Mercury',
  state: 'Berlin',
  telephone: '',
  zipCode: '12056'
}


export const CreateOrderLayout = () => {
  const { username } = useParams<{ username: string }>();
  const { profilePath, profilesPath, createOrderPath } = useRoutes()
  return (
    <Layout>
      <Content variant='article' heading='Create Order'>
        <Breadcrumbs>
          <Item to={ profilesPath() }> Profiles </Item>
          <Item to={ profilePath({ username }) }> { username } </Item>
          <Item to={ createOrderPath({ username }) }> Create Order </Item>
        </Breadcrumbs>
        <CreateOrder />
      </Content>
    </Layout>
  )
}

export function CreateOrder() {
  const { accessToken } = useAuth()
  const { username } = useParams<{ username: string }>()
  const [ items, setItems ] = useState<OrderItem[]>([])
  const [ address, dispatch ] = useReducer(addressReducer, initialAddress)

  const createOrder = async () => {
    const orderItems = items.map(item => ({ editionId: item.edition.id, quantity: item.quantity }))
    const order = makeOrder({ address, username, items: orderItems })
    await checkout(accessToken, order)
  }

  return (
    <>
      <p>
        This feature is currently in development, order placement will not
        currently work on the development system as the backend changes have not been
        merged.
      </p>
      <ItemsTable items={ items } setItems={ setItems } />
      <AddressComponent address={ address } dispatch={ dispatch } />

      <p>
        <br />
        <ButtonGroup>
          <Button variant='cta' onPress={ createOrder }>Place order</Button>
        </ButtonGroup>
      </p>
    </>
  )
}

export default CreateOrderLayout;
