
import React from 'react'
import Sidebar from 'components/layouts/Sidebar'
import { useBodyClass } from 'lib'

import { Provider, defaultTheme } from '@adobe/react-spectrum'
import '@spectrum-css/vars/dist/spectrum-global.css'
import '@spectrum-css/vars/dist/spectrum-light.css'
import '@spectrum-css/vars/dist/spectrum-medium.css'

import styles from './LoggedIn.module.css'

interface LoggedInProps {
  children: any
}

export default function LoggedIn({ children }: LoggedInProps) {

  useBodyClass([
    'spectrum',
    'spectrum--medium',
    'spectrum--light'
  ])

  return (
    <Provider theme={ defaultTheme } colorScheme="light">
      <div className={ styles.root }>
        <Sidebar />
        { children }
      </div>
    </Provider>
  )
}
