import React from 'react'
import {
  Menu,
  MenuTrigger,
  Item,
  ActionButton,
  Text,
} from '@adobe/react-spectrum'
import MoreIcon from '@spectrum-icons/workflow/More'
import DeleteIcon from '@spectrum-icons/workflow/Delete'

interface QuickActionProps {
  onDelete: any
  isDisabled?: boolean
}

export const QuickActions: React.FC<QuickActionProps> = ({
  onDelete,
  isDisabled,
}) => {
  const handleAction = (key: string | number) => {
    switch (key) {
      case 'delete':
        onDelete()
    }
  }
  return (
    <MenuTrigger>
      <ActionButton isDisabled={isDisabled} isQuiet>
        <MoreIcon />
      </ActionButton>
      <Menu onAction={handleAction}>
        <Item key="delete">
          <DeleteIcon size="S" />
          <Text> Delete </Text>
        </Item>
      </Menu>
    </MenuTrigger>
  )
}
