import config from 'config'

const headers = (accessToken: string) => {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  }
}

export const reprocessEdition = async (editionId: number, accessToken: string) => {
  try {
    const url = `${config.reprocesEditionUrl}`

    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: headers(accessToken),
      body: JSON.stringify({ id: editionId }),
    })
    const data = await response.json()
    return data
  } catch(error) {
    return { success: false, message: "API error" }
  }
}

export const blockEdition = async (editionId: string, accessToken: string) => {
  const url = `${config.blockEditionUrl}`

  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: headers(accessToken),
    body: JSON.stringify({ id: editionId }),
  })
  const data = await response.json()
  return data
}

export const unBlockEdition = async (
  editionId: string,
  accessToken: string
) => {
  const url = `${config.unBlockEditionUrl}`

  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: headers(accessToken),
    body: JSON.stringify({ id: editionId }),
  })
  const data = await response.json()
  return data
}
