import { Layout, Content } from 'components/layouts/LoggedIn'
import BackupJobsTable from 'components/Tables/BackupJobsTable'


export function BackupJobs() {
  return (
    <Layout>
      <Content variant='article' heading='Imports'>
      <BackupJobsTable />
      </Content>
    </Layout>
  )
}
