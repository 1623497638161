import React from 'react'
import { Table, Item } from 'components/modules/KeyValueTable'
import { externalLinks } from 'routes'


export const ProfileTable = ({ profile, credits }: { profile: any, credits: any }) => {
  const { ExternalProfileLink } = externalLinks

  return (
    <section>
      <Table>
        <Item label='Username'>{ profile.username } </Item>
        <Item label='Display Name'>{ profile.displayName}</Item>
        <Item label='Caption'>{ profile.caption }</Item>
        <Item label='Email'>{ profile.email }</Item>
        <Item label='Profile'>
          <ExternalProfileLink username={ profile.username }>
            { profile.username }
          </ExternalProfileLink>
        </Item>
        <Item label='Role'>{ profile.role }</Item>
        <Item label='Credits'>{ credits }</Item>
      </Table>
    </section>
  )
}

export default ProfileTable
