import { ComponentType, ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { useProfileData } from 'api/mesh/profiles'
import { Loading, Error, NotFound } from 'components/pages/Status'

export const withProfile =
  (WrappedComponent: ComponentType<any>) =>
  (props: any): ReactElement => {
    const { username } = useParams<{ username: string }>()
    const { loading, data, error } = useProfileData({ username })
    if (loading) return <Loading />
    if (error) return <Error error={error} />
    if (!data) return <NotFound />
    // @ts-expect-error
    return <WrappedComponent {...props} {...data} />
  }
