import React from 'react'
import { Table, Item } from 'components/modules/KeyValueTable'
import { formatDate, formatCurrency } from 'lib/format'
import { useRoutes, externalLinks } from 'routes'

export const OrderItemDataTable = ({ data: orderItem }) => {
  const { OrderLink, ProfileLink } = useRoutes()
  const { ExternalEditionLink } = externalLinks
  const { orderId, username, edition } = orderItem

  return (
    <section>
      <Table>
        <Item label='order Id'>
          <OrderLink id={ orderId } children={ orderId } />
        </Item>

        <Item label='username'>
          <ProfileLink username={ username } children={ username } />
        </Item>

        <Item label='edition'>
          <ExternalEditionLink id={ edition.id } children={ edition.id } />
        </Item>

        <Item label='status'>{ orderItem.status }</Item>
        <Item label='title'>{ orderItem.title }</Item>
        <Item label='ISBN 13'>{ orderItem.ISBN13 }</Item>
        <Item label='slug'>{ orderItem.slug }</Item>
        <Item label='type'>{ orderItem.type }</Item>
        <Item label='supplier'>{ orderItem.supplier }</Item>
        <Item label='tracking Id'>{ orderItem.trackingId }</Item>
        <Item label='tracking URL'>{ orderItem.trackingURL }</Item>
        <Item label='dispatched Date'>{ formatDate(orderItem.dispatchedDate) }</Item>
        <Item label='expectedDelivery Date'>{ formatDate(orderItem.expectedDeliveryDate) }</Item>
        <Item label='actual Delivery Date'>{ formatDate(orderItem.actualDeliveryDate) }</Item>
        <Item label='quantity'>{ orderItem.quantity }</Item>
        <Item label='current Price'>{ formatCurrency(orderItem.currentPrice, orderItem.currencyCode) }</Item>
        <Item label='tax'>{ formatCurrency(orderItem.tax, orderItem.currencyCode)  }</Item>
        <Item label='net Total'>{ formatCurrency(orderItem.netTotal, orderItem.currencyCode) }</Item>
        <Item label='total Amount'>{ formatCurrency(orderItem.totalAmount, orderItem.currencyCode) }</Item>
        <Item label='info'>{ orderItem.info }</Item>
        <Item label='error'>{ orderItem.error }</Item>
        <Item label='created At'>{ formatDate(orderItem.createdAt) }</Item>
      </Table>
    </section>
  )
}

export default OrderItemDataTable
