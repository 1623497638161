
import { createPortal } from 'react-dom'
import { ReactNode } from 'react'
import usePortal from 'lib/usePortal'
import styles from './ToastPortal.module.css'

export const ToastPortal = ({ children }: { children: ReactNode }) => {
  const target = usePortal({
    id: 'toasts',
    className: styles.toastPortal,
    dir: 'ltr'
  })

  return target ? createPortal(children, target) : null
}

export default ToastPortal
