
import React from 'react'
import { Table, Item } from 'components/modules/KeyValueTable'

export const AddressTable = ({ address }: { address: any }) => {

  const renderAddress = () =>
    <Table>
      <Item label='Fullname'>{ address.fullName }</Item>
      <Item label='Company Name'>{ address.companyName }</Item>
      <Item label='Line 1'>{ address.addressLine1 }</Item>
      <Item label='Line 2'>{ address.addressLine2 }</Item>
      <Item label='Zipcode'>{ address.zipCode }</Item>
      <Item label='City'>{ address.city }</Item>
      <Item label='Country'>{ address.country }</Item>
      <Item label='State'>{ address.state } </Item>
      <Item label='Email'>{ address.email }</Item>
      <Item label='Phone'>{ address.telephone } </Item>
    </Table>

  return (
    <section>
      <h3>Address</h3>
      { address
        ? renderAddress()
        : <p>There is no address data available.</p> }
    </section>
  )
}

export default AddressTable
