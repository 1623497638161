import { Layout, Content } from 'components/layouts/LoggedIn'
import CreditsTable from 'components/Tables/CreditsTable'

const Credits = () => {
  return (
    <Layout>
      <Content variant='article' heading='Credits'>
        <CreditsTable />
      </Content>
    </Layout>
  )
}

export default Credits
