import * as Cells from 'components/Table/Cells'

const columns = [
  {
    Header: 'id',
    accessor: 'id',
  },
  {
    Header: 'Supplier Id',
    accessor: 'supplier_id',
    cellRenderer: Cells.SupplierLinkCell,
  },
  {
    Header: 'Active',
    accessor: 'is_active',
    cellRenderer: Cells.BooleanCell,
  },
  {
    Header: 'Hostname',
    accessor: 'hostname',
  },
  {
    Header: 'Username',
    accessor: 'username',
  },
  {
    Header: 'Password',
    accessor: 'password',
  },
  {
    Header: 'Path',
    accessor: 'path',
  },
  {
    Header: 'Last Fetch',
    accessor: 'last_fetch',
    cellRenderer: Cells.DateCell,
  },
]

export default columns
