
import React, { useState, useEffect, useCallback } from 'react'
import classNames from 'classnames'
import InfoIcon from '@spectrum-icons/workflow/Info'
import CloseIcon from '@spectrum-icons/workflow/Close'
import NegativeIcon from '@spectrum-icons/workflow/Alert'
import PositiveIcon from '@spectrum-icons/workflow/CheckmarkCircle'
import '@spectrum-css/toast/dist/index-vars.css'
import styles from './Toast.module.css'

export enum Variants {
  info = 'info',
  positive = 'positive',
  negative = 'negative',
  default = 'default',
}

interface IToast {
  children: React.ReactNode,
  className?: string,
  onClose?: () => void,
  timeout?: number,
  variant?: Variants
}

export const Toast = (props: IToast) => {
  let { className, variant, children, timeout, onClose } = props
  const [isActive, setIsActive] = useState(false)

  const transitionDuration = 500

  const closeHandler = useCallback(() => {
    setIsActive(false)
    if (onClose) setTimeout(onClose, transitionDuration)
  }, [onClose])

  useEffect(() => {
    setIsActive(true)
    if (timeout) setTimeout(closeHandler, timeout)
  }, [children, timeout, closeHandler])

  className = classNames(
    styles.toast,
    'spectrum-Toast',
    {
      [styles.isActive]: isActive,
      [`spectrum-Toast--${variant}`]: variant
    }
  )

  const style = {
    transitionDuration: `${transitionDuration}ms`
  }

  const renderIcon = () => {
    switch (variant) {
      case Variants.info: return <InfoIcon size='S' UNSAFE_className='spectrum-Toast-typeIcon' />
      case Variants.positive: return <PositiveIcon size='S' UNSAFE_className='spectrum-Toast-typeIcon' />
      case Variants.negative: return <NegativeIcon size='S' UNSAFE_className='spectrum-Toast-typeIcon' />
      default: return null
    }
  }

  return (
    <div className={ className } style={ style }>
      { renderIcon() }
      <div className='spectrum-Toast-body'>
        <div className='spectrum-Toast-content'>{ children }</div>
      </div>
      <div className='spectrum-Toast-buttons'>
        <button onClick={ closeHandler } className='spectrum-ClearButton spectrum-ClearButton--medium spectrum-ClearButton--overBackground'>
          <CloseIcon size='XS'/>
        </button>
      </div>
    </div>
  )
}

export default Toast
