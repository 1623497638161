import { useParams } from 'react-router-dom'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { fetchSuppliers } from 'api/tables/catalog'
import { ProgressBar } from '@adobe/react-spectrum'
import { useAuth } from 'context/authContext'
import { useEffect, useState } from 'react'
import SuppliersTable from 'components/Tables/SuppliersTable'

export function Suppliers() {
  const { id } = useParams<{ id: string }>()
  const { accessToken } = useAuth()
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any | undefined>(undefined);
  const [error, setError] = useState<string | undefined>()

  useEffect(() => {
    (async () => {
      try {
        const result: any = await fetchSuppliers(accessToken);
        setData(result)
        setLoading(false)
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message)
        } else {
          setError("Error loading data");
        }
        setLoading(false)
      }
    })()
  }, [accessToken, id])

  const render = () => {
    if (loading) return <ProgressBar label='Loading…' isIndeterminate />
    if (error) return <p>{ error }</p>
    if (!data?.data) return <p>No record found</p>
    return <div>
      <SuppliersTable data={ data.data }></SuppliersTable>
    </div>

  }

  return (
    <Layout>
      <Content variant='article' heading='Suppliers'>
        { render() }
      </Content>
    </Layout>
  )
}
