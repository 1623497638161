import config from 'config'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { useParams } from 'react-router-dom'
import { useRoutes } from 'routes'
import { Breadcrumbs, Item as BreadcrumbsItem } from 'components/modules/Breadcrumbs'
import { useAsyncList } from '@react-stately/data'
import { useAuth } from 'context/authContext'
import ReportedPostTable from 'components/Tables/ReportPostTable'
import { Loading, Error } from 'components/pages/Status'
import { View } from '@adobe/react-spectrum'

const useReportedPostsItemList = (id: string) => {
  const { accessToken } = useAuth()

  return useAsyncList({
    async load({ signal }) {
      let res = await fetch(`${config.networkUrl}/v1/report/${id}}`, {
        signal,
        method: 'GET',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${ accessToken }`
          },
      });
      let json = await res.json();

      return {
        items: json.reports,
      };
    }
  })
}

const ReportedPost = () => {
  const { id } = useParams<{ id: string }>()
  const { reportedPostsPath, reportedPostPath } = useRoutes()
  const { items, isLoading, error } = useReportedPostsItemList(id)

  if (isLoading) return <Loading />
  if (error) return <Error error={error} />

  return (
    <Layout>
      <Content heading="Reported Post">
        <Breadcrumbs>
            <BreadcrumbsItem to={ reportedPostsPath() } children={ 'Reported Posts' } />
            <BreadcrumbsItem to={ reportedPostPath({ id }) } children={ id } />
        </Breadcrumbs>
        <View marginTop='size-250'>
          <ReportedPostTable post={items} />
        </View>
      </Content>
    </Layout>
  )
}

export default ReportedPost
