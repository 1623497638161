import * as Cells from 'components/Table/Cells'

const columns = [
  {
    Header: 'Id',
    accessor: 'id',
    cellRenderer: Cells.ImageLinkCell
  },
  {
    Header: 'Supplier Id',
    accessor: 'supplier_id',
    cellRenderer: Cells.SupplierLinkCell
  },
  {
    Header: 'Product Id',
    accessor: 'product_id',
    cellRenderer: Cells.ProductLinkCell
  },
  {
    Header: 'Key',
    accessor: 'key',
  },
]

export default columns
