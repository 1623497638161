import * as Cells from 'components/Table/Cells';

const columns = [
  {
    Header: 'Flagged date',
    accessor: 'createdAt',
    cellRenderer: Cells.DateCell
  },
  {
    Header: 'Post Id',
    accessor: 'post',
  },
  {
    Header: 'Feedback',
    accessor: 'feedback'
  },
  {
    Header: 'Profile',
    accessor: 'profile'
  },
  {
    Header: 'Reported User',
    accessor: 'reportedUser'
  },
  {
    Header: 'Event Id',
    accessor: 'eventId'
  },
  {
    Header: 'Reason',
    accessor: 'reason'
  }
]

export default columns