import { mapDate, isInteger, isString, isDate, FilterType } from 'lib/table'

import {
  datePickerFactory,
  textFieldFactory,
} from 'components/Table/FilterFactories'

const filters: FilterType[] = [
  {
    display: 'Supplier Product Id',
    name: 'id',
    filter: textFieldFactory({
      label: 'Supplier Product Id',
      placeholder: 'Enter Suppplier Product Id...',
      type: 'number',
    }),
    mapping: parseInt,
    validator: isInteger,
  },
  {
    display: 'Supplier Id',
    name: 'supplier_id',
    filter: textFieldFactory({
      label: 'Supplier Id',
      placeholder: 'Enter Supplier Id...',
    }),
    mapping: (value: string): string => value,
    validator: isInteger,
  },
  {
    display: 'Edition Id',
    name: 'edition_id',
    filter: textFieldFactory({
      label: 'Edition Id',
      placeholder: 'Enter Edition Id...',
      type: 'number',
    }),
    mapping: parseInt,
    validator: isInteger,
  },
  {
    display: 'Import Id',
    name: 'backup_job_id',
    filter: textFieldFactory({
      label: 'Import Id',
      placeholder: 'Enter Import Id...',
      type: 'number',
    }),
    mapping: parseInt,
    validator: isInteger,
  },
  // TODO: Add these
  //{
  //  display: 'After',
  //  name: 'createdAtMin',
  //  filter: datePickerFactory({
  //    label: 'Choose a date',
  //    placeholder: 'dd.mm.yyyy',
  //  }),
  //  mapping: mapDate,
  //  validator: isDate,
  //},
  //{
  //  display: 'Before',
  //  name: 'createdAtMax',
  //  filter: datePickerFactory({
  //    label: 'Choose a date',
  //    placeholder: 'dd.mm.yyyy',
  //  }),
  //  mapping: mapDate,
  //  validator: isDate,
  //}
]

export default filters
