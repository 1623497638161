const columns = [
  {
    Header: 'type',
    accessor: 'type',
  },
  {
    Header: 'value',
    accessor: 'value'
  },
  {
    Header: 'product form',
    accessor: 'productForm',
  },
  {
    Header: 'relation code',
    accessor: 'relationCode',
  }
]

export default columns
