import { format, getMonth } from 'date-fns'
import { LayoutType } from 'recharts/src/util/types'
import { Props as XAxisProps } from 'recharts/src/cartesian/XAxis'
import { Props as YAxisProps } from 'recharts/src/cartesian/YAxis'

const barSize = 18
const tickCount = 5
const smallTickCount = 5
const gridLineWidth = 1
const axisLineWidth = 2
const smallTickWidth = 0.5
const secondaryColor = '#ccc'
const baseAxisColor = '#4b4b4b'

export const chartConfig = {
  tickCount,
  smallTickCount,
}

export const chartProps = {
  margin: {
    top: 30,
    right: 10,
    left: 0,
    bottom: 5,
  },
}

export const chartStyles = `
  .gridLine {
    visibility: hidden;
  }
  .gridLine:nth-child(${smallTickCount}n+1) {
    visibility: initial;
  }
  .gridLine:first-child {
    visibility: hidden;
  }
  .recharts-cartesian-axis-tick .numberTick {
    stroke-width: ${smallTickWidth};
  }
  .recharts-cartesian-axis-tick:nth-child(${smallTickCount}n+1) .ordinalTick,
  .recharts-cartesian-axis-tick:nth-child(${smallTickCount}n+1) .numberTick {
    stroke-width: ${gridLineWidth};
  }
  .recharts-cartesian-axis-tick:first-child .ordinalTick,
  .recharts-cartesian-axis-tick:first-child .numberTick {
    stroke: ${baseAxisColor};
    stroke-width: ${axisLineWidth};
  }
`

export const styleProps = {
  children: chartStyles,
}

export const gridProps = {
  stroke: secondaryColor,
  strokeWidth: gridLineWidth,
  strokeDasharray: 0,
  className: 'gridLine',
}

export const vGridProps = {
  ...gridProps,
  vertical: true,
  horizontal: false,
}

export const hGridProps = {
  ...gridProps,
  vertical: false,
  horizontal: true,
}

export const intervalAxisProps = {
  interval: 0,
  tickMargin: 5,
  stroke: baseAxisColor,
  axisLine: {
    strokeWidth: axisLineWidth,
  },
  tickLine: {
    strokeWidth: 1,
  },
}

const lineType = 'monotoneX'

export const lineProps = {
  strokeWidth: 3,
  dot: false,
  type: lineType,
}

const round = (value: number, precision: number) => {
  const multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}

export const tickFormatter = {
  numbers: (tick: any, index: number): string => {
    if (index % smallTickCount !== 0) return ''
    if (tick < -1000 || 1000 < tick) return round(tick / 1000, 1) + 'K'
    return tick
  },

  months: (tick: string, _index: number) => {
    const date = new Date(tick)
    const m = getMonth(date)
    if (m % 6 !== 0) return ''
    return format(date, 'MMM yy')
  },
}

export const labelFormatter = {
  months: (label: string) => format(new Date(label), 'MMMM yyyy'),
}

export const numberAxisProps: YAxisProps = {
  interval: 0,
  axisLine: true,
  tickCount: tickCount * smallTickCount + 2,
  tickMargin: 5,
  tickFormatter: tickFormatter.numbers,
  tickLine: {
    stroke: secondaryColor,
    className: 'numberTick',
  },
}

export const responsiveHeight = (data: any[]) => {
  return (
    (barSize + barSize) * data.length +
    chartProps.margin.top +
    chartProps.margin.bottom
  )
}

export const ordinalAxis: XAxisProps = {
  type: 'number',
  interval: 0,
  axisLine: false,
  tickCount: tickCount * smallTickCount + 2,
  tickMargin: 10,
  tickFormatter: tickFormatter.numbers,
  tickLine: {
    stroke: '#ccc',
    className: 'ordinalTick',
  },
}

export const baseAxisProps = {
  stroke: 'var(--spectrum-global-color-gray-700)',
  axisLine: {
    stroke: baseAxisColor,
    strokeWidth: axisLineWidth,
  },
}

export const categoryAxis: YAxisProps = {
  ...baseAxisProps,
  type: 'category',
  tickLine: false,
}

const CustomBar = ({ x, y, width, height, name, fill }: { x: number, y: number, width: number, height: number, name: string, fill: string }) => {
  const d = `M ${x + 1},${y} h ${width} v ${height} h -${width} Z`
  const props = { name, fill, x, y, width, height, d }
  return <path radius="0" className="recharts-rectangle" {...props} />
}

const bar = {
  barSize,
  shape: CustomBar,
  fill: 'var(--spectrum-alias-categorical-color-5)',
}

const layout: LayoutType = 'vertical'

const simpleBarChart = {
  ...chartProps,
  layout,
}

const responsiveContainer = {
  width: '100%',
  debounce: 50,
}

export const cProps = {
  bar,
  ordinalAxis,
  categoryAxis,
  simpleBarChart,
  styles: styleProps,
  vGrid: vGridProps,
  responsiveContainer,
}
