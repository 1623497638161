import { useTransactionTable } from 'context/transactionTableContext'
import { Layout, Content } from 'components/layouts/LoggedIn'
import TransactionsTable from 'components/Tables/TransactionsTable'

export default function Orders() {
  const tableState = useTransactionTable()

  return (
    <Layout>
      <Content variant='article' heading='Orders'>
        <TransactionsTable tableState={ tableState } />
      </Content>
    </Layout>
  )
}