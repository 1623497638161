import React from 'react'
import { useTable, usePagination, useSortBy } from 'react-table'
import HeadingCell from './HeadingCell'
import Pagination from './Pagination'

interface BasicTableProps {
  columns: any,
  data: any
}

export default function BasicTable(props: BasicTableProps) {
  const {
    columns,
    data
  } = props

  const renderCell = (cell: any) => {
    if (cell.column.cellRenderer) {
      return cell.column.cellRenderer(cell)
    } else {
      return cell.render('Cell')
    }
  }

  const table = useTable({
    columns,
    data,
    initialState: {
      pageSize: 50
    },
    multiSort: false,
  },
  useSortBy,
  usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = table

  return (
    <div>
    <table {...getTableProps()} className='spectrum-Table'>
      <thead className='spectrum-Table-head'>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, i) =>
              <HeadingCell column={column} key={i} />
            )}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className='spectrum-Table-body'>
        {page.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()} className='spectrum-Table-row'>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()} className='spectrum-Table-cell'>
                  { renderCell(cell) }
                </td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
    <Pagination table={ table } />
  </div>
  )
}
