
import React from 'react'
import DatePicker from 'components/modules/DatePicker'

interface IFactoryProps {
  label: string,
  placeholder: string,
}

interface IFilterProps {
  filterValue: any,
  setFilter: any,
}

const mapFilterProps = (props: IFilterProps) => ({
  selectedDay: props.filterValue,
  onSelectionChange: props.setFilter
})

export const datePickerFactory = (factoryProps: IFactoryProps) =>
  (filterProps: IFilterProps) => {
    const filterProps2 = mapFilterProps(filterProps)
    return <DatePicker
      {...factoryProps}
      {...filterProps2} />
  }
