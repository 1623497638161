import { Breadcrumbs, Item } from 'components/modules/Breadcrumbs'
import { Layout, Content } from 'components/layouts/LoggedIn'
import CashOutTable from 'components/Tables/CashOutTable'
import { useParams } from 'react-router-dom'
import { useRoutes } from 'routes'

const ProfileCashoutsLayout = () => {
  const { username } = useParams<{ username: string }>();
  const { profilePath, profilesPath, profileCashoutsPath } = useRoutes()
  return (
    <Layout>
      <Content variant='article' heading='Cashouts'>
        <Breadcrumbs>
          <Item to={ profilesPath() }> Profiles </Item>
          <Item to={ profilePath({ username }) }> { username } </Item>
          <Item to={ profileCashoutsPath({ username }) }> cashouts </Item>
        </Breadcrumbs>
        <ProfileCashouts />
      </Content>
    </Layout>
  )
}

const ProfileCashouts = () => {
  const { username } = useParams<{ username: string }>();
  return (
    <div>
      <p />
      <CashOutTable username={ username } />
    </div>
  )
}

export default ProfileCashoutsLayout
