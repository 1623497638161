import { isString, FilterType } from 'lib/table'
import { textFieldFactory } from 'components/Table/FilterFactories'

const filters: FilterType[] = [
  {
    display: 'Username',
    name: 'username',
    filter: textFieldFactory({
      label: 'Username',
      placeholder: 'Enter username...',
    }),
    mapping: (value: string): string => value,
    validator: isString,
  },
  {
    display: 'Email',
    name: 'email',
    filter: textFieldFactory({
      label: 'Email',
      placeholder: 'Enter email...',
    }),
    mapping: (value: string): string => value,
    validator: isString,
  },
  {
    display: 'Name',
    name: 'displayName',
    filter: textFieldFactory({
      label: 'Name',
      placeholder: 'Enter name...',
    }),
    mapping: (value: string): string => value,
    validator: isString,
  },
]

export default filters
