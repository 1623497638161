import { Breadcrumbs, Item } from 'components/modules/Breadcrumbs'
import { Layout, Content } from 'components/layouts/LoggedIn'
import InvitesTable from 'components/Tables/InvitesTable'
import { useParams } from 'react-router-dom'
import { useRoutes } from 'routes'

const ProfileInvitesLayout = () => {
  const { username } = useParams<{ username: string }>();
  const { profilePath, profilesPath, profileInvitesPath } = useRoutes()
  return (
    <Layout>
      <Content variant='article' heading={ username }>
        <Breadcrumbs>
          <Item to={ profilesPath() }> Profiles </Item>
          <Item to={ profilePath({ username }) }> { username } </Item>
          <Item to={ profileInvitesPath({ username }) }> invites </Item>
        </Breadcrumbs>
        <ProfileInvites />
      </Content>
    </Layout>
  )
}

const ProfileInvites = () => {
  const { username } = useParams<{ username: string }>()
  return (
    <div>
      <p />
      <InvitesTable username={ username } />
    </div>
  )
}

export default ProfileInvitesLayout
