import { gql } from '@apollo/client';

export const EVENTS_QUERY = gql`
  query events($skip: Int, $limit: Int, $filter: JSON, $sort: String, $sortOrder: String) {
    eventsForAdmin(skip: $skip, limit: $limit, filter: $filter, sort: $sort, sortOrder: $sortOrder){
      count
      events {
        id
        actor
        action
        createdAt
				referrer
				queryParams
				page
				content
				device
				book
				source
				sourceType
				device
      }
    }
  }
`
