import config from 'config'

const headers = (accessToken: string) => {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  }
}

export const fetchArticle = async (articleId: string, accessToken: string) => {
  const url = `${config.articleUrl}/${articleId}`
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    headers: headers(accessToken),
  })
  const data = await response.json()
  return data
}
