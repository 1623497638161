
import React from 'react'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { ReactComponent as Illustration } from 'images/404.svg'
import '@spectrum-css/illustratedmessage/dist/index-vars.css'

export const NotFound = () => {
  return (
    <Layout>
      <Content variant='status'>
        <div className='spectrum-IllustratedMessage'>
          <Illustration className='spectrum-IllustratedMessage-illustration' />
          <h2 className='spectrum-Heading spectrum-Heading--sizeL spectrum-Heading--light spectrum-IllustratedMessage-heading'>
            { 'Error 404: Page not found' }
          </h2>
          <p className='spectrum-Body spectrum-Body--sizeS spectrum-IllustratedMessage-description'>
            { 'The link you followed may be broken, or the page may have been removed.' }
          </p>
        </div>
      </Content>
    </Layout>
  )
}

export default NotFound
