import * as Cells from 'components/Table/Cells'

const columns = [
  {
    Header: 'Username',
    accessor: 'username',
    cellRenderer: Cells.UserLinkCell,
  },
  {
    Header: 'Role',
    accessor: 'role',
  },
]

export default columns