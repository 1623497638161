import {
  Form,
  TextField,
  Flex,
  Divider,
  Heading
} from '@adobe/react-spectrum'

import {
  Address
} from 'api/checkout'

interface AddressProps {
  address: Address
  dispatch: any
}

export const types = {
  SET_ADDRESS_LINE1: 'SET_ADDRESS_LINE1',
  SET_ADDRESS_LINE2: 'SET_ADDRESS_LINE2',
  SET_CITY: 'SET_CITY',
  SET_COMPANY_NAME: 'SET_COMPANY_NAME',
  SET_COUNTRY: 'SET_COUNTRY',
  SET_FULLNAME: 'SET_FULLNAME',
  SET_STATE: 'SET_STATE',
  SET_TELEPHONE: 'SET_TELEPHONE',
  SET_ZIPCODE: 'SET_ZIPCODE'
}

interface Action {
  type: any
  value: string
}

export const addressReducer = (state: Address, action: Action): Address => {
  switch (action.type) {
    case types.SET_ADDRESS_LINE1:
      return { ...state, addressLine1: action.value }
    case types.SET_ADDRESS_LINE2:
      return { ...state, addressLine2: action.value }
    case types.SET_CITY:
      return { ...state, city: action.value }
    case types.SET_COMPANY_NAME:
      return { ...state, companyName: action.value }
    case types.SET_COUNTRY:
      return { ...state, country: action.value }
    case types.SET_FULLNAME:
      return { ...state, fullName: action.value }
    case types.SET_STATE:
      return { ...state, state: action.value }
    case types.SET_TELEPHONE:
      return { ...state, telephone: action.value }
    case types.SET_ZIPCODE:
      return { ...state, zipCode: action.value }
    default:
      return state
  }
}

const H2: React.FC<{}> = ({ children }) =>
  <Heading
    level={3}
    marginBottom='0'
    children={ children }
  />

export const AddressComponent: React.FC<AddressProps> = ({ address, dispatch }) => {
  return (
    <Flex direction='column' gap='size-125'>
      <H2> Shipping address </H2>
      <Divider size='M' />
      <Form maxWidth='size-3600' labelPosition='side' isQuiet>
        <TextField
          label='Fullname'
          value={ address.fullName }
          onChange = { (value) => dispatch({ type: types.SET_FULLNAME, value: value }) }
        />
        <TextField
          label='Line 1'
          value={ address.addressLine1 }
          onChange = { (value) => dispatch({ type: types.SET_ADDRESS_LINE1, value: value }) }
        />
        <TextField
          label='Line 2'
          value={ address.addressLine2 }
          onChange = { (value) => dispatch({ type: types.SET_ADDRESS_LINE2, value: value }) }
        />
        <TextField
          label='City'
          value={ address.city }
          onChange = { (value) => dispatch({ type: types.SET_CITY, value: value }) }
        />
        <TextField
          label='Country'
          value={ address.country }
          onChange = { (value) => dispatch({ type: types.SET_COUNTRY, value: value }) }
        />
        <TextField
          label='Zipcode'
          value={ address.zipCode }
          onChange = { (value) => dispatch({ type: types.SET_ZIPCODE, value: value }) }
        />
        <TextField
          label='State'
          value={ address.state }
          onChange = { (value) => dispatch({ type: types.SET_STATE, value: value }) }
        />
      </Form>
    </Flex>
  )
}
