import React from 'react'
import Table from 'components/Table/Table'
import columnsConfiguration from './columns'
import filterTypes from './filters'
import { FetchDataProps } from 'lib/table'
import config from 'config'
import { useAuth } from 'context/authContext'

const RelatedIdentifiersTable: React.FC<{ username?: string }> = ({
  username,
}) => {
  const { accessToken } = useAuth()

  const [data, setData] = React.useState<any[]>([])
  const [filters, setFilters] = React.useState<any>(
    username ? { username } : {}
  )
  const [sortBy, setSortBy] = React.useState<any[]>([])
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [pageCount, setPageCount] = React.useState(0)
  const [page, setPage] = React.useState(0)
  const fetchIdRef = React.useRef(0)

  const columns = React.useMemo(() => columnsConfiguration, [])

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sortBy, filters }: FetchDataProps) => {
      setSortBy(sortBy)
      setPage(pageIndex)
      const fetchId = ++fetchIdRef.current
      setLoading(true)
      const sorting = sortBy[0]
      try {
        const url = config.relatedIdentifiersUrl
        const res = await fetch(url, {
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify(
            {
              ...filters,
              sort: sorting?.id,
              skip: pageSize * pageIndex,
              limit: pageSize,
            },
            null,
            2
          ),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
        const results = await res.json()

        if (!results.data) {
          setError(true)
          return
        }

        if (fetchId === fetchIdRef.current) {
          console.log('SETTING DATA', results.data)
          setData(results.data)
          setPageCount(Math.ceil(results.total / pageSize))
          setLoading(false)
        }
      } catch (e) {
        console.error('Error fetching data', e)
        setError(true)
      }
    },
    [accessToken]
  )

  return (
    <>
      <Table
        filterTypes={filterTypes}
        columns={columns}
        data={data}
        loading={loading}
        fetchData={fetchData}
        pageCount={pageCount}
        page={page}
        filters={filters}
        setFilters={setFilters}
        sortBy={sortBy}
        error={error}
      />
    </>
  )
}

export default RelatedIdentifiersTable
