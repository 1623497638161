
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './context/authContext'
import { MeshProvider } from './context/meshContext'
import { ToastProvider } from 'components/modules/Toast'
import { TransactionTableProvider } from './context/transactionTableContext'
import Routes from 'routes'
import './App.css'

export default function App() {
  return (
    <AuthProvider>
      <MeshProvider>
        <TransactionTableProvider>
          <ToastProvider>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </ToastProvider>
        </TransactionTableProvider>
      </MeshProvider>
    </AuthProvider>
  );
}
