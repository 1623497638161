import React from 'react'

import {
  NumberField,
  Flex,
  Divider,
  Heading
} from '@adobe/react-spectrum'

import {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  Column,
  Row,
  Cell
} from 'components/modules/Table'

import styles from './CreateOrder.module.css'

import { OrderItem } from './types'
import { AddItem } from './AddItem'
import { QuickActions } from './QuickActions'

interface Price {
  amount: number
  currencySymbol: string
}

const H2: React.FC<{}> = ({ children }) =>
  <Heading
    level={3}
    marginBottom='0'
    children={ children }
  />

const TitleCell: React.FC<{}> = ({ children }) =>
  <Cell
    UNSAFE_className={ styles.titleCell }
    children={ children } />

const PriceCell: React.FC<{ price: Price }> = ({ price }) =>
  <Cell
    UNSAFE_className={ styles.priceCell }
    children={ `${price.amount} ${price.currencySymbol}` } />

interface ItemsTableProps {
  items: OrderItem[]
  setItems: any
}

export const ItemsTable: React.FC<ItemsTableProps> = ({ items, setItems }) => {
  const deleteItem = (i: number) => () => {
    const tmp = [ ...items ]
    tmp.splice(i, 1)
    setItems(tmp)
  }

  const updateQuantity = (i: number) => (v: number) => {
    const tmp = [ ...items ]
    tmp[i].quantity = v
    setItems(tmp)
  }

  return (
    <Flex direction='column' gap='size-125'>
      <H2> Order Items </H2>
      <Divider size='M' />
      <Table isQuiet>
        { !!items.length &&
          <TableHeader>
            <Column> quantity </Column>
            <Column> ISBN </Column>
            <Column> Title </Column>
            <Column> Variant </Column>
            <Column> Price </Column>
            <Column>  </Column>
          </TableHeader> }
        <TableBody>
          { items.map((item, i) =>
            <Row key={ i }>
              <Cell><NumberField value={ item.quantity } onChange={ updateQuantity(i) } /></Cell>
              <Cell>{ item.edition.ISBN13 }</Cell>
              <TitleCell>{ item.edition.title }</TitleCell>
              <Cell>{ item.edition.productFormat }</Cell>
              <PriceCell price={ item.edition.price } />
              <Cell>
                <QuickActions onDelete={ deleteItem(i) } />
              </Cell>
            </Row>
          )}
        </TableBody>
        <TableFooter>
          <Row>
            <Cell colSpan={1000}>
              <AddItem items={ items } setItems={ setItems } />
            </Cell>
          </Row>
        </TableFooter>
      </Table>
    </Flex>
  )
}
