import * as Cells from 'components/Table/Cells';

const columns = [
  {
    Header: 'Full name',
    accessor: 'fullName'
  },
  {
    Header: 'Company Name',
    accessor: 'companyName'
  },
  {
    Header: 'Address Line 1',
    accessor: 'addressLine1',
  },
  {
    Header: 'Address Line 2',
    accessor: 'addressLine2',
  },
  {
    Header: 'Postal code',
    accessor: 'zipCode',
  },
  {
    Header: 'City',
    accessor: 'city'
  },
  {
    Header: 'State',
    accessor: 'state'
  },
  {
    Header: 'Country',
    accessor: 'country'
  },
  {
    Header: 'Telephone',
    accessor: 'telephone'
  },
  {
    Header: 'Default Address',
    accessor: 'isDefault',
    cellRenderer: Cells.BooleanCell
  }
]

export default columns
