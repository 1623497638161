const apiUrl: string = process.env.REACT_APP_API_URL || ''
const authUrl: string = process.env.REACT_APP_AUTH_URL || apiUrl
const checkoutUrl: string = 'http://api.mojoreads.local/payment/v2/checkout'
const webUrl: string = process.env.REACT_APP_WEB_URL || ''
const paymentURL: string = `${process.env.REACT_APP_API_URL}/payment/v1`

const signInUrl: string = `${authUrl}/signin`
const meUrl: string = `${authUrl}/me`
const meshUrl: string = `${apiUrl}/mesh`
const addressURL: string = `${paymentURL}/address`
const blockSearchUrl: string = `${apiUrl}/network/v1/profile/search?query=`
const blockUrl: string = `${authUrl}/user/block`
const networkUrl: string = `${apiUrl}/network`
const moderationUrl: string = `${apiUrl}/admin-api/moderation`
const userInfoUrl: string = `${apiUrl}/admin-api/user-info`
const deleteUserUrl: string = `${apiUrl}/admin-api/delete-user`
const changeEmailUrl: string = `${apiUrl}/admin-api/change-email`
const makeAdminUrl: string = `${apiUrl}/admin-api/make-admin`
const removeAdminUrl: string = `${apiUrl}/admin-api/remove-admin`
const changeRoleUrl: string = `${apiUrl}/admin-api/profile-role`
const verifyEmailUrl: string = `${authUrl}/verify`
const orderItemsUrl: string = `${apiUrl}/admin-api/order-items`

const productsUrl: string = `${apiUrl}/admin-api/tables/products`
const editionsUrl: string = `${apiUrl}/admin-api/tables/editions`
const relatedIdentifiersUrl: string = `${apiUrl}/admin-api/tables/related-identifiers`
const importsUrl: string = `${apiUrl}/admin-api/tables/imports`
const ftpUrl: string = `${apiUrl}/admin-api/tables/ftp`
const imagesUrl: string = `${apiUrl}/admin-api/tables/images`
const suppliersUrl: string = `${apiUrl}/admin-api/tables/suppliers`
const epubsUrl: string = `${apiUrl}/admin-api/tables/epubs`
const articleUrl: string = `${apiUrl}/admin-api/details/article`
const articlesUrl: string = `${apiUrl}/admin-api/tables/articles`
const reportedArticlesUrl: string = `${apiUrl}/admin-api/tables/reported-articles`
const blockEditionUrl: string = `${apiUrl}/admin-api/catalog/edition/block`
const unBlockEditionUrl: string = `${apiUrl}/admin-api/catalog/edition/unblock`
const reprocesEditionUrl: string = `${apiUrl}/admin-api/catalog/edition/reprocess`

const config = {
  signInUrl,
  meUrl,
  meshUrl,
  webUrl,
  apiUrl,
  checkoutUrl,
  addressURL,
  userInfoUrl,
  networkUrl,
  moderationUrl,
  blockUrl,
  blockSearchUrl,
  deleteUserUrl,
  changeEmailUrl,
  makeAdminUrl,
  removeAdminUrl,
  changeRoleUrl,
  blockEditionUrl,
  unBlockEditionUrl,
  verifyEmailUrl,
  orderItemsUrl,
  productsUrl,
  editionsUrl,
  relatedIdentifiersUrl,
  importsUrl,
  imagesUrl,
  epubsUrl,
  articleUrl,
  articlesUrl,
  reportedArticlesUrl,
  suppliersUrl,
  ftpUrl,
  reprocesEditionUrl
}

export default config
