import config from 'config'
import { useState, useMemo } from 'react'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { Breadcrumbs, Item } from 'components/modules/Breadcrumbs'
import { useParams } from 'react-router-dom'
import { useRoutes } from 'routes'
import { TextField, Flex, ActionButton } from '@adobe/react-spectrum'
import { withProfile } from './withProfile'
import { useAuth } from 'context/authContext'
import { useQuery } from '@apollo/client'
import { PROFILE_QUERY } from 'api/mesh/profiles/profilesQueries'
import { useToast } from 'components/modules/Toast'

interface Profile {
  username: string
  email: string
}

interface ProfileProps {
  profile: Profile
}

const ProfilesChangeEmailLayout = (props: ProfileProps) => {
  const { username } = useParams<{ username: string }>();
  const { profilePath, profilesPath, profileChangeEmailPath } = useRoutes()
  const { profile } = props;

  return (
    <Layout>
      <Content variant='article' heading='Change Email'>
        <Breadcrumbs>
          <Item to={ profilesPath() }> Profiles </Item>
          <Item to={ profilePath({ username }) }> { username } </Item>
          <Item to={ profileChangeEmailPath({ username }) }> Change Email </Item>
        </Breadcrumbs>
        <ProfilesChangeEmail profile={ profile }/>
      </Content>      
    </Layout>
  )
}

const ProfilesChangeEmail = (props: ProfileProps) => {
  const { profile } = props;
  const { username, email } = profile
  const { accessToken } = useAuth();
  const { addToast } = useToast()
  const { refetch } = useQuery(PROFILE_QUERY, { variables: { username } })
  const [ newEmail, setNewEmail ] = useState({ username, email: '' })

  let isValid = useMemo(
    () => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      .test(newEmail.email),
      [newEmail.email])

  const onEmailChange = (e: string) => {
    setNewEmail({ ...newEmail, email: e })
  }

  const onSubmit = async () => {
    try {
        const rawResponse = await fetch(config.changeEmailUrl, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(newEmail)
        })

        const content = await rawResponse.json()

        if (rawResponse.status === 200) {
        refetch()
        addToast({ variant: 'positive', message: 'Email has been updated', timeout: 3000 })
        } else {
        addToast({ variant: 'negative', message: content.message, timeout: 3000 })
        }
    } catch (error) {
        addToast({ variant: 'negative', message: error, timeout: 3000 })
    }
    setNewEmail({ ...newEmail, email: '' })
  }

  return (
    <div>
      <p>Change the email of this user <strong>{ username }</strong>.</p>
      <Flex direction="column" gap="size-200">
        <TextField 
            label="Username"
            value={ username }
            isDisabled={ true } />
        <TextField 
            label="Current Email Address"
            value={ email }
            isDisabled={ true } />
        <TextField
            validationState={isValid ? 'valid' : 'invalid'}
            label="New Email Address"
            inputMode="email"
            value={ newEmail.email }
            onChange={ (e) => onEmailChange(e) }
            autoFocus={ true }
            />
        <ActionButton
            width={ 150 }
            onPress={ () => onSubmit() }
            type="submit"
            isDisabled={ !isValid }>
            Change Email
        </ActionButton>
      </Flex>
    </div>
  )
}

export default withProfile(ProfilesChangeEmailLayout)