import { Layout, Content } from 'components/layouts/LoggedIn'
import EpubsTable from 'components/Tables/EpubsTable'


export function Epubs() {
  return (
    <Layout>
      <Content variant='article' heading='Epubs'>
      <EpubsTable />
      </Content>
    </Layout>
  )
}
