import { Layout, Content } from 'components/layouts/LoggedIn'
import ProductsTable from 'components/Tables/ProductsTable'


export function Products() {
  return (
    <Layout>
      <Content variant='article' heading='Products'>
      <ProductsTable />
      </Content>
    </Layout>
  )
}
