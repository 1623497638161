import { useParams } from 'react-router-dom'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { fetchSupplier } from 'api/details/catalog'
import { ProgressBar } from '@adobe/react-spectrum'
import { useAuth } from 'context/authContext'
import { useEffect, useState } from 'react'
import { Table, Item } from 'components/modules/KeyValueTable'
import { formatDate } from 'lib/format'
import ProductsTable from 'components/Tables/ProductsTable'
import BackupJobsTable from 'components/Tables/BackupJobsTable'
import config from 'config'

export function Supplier() {
  const { id } = useParams<{ id: string }>()
  const { accessToken } = useAuth()
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any | undefined>(undefined);
  const [error, setError] = useState<string | undefined>()

  useEffect(() => {
    (async () => {
      try {
        const result: any = await fetchSupplier(id, accessToken);
        setData(result)
        setLoading(false)
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message)
        } else {
          setError("Error loading data");
        }
        setLoading(false)
      }
    })()
  }, [accessToken, id])

  const render = () => {
    if (loading) return <ProgressBar label='Loading…' isIndeterminate />
    if (error) return <p>{ error }</p>
    if (!data?.supplier) return <p>No record found</p>
    return <div>
    <section>
      <Table>
        <Item label='Id'>{ data.supplier.id } </Item>
        <Item label='Name'>{ data.supplier.name } </Item>
        <Item label='Email'>{ data.supplier.email } </Item>
        <Item label='Contact'>{ data.supplier.contact_name } </Item>
        <Item label='Active'>{ data.supplier.active ? "yes" : "no" } </Item>
        <Item label='Payment'>{ data.supplier.active_for_payment ? "yes" : "no" } </Item>
        <Item label='Priority'>{ data.supplier.product_priority } </Item>
        <Item label='Created'>{ formatDate(data.supplier.created_at) } </Item>
      </Table>
    </section>
    <section>
      <h4>Imports</h4>
      <BackupJobsTable supplier_id={ data.supplier.id }></BackupJobsTable>
    </section>
    <section>
      <h4>Products</h4>
      <ProductsTable supplier_id={ data.supplier.id }></ProductsTable>
    </section>
    </div>

  }

  const headers = (accessToken: string) => {
    return {
      'Accept': 'application/json',
      'Authorization': `Bearer ${ accessToken }`
    }
  }

  const uploadFile = async (event: any) => {
    const data = new FormData() ;
    data.append('onix', event.target.files[0]);
    try {
      const result = await fetch(`${config.apiUrl}/admin-api/suppliers/upload-onix/${id}`, {
        method: 'POST',
        mode: 'cors',
        headers: headers(accessToken),
        body: data
      })
      console.log(result)
    } catch (e) {
      console.log("error");
    }
  }


  return (
    <Layout>
      <Content variant='article' heading='Supplier'>
        { render() }

        <h4>Upload an ONIX JSON product</h4>
        <input type="file" onChange={ uploadFile } />
      </Content>
    </Layout>
  )
}
