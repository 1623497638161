import { mapDate, isInteger, isString, isDate, FilterType } from 'lib/table'

import {
  textFieldFactory,
} from 'components/Table/FilterFactories'

const filters: FilterType[] = [
  {
    display: 'Edition Id',
    name: 'id',
    filter: textFieldFactory({
      label: 'Edition Id',
      placeholder: 'Enter Edition Id...',
      type: 'number',
    }),
    mapping: parseInt,
    validator: isInteger,
  },
  {
    display: 'ISBN 13',
    name: 'isbn_13',
    filter: textFieldFactory({
      label: 'ISBN 13',
      placeholder: 'Enter an ISBN 13...',
    }),
    mapping: (x: string): string => x,
    validator: isString,
  },
  {
    display: 'ISBN 10',
    name: 'isbn_10',
    filter: textFieldFactory({
      label: 'ISBN 10',
      placeholder: 'Enter an ISBN 10...',
    }),
    mapping: (x: string): string => x,
    validator: isString,
  },
  {
    display: 'GTIN 10',
    name: 'gtin_13',
    filter: textFieldFactory({
      label: 'GTIN 13',
      placeholder: 'Enter a GTIN 13...',
    }),
    mapping: (x: string): string => x,
    validator: isString,
  },
]

export default filters
