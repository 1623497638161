import { gql } from '@apollo/client';

export const CREDITS_QUERY = gql`
  query credits($skip: Int, $limit: Int, $filter: JSON, $sort: String, $sortOrder: String) {
    creditsForAdmin(skip: $skip, limit: $limit, filter: $filter, sort: $sort, sortOrder: $sortOrder){
      count
      credits {
        id
        username
        credits
        event
        createdAt
      }
    }
  }
`

export const BOOKSTORE_CREDITS = gql`
query bookstoreCredits {
  bookstoreCredits {
    totalCredits
    supporterCredits
    affiliateCredits
    bookstores {
      username
      totalCredits
      supporterCredits
      affiliateCredits
      isOnboarded
    }
  }
}
`

export interface DashboardData {
  monthlySalesReport: { date: string, amount: number }[]
  creditsByDate: {
    buckets: {
      date: string
      cash_out_credits: number
      credits_in: number
      credits_out: number
      credits_total: number
      custom_credits: number
      install_android_app_credits: number
      install_chrome_plugin_credits: number
      install_ios_app_credits: number
      invite_success_credits: number
      mojo_credits: number
      profile_completion_credits: number
      purchase_credits: number
      rate_credits: number
      redeem_voucher_credits: number
      review_credits: number
      shipping_credits: number
      signup_bonus_credits: number
    }[]
  }
  cumulativeCredits: {
    buckets: {
      date: string
      credits: number
    }[]
  }
}

export const CREDITS_BY_DATE_QUERY = gql`
  query credits {
    monthlySalesReport {
      date
      amount
    }

    paymentModesReport {
      paymentMode
      amount
    }

    cumulativeCredits {
      buckets {
        date
        credits
      }
    }
    creditsByDate {
      buckets {
        credits_total
        credits_in
        credits_out
        install_ios_app_credits
        redeem_voucher_credits
        invite_success_credits
        profile_completion_credits
        review_credits
        cash_out_credits
        mojo_credits
        purchase_credits
        install_android_app_credits
        shipping_credits
        rate_credits
        custom_credits
        signup_bonus_credits
        install_chrome_plugin_credits
        date
      }
    }
  }
`

export const ADD_CREDITS_MUTATION = gql`
  mutation AwardCredits($username: String! $meta: JSON! $credits: Int!) {
    AwardCredits(username: $username, meta: $meta, credits: $credits){
      createdAt
      credits
    }
  }
`

export const CREDIT_BY_USERNAME = gql`
  query creditsByUsername($username: String, $limit: Int, $skip: Int) {
    creditsByUsername(username: $username, limit: $limit, skip: $skip ) {
      count
    }
  }
`
