import {
	ApolloClient,
	InMemoryCache,
	createHttpLink,
	defaultDataIdFromObject
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context';
import { ApolloProvider } from '@apollo/client'
import config from 'config'

const httpLink = createHttpLink({
  uri: config.meshUrl
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('accessToken');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
   dataIdFromObject: (object: any) => {
     switch (object.__typename) {
       case 'Profile': return object.username;
       default: return defaultDataIdFromObject(object);
     }
   }
 })
});

const MeshProvider = ({ children }: { children: any }) => {
  return (
    <ApolloProvider client={ client }>
      { children }
    </ApolloProvider>
  )
}

export {
  MeshProvider
}
