import { Layout, Content } from 'components/layouts/LoggedIn'
import ProfilesTable from 'components/Tables/ProfilesTable'

export const ProfilesIndex = () => {
  return (
    <Layout>
      <Content variant='article' heading='Profiles'>
        <ProfilesTable  />
      </Content>
    </Layout>
  )
}

export default ProfilesIndex