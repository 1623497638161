import { useState } from 'react';

export default function useAccessToken() {
  const getAccessToken = ():string => {
    return localStorage.getItem('accessToken') || '';
  };

  const [accessToken, setAccessToken] = useState<string>(getAccessToken());

  const saveAccessToken = (accessToken:string) => {
    localStorage.setItem('accessToken', accessToken);
    setAccessToken(accessToken);
  };

  return {
    setAccessToken: saveAccessToken,
    accessToken
  }
}
