import { Layout, Content } from 'components/layouts/LoggedIn'
import EventsTable from 'components/Tables/EventsTable'

const Events = () => {
  return (
    <Layout>
      <Content variant='article' heading='Events'>
        <EventsTable />
      </Content>
    </Layout>
  )
}

export default Events
