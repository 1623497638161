import { gql } from '@apollo/client';

const PROFILES_QUERY = gql`
  query profilesForAdmin($skip: Int, $limit: Int, $sort: String, $where: JSON) {
    profilesForAdmin(skip: $skip, limit: $limit, sort: $sort, where: $where) {
      total
      profiles {
        username
        displayName
        email
        role
        createdAt
      }
    }
  }
`
const CONVERT_TO_BOOKSTORE_MUTATION = gql`
  mutation convertToBookStore($username: String!) {
    convertToBookStore(username: $username) {
      username
      role
    }
  }
`

const PROFILE_QUERY = gql`
  query profiles($username: String!) {
    credits: creditsByUsername(username: $username) {
      count
    }

    profiles: profilesByUsernames(usernames: [$username]) {
      username
      displayName
      caption
      role
      email
      avatar {
        baseUrl
        key
      }
      isFollowing
      followersCount
      articlesCount
      reviewsCount
     }
  }
`

export {
  PROFILES_QUERY,
  PROFILE_QUERY,
  CONVERT_TO_BOOKSTORE_MUTATION
}
