import * as Cells from 'components/Table/Cells';

const columns = [
  {
    Header: 'username',
    accessor: 'username',
    cellRenderer: Cells.UserLinkCell,
  },
  {
    Header: 'displayName',
    accessor: 'displayName',
  },
  {
    Header: 'email',
    accessor: 'email',
  },
  {
    Header: 'role',
    accessor: 'role',
  },
  {
    Header: 'created',
    accessor: 'createdAt',
    cellRenderer: Cells.DateCell
  },
]

export default columns
