import React from 'react'
import classNames from 'classnames'

import { format, isSameDay, isSameMonth } from 'date-fns'

const now = new Date()

interface IProps {
  day: Date
  month: Date
  selected?: Date
  setSelected: (val: Date) => void
  onPress?: () => void
}

export const CalendarTCell = (props: IProps) => {
  const { day, month, selected, setSelected } = props

  const title = format(day, 'EEEE, MMMM d, yyyy')
  const value = format(day, 'd')

  const spanClasses = classNames('spectrum-Calendar-date', {
    'is-today': isSameDay(day, now),
    'is-selected': selected && isSameDay(day, selected),
    'is-outsideMonth': !isSameMonth(day, month),
  })

  return (
    <td
      role="gridcell"
      title={title}
      className="spectrum-Calendar-tableCell"
      aria-disabled="true"
      aria-selected="false"
      aria-invalid="false"
    >
      <span
        role="presentation"
        onClick={() => setSelected(day)}
        className={spanClasses}
      >
        {value}
      </span>
    </td>
  )
}

export default CalendarTCell
