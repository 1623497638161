import { Layout, Content } from 'components/layouts/LoggedIn'
import InvitesTable from 'components/Tables/InvitesTable'

const Invites = () => {
  return (
    <Layout>
      <Content variant='article' heading='Invites'>
        <InvitesTable />
      </Content>
    </Layout>
  )
}

export default Invites