import * as Cells from 'components/Table/Cells'

const columns = [
  {
    Header: 'Product',
    accessor: 'supplier_product_id',
    disableSortBy: true,
    cellRenderer: Cells.ProductLinkCell
  },
  {
    Header: 'Supplier',
    accessor: 'supplier_id',
    disableSortBy: true,
    cellRenderer: Cells.SupplierLinkCell
  },
  {
    Header: 'Import',
    accessor: 'backup_job_id',
    disableSortBy: true,
    cellRenderer: Cells.ImportLinkCell
  },
  {
    Header: 'Edition',
    accessor: 'edition_id',
    disableSortBy: true,
    cellRenderer: Cells.EditionLinkCell,
  },
  {
    Header: 'ISBN 13',
    accessor: 'isbn_13',
    disableSortBy: true,
  },
  {
    Header: 'Active',
    accessor: 'is_active',
    disableSortBy: true,
    cellRenderer: Cells.BooleanCell,
  },
  {
    Header: 'Type',
    accessor: 'notification_type',
    disableSortBy: true,
  },
  {
    Header: 'Form',
    accessor: 'product_form',
    disableSortBy: true,
  },
  {
    Header: 'Title',
    accessor: 'title',
    disableSortBy: true,
  },
  {
    Header: 'Price',
    accessor: 'amount',
    disableSortBy: true,
    cellRenderer: Cells.CurrencyCell,
  },
  {
    Header: 'Created',
    accessor: 'created_at',
    disableSortBy: true,
    cellRenderer: Cells.DateCell,
  },
]

export default columns
