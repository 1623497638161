import * as Cells from 'components/Table/Cells';

const columns = [
  {
    Header: 'post',
    accessor: 'post',
    cellRenderer: Cells.ReportedPostLinkCell
  },
  {
    Header: 'content',
    accessor: 'body',
  },
  {
    Header: 'count',
    accessor: 'reportedCount',
  },
  {
    Header: 'Status',
    accessor: 'isDeleted',
    cellRenderer: (cell:any) => cell.value ? 'deleted' : 'reported'
  },
]

export default columns