import config from 'config'

export const undeleteArticle = async (id: string, accessToken: string) => {
  try {
    const rawResponse = await fetch(
      `${config.moderationUrl}/article/undelete`,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ article_id: id }),
      }
    )
    const content = await rawResponse.json()

    return content
  } catch (error) {
    console.log(error)
  }
}

export const deleteArticle = async (id: string, accessToken: string) => {
  try {
    const rawResponse = await fetch(`${config.moderationUrl}/article/delete`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ article_id: id }),
    })
    const content = await rawResponse.json()

    return content
  } catch (error) {
    console.log(error)
  }
}
