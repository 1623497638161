
import { useQuery } from '@apollo/client'
import { TRANSACTION_DETAILS_QUERY } from './queries'

export const useTransactionData = ({ id }) => {
  const { loading, error, data } = useQuery(TRANSACTION_DETAILS_QUERY, {
    variables: {
      id: parseInt(id)
    }
  })

  return { loading, error, data: data?.transactions?.transactions[0] }
}
