
import React from 'react'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { ProgressBar } from '@adobe/react-spectrum'

export const Loading = () => {
  return (
    <Layout>
      <Content variant='article'>
        <ProgressBar label='Loading…' isIndeterminate />
      </Content>
    </Layout>
  )
}

export default Loading
