import React, { useEffect, ReactElement } from 'react'
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  Row,
  TableInstance,
} from 'react-table'
import { ProgressBar, Flex } from '@adobe/react-spectrum'
import FilterGroup from './FilterGroup'
import Pagination from './Pagination'
import HeadingCell from './HeadingCell'
import './Table.css'

interface TableProps {
  columns: any[]
  filterTypes: any[]
  data: any
  loading: boolean
  error: boolean
  fetchData: any
  pageCount?: number
  page: number
  sortBy: any[]
  filters: any
  setFilters: any
  quickactions?: (row: Row, table: TableInstance) => ReactElement
}

export default function Table(props: TableProps) {
  const {
    columns,
    filterTypes,
    data,
    fetchData,
    loading,
    error,
    setFilters,
    quickactions,
    page: controlledPage,
    filters: customFilters,
    sortBy: customSortBy,
    pageCount: controlledPageCount,
  } = props

  const defaultColumn = React.useMemo(() => ({}), [])

  const renderCell = (cell: any) => {
    try {
      if (cell.column.cellRenderer) return <cell.column.cellRenderer {...cell} />
      if (!cell.value) return null
      return cell.value.length > 50 ? `${cell.value.slice(0, 50)}...` : cell.value
    } catch (error) {
      console.log("Hey error")
      return "error"
    }
  }

  const renderInfo = () => {
    if (error) return <div> Error fetching data </div>
    if (loading) return <ProgressBar label="Loading…" isIndeterminate />

    const total = controlledPageCount
      ? `of ~${controlledPageCount * pageSize}`
      : ''

    return `Showing ${page.length} ${total} results`
  }

  const table = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: customSortBy,
        pageIndex: controlledPage,
      },
      manualPagination: true,
      manualFilters: true,
      manualSortBy: true,
      multiSort: false,
      pageCount: controlledPageCount,
    },
    useFilters,
    useSortBy,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    state: { pageIndex, pageSize, sortBy, filters },
  } = table

  useEffect(() => {
    fetchData({ pageIndex, pageSize, sortBy, filters: customFilters })
  }, [fetchData, pageIndex, pageSize, sortBy, filters, customFilters])

  const renderFilters = () => {
    if (filterTypes.length === 0) return

    return (
      <>
        <FilterGroup
          filterTypes={filterTypes}
          filters={customFilters}
          setFilters={setFilters}
        />
      </>
    )
  }

  // Render the UI for your table
  return (
    <article className="spectrum-Site-article">
      {renderFilters()}
      <table {...getTableProps()} className="spectrum-Table">
        <thead className="spectrum-Table-head">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <HeadingCell column={column} key={i} />
              ))}
              {quickactions ? (
                <th
                  className="spectrum-Table-headCell"
                  children="Quickactions"
                />
              ) : null}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="spectrum-Table-body">
          {rows.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} className="spectrum-Table-row">
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="spectrum-Table-cell"
                    >
                      {renderCell(cell)}
                    </td>
                  )
                })}
                {quickactions ? (
                  <td className="spectrum-Table-cell">
                    <Flex height="size-250" alignItems="center">
                      {quickactions(row, table)}
                    </Flex>
                  </td>
                ) : null}
              </tr>
            )
          })}
          <tr className="spectrum-Table-row">
            <td colSpan={10000} className="spectrum-Table-cell">
              {renderInfo()}
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination table={table} />
    </article>
  )
}
