import React from 'react'
import { useToast } from 'components/modules/Toast'
import Table from 'components/Table/Table'
import columnsConfiguration from './columns'
import filterTypes from './filters'
import { FetchDataProps } from 'lib/table'
import config from 'config'
import { useAuth } from 'context/authContext'
import { ActionButton, Flex } from '@adobe/react-spectrum'

const OrderItemsTable: React.FC<{ username?: string }> = ({ username }) => {
  const { accessToken } = useAuth()
  const { addToast } = useToast()

  const [data, setData] = React.useState<any[]>([])
  const [filters, setFilters] = React.useState<any>(
    username ? { username } : {}
  )
  const [sortBy, setSortBy] = React.useState<any[]>([])
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [pageCount, setPageCount] = React.useState(0)
  const [page, setPage] = React.useState(0)
  const fetchIdRef = React.useRef(0)

  const columns = React.useMemo(() => columnsConfiguration, [])

  const fetchData = React.useCallback(
    async ({ pageIndex, sortBy, filters }: FetchDataProps) => {
      const pageSize = 50
      setSortBy(sortBy)
      setPage(pageIndex)
      const fetchId = ++fetchIdRef.current
      setLoading(true)
      const sorting = sortBy[0]
      try {
        const url = config.orderItemsUrl
        const res = await fetch(url, {
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify(
            {
              ...filters,
              sort: sorting?.id,
              skip: pageSize * pageIndex,
              limit: pageSize,
            },
            null,
            2
          ),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
        const results = await res.json()

        if (!results.data) {
          setError(true)
          return
        }

        if (fetchId === fetchIdRef.current) {
          setData(results.data)
          setPageCount(Math.ceil(results.total / pageSize))
          setLoading(false)
        }
      } catch (e) {
        console.error('Error fetching data', e)
        setError(true)
      }
    },
    [accessToken]
  )

  const download = async () => {
    addToast({
      variant: 'info',
      message: 'Starting to download',
    })

    try {
      const url = config.orderItemsUrl
      const res = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(
          {
            ...filters,
            sort: sortBy,
            skip: 0,
            limit: 10000,
            output: 'csv',
          },
          null,
          2
        ),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      const results = await res.json()

      if (!results.csv) {
        addToast({
          variant: 'negative',
          message: 'Download failed ',
        })

        return
      }

      // text content
      const file = new Blob([results.csv], { type: 'text/plain' }) // anchor link

      const element = document.createElement('a')

      element.href = URL.createObjectURL(file)
      element.download = 'order-items-' + Date.now() + '.csv' // simulate link click
      document.body.appendChild(element) // Required for this to work in FireFox
      element.click()

      addToast({
        variant: 'positive',
        message: 'Download is ready',
      })
    } catch {
      addToast({
        variant: 'negative',
        message: 'Download failed',
      })
    }
  }

  return (
    <>
      <Flex justifyContent="end">
        <ActionButton onPress={download}>Download</ActionButton>
      </Flex>

      <Table
        filterTypes={filterTypes}
        columns={columns}
        data={data}
        loading={loading}
        fetchData={fetchData}
        pageCount={pageCount}
        page={page}
        filters={filters}
        setFilters={setFilters}
        sortBy={sortBy}
        error={error}
      />
    </>
  )
}

export default OrderItemsTable
