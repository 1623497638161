
import React from 'react'
import { TextField } from '@adobe/react-spectrum'

interface IFactoryProps {
  label: string,
  placeholder: string,
  type?: string
}

interface IFilterProps {
  filterValue: any,
  setFilter: any,
}

const mapFilterProps = (props: IFilterProps) => ({
  value: props.filterValue,
  onChange: props.setFilter
})

export const textFieldFactory = (factoryProps: IFactoryProps) =>
  (filterProps: IFilterProps) => {
    const filterProps2 = mapFilterProps(filterProps)
    return <TextField
      {...factoryProps}
      {...filterProps2} />
  }
