
import React from 'react'
import { Text, Button, ProgressCircle } from '@adobe/react-spectrum'

// import styles from './SubmitButton.module.css'

interface SubmitButtonProps {
  isLoading: boolean,
  isDisabled?: boolean
}

export default function SubmitButton({ isLoading, isDisabled }: SubmitButtonProps) {
  return (
    <Button variant="cta" type="submit" isDisabled={ isLoading || isDisabled }>
      { isLoading &&
        <ProgressCircle
          aria-label="Loading…"
          marginEnd="size-150"
          size="S"
          isIndeterminate
          variant="overBackground" />}
      <Text>Submit</Text>
    </Button>
  )
}
