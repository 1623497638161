import React from 'react'
import { Provider, defaultTheme } from '@adobe/react-spectrum'
import SidebarHeader from 'components/layouts/Sidebar/SideBarHeader'
import styles from './LoggedOut.module.css'

interface LoggedOutProps {
  children: React.ReactNode
}

export default function LoggedOut({ children }: LoggedOutProps) {
  return (
    <Provider theme={defaultTheme} colorScheme="light">
      <div className={styles.root}>
        <div id="site-sidebar" className="spectrum-Site-sideBar">
          <SidebarHeader />
        </div>
        { children }
      </div>
    </Provider>
  )
}
