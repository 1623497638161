import { mapDate, isInteger, isString, isDate, FilterType } from 'lib/table'

import {
  pickerFactory,
  datePickerFactory,
  textFieldFactory,
} from 'components/Table/FilterFactories'

const filters: FilterType[] = [
  {
    display: 'Order Id',
    name: 'id',
    filter: textFieldFactory({
      label: 'Order Id',
      placeholder: 'Enter number...',
    }),
    mapping: parseInt,
    validator: isInteger,
  },
  {
    display: 'Minimum Ammount',
    name: 'amountMin',
    filter: textFieldFactory({
      label: 'Minimum Amount',
      placeholder: 'Enter number...',
    }),
    mapping: parseInt,
    validator: isInteger,
  },
  {
    display: 'Maximum Ammount',
    name: 'amountMax',
    filter: textFieldFactory({
      label: 'Maximum Amount',
      placeholder: 'Enter number...',
    }),
    mapping: parseInt,
    validator: isInteger,
  },
  {
    display: 'After',
    name: 'createdAtMin',
    filter: datePickerFactory({
      label: 'Choose a date',
      placeholder: 'dd.mm.yyyy',
    }),
    mapping: mapDate,
    validator: isDate,
  },
  {
    display: 'Before',
    name: 'createdAtMax',
    filter: datePickerFactory({
      label: 'Choose a date',
      placeholder: 'dd.mm.yyyy',
    }),
    mapping: mapDate,
    validator: isDate,
  },
  {
    display: 'Username',
    name: 'username',
    filter: textFieldFactory({
      label: 'Username',
      placeholder: 'Enter name...',
    }),
    mapping: (value: string): string => value,
    validator: isString,
  },
  {
    display: 'Status',
    name: 'paymentStatus',
    filter: pickerFactory({
      label: 'Pick status',
      items: ['successful', 'failed', 'none', 'empty', 'cancelled', 'pending'],
    }),
    mapping: (value: string): string => value,
    validator: isString,
  },
]

export default filters
