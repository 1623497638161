
import React from 'react'

export const CalendarTHead = () =>
  <thead role="presentation">
     <tr role="row">
        <th role="columnheader" scope="col" className="spectrum-Calendar-tableCell"><abbr className="spectrum-Calendar-dayOfWeek" title="Sunday">SUN</abbr></th>
        <th role="columnheader" scope="col" className="spectrum-Calendar-tableCell"><abbr className="spectrum-Calendar-dayOfWeek" title="Monday">MON</abbr></th>
        <th role="columnheader" scope="col" className="spectrum-Calendar-tableCell"><abbr className="spectrum-Calendar-dayOfWeek" title="Tuesday">TUE</abbr></th>
        <th role="columnheader" scope="col" className="spectrum-Calendar-tableCell"><abbr className="spectrum-Calendar-dayOfWeek" title="Wednesday">WED</abbr></th>
        <th role="columnheader" scope="col" className="spectrum-Calendar-tableCell"><abbr className="spectrum-Calendar-dayOfWeek" title="Thursday">THU</abbr></th>
        <th role="columnheader" scope="col" className="spectrum-Calendar-tableCell"><abbr className="spectrum-Calendar-dayOfWeek" title="Friday">FRI</abbr></th>
        <th role="columnheader" scope="col" className="spectrum-Calendar-tableCell"><abbr className="spectrum-Calendar-dayOfWeek" title="Saturday">SAT</abbr></th>
     </tr>
  </thead>

export default CalendarTHead
