import * as Cells from 'components/Table/Cells';

const columns = [
  {
    Header: 'id',
    accessor: 'id',
    cellRenderer: Cells.OrderItemLinkCell,
  },
  {
    Header: 'type',
    accessor: 'type'
  },
  {
    Header: 'title',
    accessor: 'title',
    cellRenderer: Cells.ExternalEditionLinkCell,
  },
  {
    Header: 'ISBN',
    accessor: 'ISBN13',
  },
  {
    Header: 'status',
    accessor: 'status',
  },
  {
    Header: 'quantity',
    accessor: 'quantity',
  },
  {
    Header: 'total',
    accessor: 'totalAmount',
    cellRenderer: Cells.CurrencyCell,
  }
]

export default columns
