import React, { useState } from 'react'
import Card from 'components/modules/Card'
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import {
  Picker,
  Item,
  View
} from '@adobe/react-spectrum'

import { format, getMonth } from 'date-fns'

import {
  chartProps,
  chartConfig,
  styleProps,
  hGridProps,
  numberAxisProps,
  intervalAxisProps,
  labelFormatter,
} from './charts'

interface MonthlyCredits {
  buckets: [any]
}

export const MonthlyCreditsByTypeChart:React.FC<{ data: MonthlyCredits }> = ({ data }) => {
  const { smallTickCount } = chartConfig

  const types = [
    {
      name: 'Install iOS App',
      type: 'install_ios_app'
    },
    {
      name: 'Redeam Voucher',
      type: 'redeem_voucher'
    },
    {
      name: 'Invite Success',
      type: 'invite_success'
    },
    {
      name: 'Profile Completion',
      type: 'profile_completion'
    },
    {
      name: 'Review',
      type: 'review'
    },
    {
      name: 'Cash-out',
      type: 'cash_out'
    },
    {
      name: 'Mojo',
      type: 'mojo'
    },
    {
      name: 'Purchase',
      type: 'purchase'
    },
    {
      name: 'Install Android App',
      type: 'install_android_app'
    },
    {
      name: 'Shipping',
      type: 'shipping'
    },
    {
      name: 'Rate',
      type: 'rate'
    },
    {
      name: 'Custom',
      type: 'custom'
    },
    {
      name: 'Signup Bonus',
      type: 'signup_bonus'
    },
    {
      name: 'Install Chrome Plugin',
      type: 'install_chrome_plugin'
    },
  ]
  const [type, setType] = useState<string | number>('custom')
  const myData = data.buckets.map(d => ({
    name: d.date,
    [type]: d[`${type}_credits`],
  }))

  const tickNumberFormatter = (tick: number, index: number): string => {
    if (index % smallTickCount !== 0) return ''
    if ((tick > -1000) && (tick < 1000)) return tick.toString()
    return tick / 1000 + 'K'
  }

  const tickFormatterXAxis = (tick: string, index: number) => {
    const date = new Date(tick)
    const m = getMonth(date)
    if (m % 2 !== 0) return ''
    return format(date, "MMM yy")
  }

  return (
    <Card heading='Monthly Credits By Type'>
      <View>
        <Picker
          marginTop={10}
          label="Select type"
          items={types}
          selectedKey={type}
          onSelectionChange={ (selection) => setType(selection) }
        >
          {(item) => <Item key={item.type}>{item.name}</Item>}
        </Picker>
      </View>
      <ResponsiveContainer height={ 300 } >
        <ComposedChart data={myData} { ...chartProps } >
          <style { ...styleProps } />
          <CartesianGrid { ...hGridProps } />
          <XAxis dataKey="name" tickFormatter={ tickFormatterXAxis } { ...intervalAxisProps }/>
          <YAxis yAxisId="monthly" orientation="left" tickFormatter={ tickNumberFormatter } { ...numberAxisProps }/>
          <Tooltip labelFormatter={ labelFormatter.months } />
          <Legend />
          <Bar yAxisId="monthly" dataKey={type} fill="#0000FF" />
        </ComposedChart>
      </ResponsiveContainer>
    </Card>
  )
}
