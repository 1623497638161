import React from 'react'
import { Button, Text } from '@adobe/react-spectrum'
import CheckmarkIcon from '@spectrum-icons/workflow/Checkmark'
import '@spectrum-css/button/dist/index-vars.css'
import { formatValue } from 'lib/format'

interface FilterChipProps {
  type: string,
  value: string,
  onPress: any
}

export default function FilterChip({ type, value, onPress }: FilterChipProps) {
  return (
    <Button variant="secondary" onPress={ onPress }>
      <Text>{type} { formatValue(value) }</Text>
      <CheckmarkIcon />
    </Button>
  )
}
