import { Breadcrumbs, Item } from 'components/modules/Breadcrumbs'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { useRoutes } from 'routes'
import { BOOKSTORE_CREDITS } from 'api/credits'
import { ProgressBar } from '@adobe/react-spectrum'
import { useQuery } from '@apollo/client'
import BookstoreCreditsTable from 'components/Tables/BookstoreCreditsTable'
import { Table, Item as TableItem } from 'components/modules/KeyValueTable'

const BookshopCreditsLayout = () => {
  const { bookshopCreditsPath } = useRoutes()
  return (
    <Layout>
      <Content variant='article' heading='Bookshop Credits'>
        <Breadcrumbs>
          <Item to={ '/' }>home</Item>
          <Item to={ bookshopCreditsPath() }> bookshop credits </Item>
        </Breadcrumbs>
        <BookshopCredits />
      </Content>
    </Layout>
  )
}

const BookshopCredits = () => {
  const { loading, error, data } = useQuery(BOOKSTORE_CREDITS, {
    variables: {
    }
  })

 if (loading) return <ProgressBar label='Loading…' isIndeterminate />
 if (error) return <div>Error fetching data</div>
 return (
    <div>
      <section>
        <Table>
          <TableItem label='Total Affiliate Credits'>{ data.bookstoreCredits.affiliateCredits }</TableItem>
          <TableItem label='Total Supporter Credits'>{ data.bookstoreCredits.supporterCredits }</TableItem>
          <TableItem label='Total Credits'>{ data.bookstoreCredits.totalCredits }</TableItem>
        </Table>
      </section>
      <BookstoreCreditsTable bookstoreCredits={ data.bookstoreCredits.bookstores } />
    </div>
  )
}

export default BookshopCreditsLayout
