import React, { ReactNode } from 'react'
import LinkOut from '@spectrum-icons/workflow/LinkOut'
import { generatePath } from 'react-router'
import classNames from 'classnames'
import config from 'config'

interface ExternalLinkProps {
  to: string
  params: { id?: any; username?: string }
  className?: string
  children?: ReactNode
  rest?: any
}

const ExternalLink = (props: ExternalLinkProps) => {
  const { to, params, className, children, ...rest } = props
  const linkProps = { target: '_blank', rel: 'noreferrer', ...rest }

  const propClassName = classNames('spectrum-Link', className)
  const href = generatePath(to, params)

  return (
    <a href={href} className={propClassName} {...linkProps}>
      {children}
      <LinkOut maxHeight={10} />
    </a>
  )
}

export const ExternalEditionLink = ({ id, ...rest }: { id: number, children: ReactNode }) => {
  if (!id) return null
  if (!config.webUrl) return rest.children
  return (
    <ExternalLink to={`${config.webUrl}/books/:id`} params={{ id }} {...rest} />
  )
}

export const ExternalProfileLink = ({ username, ...rest }: { username: string, children: ReactNode }) => {
  if (!username) return null
  if (!config.webUrl) return rest.children
  return (
    <ExternalLink
      to={`${config.webUrl}/profiles/:username`}
      params={{ username }}
      {...rest}
    />
  )
}

export const ExternalArticleLink = ({
  id,
  ...rest
}: {
  id: string
  children: ReactNode
}) => {
  if (!id) return null
  if (!config.webUrl) return rest.children
  return (
    <ExternalLink
      to={`${config.webUrl}/articles/:id`}
      params={{ id }}
      {...rest}
    />
  )
}
