import { mapDate, isInteger, isString, isDate, FilterType } from 'lib/table'

import {
  pickerFactory,
  textFieldFactory,
} from 'components/Table/FilterFactories'

const filters: FilterType[] = [
  {
    display: 'Supplier Id',
    name: 'supplier_id',
    filter: textFieldFactory({
      label: 'Supplier Id',
      placeholder: 'Enter Supplier Id...',
    }),
    mapping: (value: string): string => value,
    validator: isInteger,
  },
  {
    display: 'FTP Id',
    name: 'dropbox_id',
    filter: textFieldFactory({
      label: 'FTP Id',
      placeholder: 'Enter FTP Id...',
      type: 'number',
    }),
    mapping: parseInt,
    validator: isInteger,
  },
  {
    display: 'Processed',
    name: 'processed',
    filter: pickerFactory({
      label: 'processed',
      items: ['Unprocessed', 'Success', 'Failed'],
    }),
    mapping: (value: string): string => value,
    validator: isString,
  },
]

export default filters
