import React, {
  useRef,
  useContext,
  createContext,
} from 'react'

import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

interface IRoutesContext {
  routes: any,
  addRoute: (routeName: string, routePath: string) => void
}

const defaultValue: IRoutesContext = {
  routes: {},
  addRoute: (routeName: string, routePath: string) => undefined
}


const capitalize = (s: string) => {
  const [ first, ...rest ] = s.split('')
  return first.toUpperCase() + rest.join('')
}

const pathHelper = (routePath: string) => (routeParams: any) => generatePath(routePath, routeParams)

const linkHelper = (routePath: string) => (linkProps: any) => {
  let { children, className, ...routeParams } = linkProps
  className = classNames('spectrum-Link', className)
  try {
    const path = generatePath(routePath, routeParams)
    return <Link to={ path } className={ className } children={ children } />
  } catch {
    return children || <></>
  }
}

export const RoutesContext = createContext<IRoutesContext>(defaultValue)

export const RoutesProvider = ({ children, ...props }: any) => {
  const routes = useRef<{ [key: string]: (routeParams: any) => string }>({})

  const addRoute = (routeName: string, routePath: string) => {
    const pathName = `${routeName}Path`
    const linkName = capitalize(`${routeName}Link`)

    routes.current[pathName] = pathHelper(routePath)
    routes.current[linkName] = linkHelper(routePath)
  }

  const routesContextValue:IRoutesContext = { routes, addRoute }

  return <RoutesContext.Provider value={ routesContextValue } children={ children} />
}

export const useRoutesContext = () => {
  const ctx = useContext(RoutesContext)
  if (!ctx) throw Error('The `useRoutes` hook must be called from a descendent of the `RoutesProvider`.')
  return ctx
}
