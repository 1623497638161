import { Layout, Content } from 'components/layouts/LoggedIn'
import { Breadcrumbs, Item } from 'components/modules/Breadcrumbs'
import CreditsTable from 'components/Tables/CreditsTable'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { PROFILE_QUERY } from 'api/profiles'
import { useRoutes } from 'routes'

const ProfileCreditsLayout = () => {
  const { username } = useParams<{ username: string }>();
  const { profilePath, profilesPath, profileCreditsPath } = useRoutes()
  return (
    <Layout>
      <Content variant='article' heading={ username }>
        <Breadcrumbs>
          <Item to={ profilesPath() }> Profiles </Item>
          <Item to={ profilePath({ username }) }> { username } </Item>
          <Item to={ profileCreditsPath({ username }) }> credits </Item>
        </Breadcrumbs>
        <ProfileCredits />
      </Content>
    </Layout>
  )
}

const ProfileCredits = () => {
  const { username } = useParams<{ username: string }>();
  const { loading, error } = useQuery(PROFILE_QUERY, {
    variables: {
      username
    }
  })

  if (loading) return <p>{ `Loading profile "${username}"...` }</p>;
  if (error) return <p>{ `Error loading profile "${username}"` }</p>;

  return (
    <div>
      <p />
      <CreditsTable username={ username } />
    </div>
  )
}

export default ProfileCreditsLayout
