import React from 'react'
import Card from 'components/modules/Card'
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Line,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

import { format, getMonth } from 'date-fns'

import {
  chartProps,
  chartConfig,
  styleProps,
  hGridProps,
  numberAxisProps,
  intervalAxisProps,
  labelFormatter,
} from './charts'

interface MonthlyCredits {
  buckets: [
    {
      date: string,
      credits_total: number
      credits_in: number
      credits_out: number
    }
  ]
}

export const MonthlyCreditsChart:React.FC<{ data: MonthlyCredits }> = ({ data }) => {
  const { smallTickCount } = chartConfig

  const chartData = data.buckets.map(d => ({
    name: d.date,
    total: d.credits_total,
    in: d.credits_in,
    out: d.credits_out
  }))

  const tickNumberFormatter = (tick: number, index: number): string => {
    if (index % smallTickCount !== 0) return ''
    if ((tick > -1000) && (tick < 1000)) return tick.toString()
    return tick / 1000 + 'K'
  }

  const tickFormatterXAxis = (tick: string, index: number) => {
    const date = new Date(tick)
    const m = getMonth(date)
    if (m % 2 !== 0) return ''
    return format(date, "MMM yy")
  }

  return (
    <Card heading='Monthly Credits'>
      <ResponsiveContainer height={ 300 } >
        <ComposedChart data={ chartData } { ...chartProps } >
          <style { ...styleProps } />
          <CartesianGrid { ...hGridProps } />
          <XAxis dataKey="name" tickFormatter={ tickFormatterXAxis } { ...intervalAxisProps } />
          <YAxis yAxisId="monthly" orientation="left" tickFormatter={ tickNumberFormatter } { ...numberAxisProps } />
          <Tooltip labelFormatter={ labelFormatter.months } />
          <Legend />
          <Line yAxisId="monthly" dataKey="total" fill="#8884d8" />
          <Bar yAxisId="monthly" dataKey="in" fill="#0000FF" />
          <Bar yAxisId="monthly" dataKey="out" fill="#FF0000" />
        </ComposedChart>
      </ResponsiveContainer>
    </Card>
  )
}
