
import React from 'react'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { ReactComponent as Illustration } from 'images/404.svg'
import '@spectrum-css/illustratedmessage/dist/index-vars.css'

export const Error = ({ error }: { error: Error }) => {
  console.error(JSON.stringify(error))
  return (
    <Layout>
      <Content variant='status'>
        <div className='spectrum-IllustratedMessage'>
          <Illustration className='spectrum-IllustratedMessage-illustration' />
          <h2 className='spectrum-Heading spectrum-Heading--sizeL spectrum-Heading--light spectrum-IllustratedMessage-heading'>
            { 'Ooops! Something went wrong.' }
          </h2>
          <p className='spectrum-Body spectrum-Body--sizeS spectrum-IllustratedMessage-description'>
            { error.message }
          </p>
        </div>
      </Content>
    </Layout>
  )
}

export default Error
