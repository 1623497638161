import * as Cells from 'components/Table/Cells'

const columns = [
  {
    Header: 'id',
    accessor: 'id',
    disableSortBy: false,
    cellRenderer: Cells.OrderItemLinkCell,
  },
  {
    Header: 'orderId',
    accessor: 'orderId',
    disableSortBy: false,
    cellRenderer: Cells.TransactionLinkCell,
  },
  {
    Header: 'username',
    accessor: 'username',
    disableSortBy: false,
    cellRenderer: Cells.UserLinkCell,
  },
  {
    Header: 'status',
    accessor: 'status',
    disableSortBy: false,
  },
  {
    Header: 'title',
    accessor: 'title',
    disableSortBy: true,
  },
  {
    Header: 'authors',
    accessor: 'authors',
    disableSortBy: true,
  },
  {
    Header: 'ISBN13',
    accessor: 'ISBN13',
    disableSortBy: true,
  },
  {
    Header: 'Sender',
    accessor: 'sender',
    disableSortBy: false,
  },
  {
    Header: 'Total',
    accessor: 'totalAmount',
    disableSortBy: true,
    cellRenderer: Cells.CurrencyCell,
  },
  {
    Header: 'Date',
    accessor: 'createdAt',
    disableSortBy: false,
    cellRenderer: Cells.DateCell,
  },
]

export default columns
