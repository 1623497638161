import config from 'config'

const headers = (accessToken: string) => {
  return {
    'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ accessToken }`
  }
}

export const fetchUserData = async (username: String, accessToken: string): Promise<any> => {
  const url = `${config.apiUrl}/admin-api/user-data/${username}`
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: headers(accessToken)
  })
  const data  = await(response.json())
  return data
}
