import React from 'react'
import classNames from 'classnames'
import styles from './KeyValueTable.module.css'

interface IProps {
  children: any,
  className?: string,
  label?: string,
}

export const Item = ({ label, className, children }: IProps) => {
  className = classNames('spectrum-Table-headCell', styles.theadcell, className)
  return (
    <tr>
      <th className={ className }>
        { label }
      </th>
      <td>
        { children }
      </td>
    </tr>
  )
}

export const Table = ({ className, children }: IProps) => {
  className = classNames(styles.table, className)
  return (
    <table className={ className }>
      <tbody>
        { children }
      </tbody>
    </table>
  )
}
