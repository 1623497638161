import * as Cells from 'components/Table/Cells'

const columns = [
  {
    Header: 'id',
    accessor: 'id',
    disableSortBy: true,
    cellRenderer: Cells.EditionLinkCell
  },
  {
    Header: 'ISBN 13',
    accessor: 'isbn_13',
    disableSortBy: true,
  },
  {
    Header: 'GTIN 13',
    accessor: 'gtin_13',
    disableSortBy: true,
  },
  {
    Header: 'ISBN 10',
    accessor: 'isbn_10',
    disableSortBy: true,
  },
  {
    Header: 'Title',
    accessor: 'title',
    disableSortBy: true,
  },
  {
    Header: 'Form',
    accessor: 'product_form',
    disableSortBy: true,
  },
  {
    Header: 'Image',
    accessor: 'image_id',
    disableSortBy: true,
    cellRenderer: Cells.ImageLinkCell
  },
  {
    Header: 'Epub',
    accessor: 'epub_id',
    disableSortBy: true,
    cellRenderer: Cells.EpubLinkCell
  },
  {
    Header: 'Product',
    accessor: 'supplier_product_id',
    disableSortBy: true,
    cellRenderer: Cells.ProductLinkCell
  },
  {
    Header: 'Created',
    accessor: 'created_at',
    disableSortBy: true,
    cellRenderer: Cells.DateCell,
  },
]

export default columns
