import React, { useState } from 'react'

import '@spectrum-css/button/dist/index-vars.css'
import FilterChip from '../FilterChip'


import FilterModal from 'components/Table/FilterModal'
import styles from './FilterGroup.module.css'

import {
  Button,
  ButtonGroup,
  DialogTrigger,
  Text,
} from '@adobe/react-spectrum';

import FilterIcon from '@spectrum-icons/workflow/Filter'
import { FilterType } from 'lib/table'

type EditFilterType = {
  key: string,
  value: any
}

interface FilterGroupProps {
  filterTypes: any[]
  setFilters: any,
  filters: FilterType[]
}

export default function FilterGroup({ filterTypes, filters, setFilters }: FilterGroupProps) {

  const [filter, setFilter] = useState<EditFilterType | null>()
  const resetFilter = () => setFilter(null)
  const modalProps = { filter, filters, setFilters, filterTypes }

  return (
    <DialogTrigger>
      <ButtonGroup UNSAFE_className={ styles.root }>
        <Button onPress={ resetFilter } variant="cta">
          <FilterIcon />
          <Text>Add filter</Text>
        </Button>

        {
          Object.entries(filters).map(([key, value]) => {
            const selectFilter = () => setFilter({key, value})
            return <FilterChip key={key} type={key} value={value} onPress={ selectFilter } />
          })
        }
      </ButtonGroup>

      {(close) => <FilterModal close={close} {...modalProps} />}

    </DialogTrigger>
  )
}
