
import React from 'react'
import { Checkbox as SpectrumCheckbox } from '@react-spectrum/checkbox'

interface ICheckbox {
  isSelected: boolean,
  value?: string,
  name?: string,
  isDisabled?: boolean,
  isReadOnly?: boolean,
  isRequired?: boolean,
}

export const Checkbox: React.FC<ICheckbox> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <SpectrumCheckbox {...otherProps}>
      { children }
    </SpectrumCheckbox>
  )
}
