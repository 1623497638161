import { useParams } from 'react-router-dom'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { fetchEdition } from 'api/details/catalog'
import { ActionButton, ProgressBar } from '@adobe/react-spectrum'
import { useAuth } from 'context/authContext'
import { useToast } from 'components/modules/Toast'
import { useEffect, useState } from 'react'
import { EditionProductsTable } from 'components/Tables/EditionsProductsTable'
import { EditionImagesTable } from 'components/Tables/EditionsImagesTable'
import { EditionEpubsTable } from 'components/Tables/EditionsEpubsTable'
import { Table, Item } from 'components/modules/KeyValueTable'
import { useRoutes } from 'routes'
import { blockEdition, unBlockEdition, reprocessEdition } from 'api/catalog'
import { ExternalEditionLink } from 'routes/helper/externalLinks'
import _ from 'lodash'

export function Edition() {
  const { id } = useParams<{ id: string }>()
  const { accessToken } = useAuth()
  const { addToast } = useToast()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<any | undefined>(undefined)
  const [error, setError] = useState<string | undefined>()
  const { CatalogProductLink, CatalogImageLink, CatalogEpubLink } = useRoutes()

  const loadEditionData = async () => {
    try {
      const result: any = await fetchEdition(id, accessToken)
      setData(result)
      setLoading(false)
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message)
      } else {
        setError('Error loading data')
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      await loadEditionData()
    })()
  }, [accessToken, id])

  const renderProducts = () => {
    if (!data.products?.length) {
      return 'Edition has no products'
    }
    return <EditionProductsTable data={data.products}></EditionProductsTable>
  }

  const renderImages = () => {
    if (!data.images?.length) {
      return 'Edition has no images'
    }
    return <EditionImagesTable data={data.images}></EditionImagesTable>
  }

  const renderEpubs = () => {
    if (!data.epubs?.length) {
      return 'Edition has no epubs'
    }
    return <EditionEpubsTable data={data.epubs}></EditionEpubsTable>
  }

  const onSuccessBlock = async (message: string) => {
    setLoading(true)

    addToast({
      variant: 'positive',
      message,
      timeout: 6000,
    })

    try {
      const result: any = await fetchEdition(id, accessToken)
      setData(result)
      setLoading(false)
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message)
      } else {
        setError('Error loading data')
      }
      setLoading(false)
    }
  }

  const onFailureBlock = (
    message: string = 'Could not change status edition'
  ) => {
    addToast({
      variant: 'negative',
      message,
      timeout: 6000,
    })
  }

  const onBlock = async () => {
    const isBlocked = data.edition.blocked

    if (!isBlocked) {
      try {
        const response = await blockEdition(id, accessToken)

        const { message, success } = response

        if (success) {
          onSuccessBlock(message)
        } else {
          onFailureBlock(message)
        }
      } catch (err) {
        onFailureBlock()
      }
    } else {
      try {
        const response = await unBlockEdition(id, accessToken)

        const { message, success } = response

        if (success) {
          onSuccessBlock(message)
        } else {
          onFailureBlock(message)
        }
      } catch (err) {
        onFailureBlock()
      }
    }
  }

  const reprocess = async () => {
    const response = await reprocessEdition(Number(id), accessToken)
    if (response.success) {
      addToast({
        variant: 'positive',
        message: response.message || "Reprocessing complete",
        timeout: 6000,
      })
    } else {
      addToast({
        variant: 'negative',
        message: response.message || "Reprocessing failed",
        timeout: 6000,
      })
    }
    await loadEditionData()
  }

  const renderOptions = () => {
    return (
      <div>
        <div>
          <ActionButton onPress={onBlock}>
            {data.edition.blocked ? 'Unblock Edition' : 'Block Edition'}
          </ActionButton>
        </div>
        <br />
        <div>
          <ActionButton onPress={reprocess}>
            Reprocess Edition
          </ActionButton>
        </div>
      </div>
    )
  }

  const render = () => {
    if (loading) return <ProgressBar label="Loading…" isIndeterminate />
    if (error) return <p>{error}</p>
    if (!data?.edition) return <p>No record found</p>
    return (
      <div>
        <section>
          <Table>
            <Item label="Edition Id">{data.edition.edition_id} </Item>
            <Item label="ISBN 13">{data.edition.isbn_13}</Item>
            <Item label="ISBN 10">{data.edition.isbn_10}</Item>
            <Item label="GTIN 13">{data.edition.gtin_13}</Item>
            <Item label="Title">{data.edition.title}</Item>
            <Item label="Description">{data.edition.description}</Item>
            <Item label="Authors">{data.edition.authors?.join(", ")}</Item>
            <Item label="Blocked">{data.edition.blocked ? 'yes' : 'no'}</Item>
            <Item label="Product">
              <CatalogProductLink id={data.edition.product_id}>
                {data.edition.product_id}
              </CatalogProductLink>
            </Item>
            <Item label="Image">
              <CatalogImageLink id={data.edition.image_id}>
                {data.edition.image_id}
              </CatalogImageLink>
            </Item>
            <Item label="Epub">
              <CatalogEpubLink id={data.edition.epub_id}>
                {data.edition.epub_id}
              </CatalogEpubLink>
            </Item>
            <Item label="Availability">{data.availability.code}</Item>
            <Item label="Availability Note">{data.availability.reason}</Item>
            <Item label="URL">
              {/* @ts-ignore */}
              <ExternalEditionLink id={data.edition.edition_id}>Open on website</ExternalEditionLink>
            </Item>
          </Table>
        </section>
        <section>
          <h3>Products</h3>
          {renderProducts()}
        </section>
        <section>
          <h3>Images</h3>
          {renderImages()}
        </section>
        <section>
          <h3>Epubs</h3>
          {renderEpubs()}
        </section>
        <section>
          <h3>Options</h3>
          {renderOptions()}
        </section>
      </div>
    )
  }

  return (
    <Layout>
      <Content variant="article" heading="Edition">
        {render()}
      </Content>
    </Layout>
  )
}
