import { Layout, Content } from 'components/layouts/LoggedIn'
import RelatedIdentifiersTable from 'components/Tables/RelatedIdentifiersTable'

export default function RelatedIdentifiers() {
  return (
    <Layout>
      <Content variant="article" heading="Related Identifiers">
        <RelatedIdentifiersTable />
      </Content>
    </Layout>
  )
}
