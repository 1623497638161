import config from 'config'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { useParams } from 'react-router-dom'
import { Breadcrumbs, Item } from 'components/modules/Breadcrumbs'
import { useRoutes } from 'routes'
import { withProfile } from './withProfile'
import { Table, Item as Itm } from 'components/modules/KeyValueTable'
import { useAuth } from 'context/authContext'
import { useToast } from 'components/modules/Toast'

import { ActionButton, AlertDialog, DialogTrigger } from '@adobe/react-spectrum'

interface Profile {
  username: string
  displayName: string
  email: string
  role: string
}

interface ProfileDeleteAccountProps {
  profile: Profile
}

const ProfilesDeleteAccountLayout = (props: ProfileDeleteAccountProps): JSX.Element => {
    const { username } = useParams<{ username: string }>();
    const { profilePath, profilesPath, ProfileDeleteAccountPath } = useRoutes()

    const {
      profile
  } = props

    return (
        <Layout>
            <Content variant='article' heading='Delete User'>
                <Breadcrumbs>
                    <Item to={ profilesPath() }> Profiles </Item>
                    <Item to={ profilePath({ username }) }> { username } </Item>
                    <Item to={ ProfileDeleteAccountPath({ username }) }> Delete Account </Item>
                </Breadcrumbs>
                <ProfileDeleteAccount profile={profile} />
            </Content>
        </Layout>
    )
}

const ProfileDeleteAccount = (props: ProfileDeleteAccountProps) => {

    const { accessToken } = useAuth();
    const { addToast } = useToast()

    const {
        profile
    } = props

    const { username, displayName, email, role } = profile

    const deleteUser = async () => {
        try {
            const rawResponse = await fetch(`${config.deleteUserUrl}/${username}`, {
              method: 'POST',
              mode: 'cors',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              },
              body: JSON.stringify({username, email})
            })
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const content = await rawResponse.json()
            if (content.error) {
              addToast({ variant: 'negative', message: "Error deleting user", timeout: 3000 })
            } else {
              addToast({ variant: 'positive', message: "User deleted", timeout: 3000 })
            }
          } catch(error) {
            addToast({ variant: 'negative', message: "Error deleting user", timeout: 3000 })
          }
    }

    return (
        <div>
          <p>Would you like to delete the account of <strong>{username}</strong> ?</p>
          <DialogTrigger>
                 <ActionButton>
                     Delete User
                 </ActionButton>
                <AlertDialog
                    title="Warning, you are about to delete a user!"
                    variant="destructive"
                    primaryActionLabel="Confirm"
                    cancelLabel="Cancel"
                    autoFocusButton="cancel"
                    onPrimaryAction={()=> deleteUser()}
                >
                This action cannot be reversed. Would you like to delete this user?
                <Table>
                    <Itm label='Username'>{ username }</Itm>
                    <Itm label='Display Name'>{ displayName }</Itm>
                    <Itm label='Email'>{ email }</Itm>
                    <Itm label='Role'>{ role }</Itm>
                </Table>
                </AlertDialog>
        </DialogTrigger>
        </div>
    )
}

export default withProfile(ProfilesDeleteAccountLayout)
