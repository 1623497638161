import React from 'react'
import { Route } from 'react-router-dom'
import { useRoutesContext } from './routesContext'

export const useRoutes = () => {
  const { routes } = useRoutesContext()
  return routes.current
}

interface RouterProps {
  path: string
  component: React.ComponentType
}

export const useRoute = (routeName: string, routeProps: RouterProps) => {
  const { addRoute } = useRoutesContext()
  addRoute(routeName, routeProps.path)
  // @ts-ignore
  return <Route key={routeName} exact {...routeProps} />
}
