import { Layout, Content } from 'components/layouts/LoggedIn'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { ProgressBar } from '@adobe/react-spectrum'
import { ORDER_ITEMS_LIST_QUERY } from 'api/transactions'
import { Breadcrumbs, Item } from 'components/modules/Breadcrumbs'
import { useRoutes } from 'routes'
import OrderItemDataTable from 'components/containers/OrderItemDataTable'

export const useOrderItemData = ({ id }: { id: string }) => {
  const { loading, error, data } = useQuery(ORDER_ITEMS_LIST_QUERY, {
    variables: {
      id: parseInt(id)
    }
  })

  return { loading, error, data: data?.orderItems?.orderItems[0] }
}

export default function OrderItems() {
  const { id } = useParams<{ id: string }>()

  const { orderItemPath, orderItemsPath } = useRoutes()
  const { loading, error, data: orderItem } = useOrderItemData({ id })

  const renderOrderItemData = () => {
    if (loading) return <ProgressBar label='Loading…' isIndeterminate />
    if (error) return <div>Error fetching data</div>
    if (!orderItem) return <div>Order Item not found.</div>

    return <OrderItemDataTable data={ orderItem } />
  }

  return (
    <Layout>
      <Content variant='article' heading='Order Item'>

        <Breadcrumbs>
          <Item to={ orderItemsPath() }> Order Items </Item>
          <Item to={ orderItemPath({ id }) }> { id } </Item>
        </Breadcrumbs>

        { renderOrderItemData() }
      </Content>
    </Layout>
  )
}
