import React from 'react'
import { Link } from 'react-router-dom'
import { Heading } from '@adobe/react-spectrum'
import { ReactComponent as Logo } from 'images/mojoreads-icon.svg'
import '@spectrum-css/typography/dist/index-vars.css'
import styles from './SideBarHeader.module.css'

interface SideBarHeaderProps {
  to?: string
}

interface ConditionalLinkProps {
  to?: string
  className?: string
  children: React.ReactNode
}

const ConditionalLink = ({ to, className, children }: ConditionalLinkProps) =>
  to ? (
    <Link to={to} className={className}>
      {/* @ts-ignore */}
      {children}
    </Link>
  ) : (
    <span className={className}>{children}</span>
  )

export default function SidebarHeader({ to }: SideBarHeaderProps) {
  return (
    <ConditionalLink to={to} className={styles.root}>
      <Logo className={styles.logo} />
      <Heading
        level={2}
        UNSAFE_className="spectrum-Heading spectrum-Heading--sizeM"
      >
        Admin
      </Heading>
    </ConditionalLink>
  )
}
