import { useReducer } from 'react'

interface LoginForm {
  username: string,
  password: string
}

interface InputEvent {
  name: string,
  value: string
}

const initialState = {
  username: '',
  password: ''
}

const formReducer = (state: LoginForm, event: InputEvent) => {
 return {
   ...state,
   [event.name]: event.value
 }
}

const useLoginForm = () => {
  const [formData, setFormData] = useReducer(formReducer, initialState)
  return {formData, setFormData}
}

export {
  useLoginForm
}
