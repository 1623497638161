import { gql } from '@apollo/client';

export const INVITES_QUERY = gql`
  query invites($skip: Int, $limit: Int, $filter: JSON, $sort: String, $sortOrder: String) {
    invitesForAdmin(skip: $skip, limit: $limit, filter: $filter, sort: $sort, sortOrder: $sortOrder){
      count
      invites {
        id
        inviter
        invitee
        status
        email
        createdAt
        updatedAt
      }
    }
  }
`
