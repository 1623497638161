import { useParams } from 'react-router-dom'
import { ActionButton, Flex } from '@adobe/react-spectrum'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { fetchProduct } from 'api/details/catalog'
import { ProgressBar } from '@adobe/react-spectrum'
import { useAuth } from 'context/authContext'
import { useEffect, useState } from 'react'
import { useToast } from 'components/modules/Toast'
import { Table, Item } from 'components/modules/KeyValueTable'
import ReactJson from 'react-json-view'
import { useRoutes } from 'routes'
import { formatDate } from 'lib/format'
import { ProductRelatedProductsTable } from 'components/Tables/ProductRelatedProducts'

export function Product() {
  const { id } = useParams<{ id: string }>()
  const { accessToken } = useAuth()
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any | undefined>(undefined);
  const [error, setError] = useState<string | undefined>()
  const [showOnix, setShowOnix] = useState(false)
  const { CatalogEditionLink, CatalogSupplierLink, CatalogBackupJobLink } = useRoutes()
  const { addToast } = useToast()

  useEffect(() => {
    (async () => {
      try {
        const result: any = await fetchProduct(id, accessToken);
        setData(result)
        setLoading(false)
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message)
        } else {
          setError("Error loading data");
        }
        setLoading(false)
      }
    })()
  }, [accessToken, id])

  const renderProductData = () => {
    if (!data?.product) return <p>No product found</p>
    if (!data?.product.product_data_id) return <p>No extracted product data record found</p>
    const product = data.product
    return <>
      <Table>
        <Item label='isbn 13'>{ product.isbn_13 }</Item>
        <Item label='gtin 13'>{ product.gtin_13 }</Item>
        <Item label='isbn 10'>{ product.isbn_10 }</Item>
        <Item label='notification type'>{ product.notification_type }</Item>
        <Item label='product form'>{ product.product_form }</Item>
        <Item label='title'>{ product.title }</Item>
        <Item label='authors'>{ product.authors?.join(", ") }</Item>
        <Item label='translators'>{ product.translators?.join(", ") }</Item>
        <Item label='narrators'>{ product.narrators?.join(", ") }</Item>
        <Item label='publication date'>{ formatDate(product.publication_date) }</Item>
        <Item label='description'>{ product.description }</Item>
        <Item label='author_bio'>{ product.author_bio }</Item>
        <Item label='pages'>{ product.pages }</Item>
        <Item label='cover_image'>{ product.cover_image }</Item>
        <Item label='publishers'>{ product.publishers }</Item>
        <Item label='country'>{ product.country }</Item>
        <Item label='amount'>{ product.amount }</Item>
        <Item label='currency code'>{ product.currency_code }</Item>
        <Item label='discount percent'>{ product.discount_percent }</Item>
        <Item label='tax_rate'>{ product.tax_rate }</Item>
        <Item label='supplier name'>{ product.supplier_name }</Item>
        <Item label='availability code'>{ product.availability_code }</Item>
        <Item label='product availability'>{ product.product_availability }</Item>
        <Item label='ship date'>{ formatDate(product.expected_ship_date) }</Item>
        <Item label='tax rate code'>{ product.tax_rate_code }</Item>
        <Item label='stock'>{ product.stock }</Item>
      </Table>
      <h4>Categories</h4>
      <Table>
        <Item label='warengruppen systematik'>{ product.category_warengruppen_systematik?.join(", ") }</Item>
        <Item label='bisac'>{ product.category_bisac?.join(", ") }</Item>
        <Item label='thema subject'>{ product.category_thema_subject?.join(", ") }</Item>
      </Table>
      <h4>Related Products</h4>
      <ProductRelatedProductsTable relatedProducts={ product.related_products || [] }></ProductRelatedProductsTable>
    </>
  }

  const renderOnix = () => {
    if (showOnix) {
      return (
        // @ts-expect-error
        <ReactJson
          src={data.product.data}
          name={null}
          enableClipboard={false}
          displayDataTypes={false}
          displayObjectSize={false}
        />
      )
    }
  }
  const filename = () => {
    const date = new Date().toJSON().replace(/[T:\.]/g, "-").replace('Z', '')
    const edition = data.product.edition_id || "edition"
    const product = data.product.product_id
    const supplier = data.product.supplier_id || "supplier"
    const onixVersion = data.product.data_type
    return `${edition}-${product}-${supplier}-${onixVersion}-${date}.json`
  }

  const downloadOnix = async () => {
    addToast({
      variant: 'info',
      message: 'Starting to download',
    })

    const file = new Blob([JSON.stringify(data.product.data, null, 2)], { type: 'application/json' })
    const element = document.createElement('a')
    element.href = URL.createObjectURL(file)
    element.download = filename()
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()

    addToast({
      variant: 'positive',
      message: 'Download is ready',
    })
  }

  const renderOnixButton = () => {
    if (showOnix) {
      return <ActionButton onPress={() => setShowOnix(false)}>Hide Onix</ActionButton>
    } else {
      return <ActionButton onPress={() => setShowOnix(true)}>Show Onix</ActionButton>
    }
  }

  const render = () => {
    if (loading) return <ProgressBar label='Loading…' isIndeterminate />
    if (error) return <p>{ error }</p>
    if (!data?.product) return <p>No record found</p>
    const product = data.product
    return <div>
    <section>
      <Table>
        <Item label='product'>{ product.product_id }</Item>
        <Item label='edition'><CatalogEditionLink id={ product.edition_id }>{ product.edition_id }</CatalogEditionLink></Item>
        <Item label='supplier'><CatalogSupplierLink id={ product.supplier_id }>{ product.supplier_id }</CatalogSupplierLink></Item>
        <Item label='import'><CatalogBackupJobLink id={ product.backup_job_id }>{ product.backup_job_id }</CatalogBackupJobLink></Item>
        <Item label='data_type'>{ product.data_type }</Item>
        <Item label='is_active'>{ product.is_active ? "yes" : "no" }</Item>
        <Item label='created_at'>{ formatDate(product.created_at) }</Item>
      </Table>
    </section>
    <section>
      <h4>Libri Availability</h4>
      <Table>
        <Item label='type (ms01)'>{ product.supplier_data?.ms01 || product.supplier_data?.lieferantintern?.ms?.ms01 || "not defined"}</Item>
        <Item label='code (ms02)'>{ product.supplier_data?.ms02 || product.supplier_data?.lieferantintern?.ms?.ms02 || "not defined"}</Item>
        <Item label='text (ms03)'>{ product.supplier_data?.ms03 || product.supplier_data?.lieferantintern?.ms?.ms03 || "not defined"}</Item>
        <Item label='date (ms04)'>{ product.supplier_data?.ms04 || product.supplier_data?.lieferantintern?.ms?.ms04 || "not defined"}</Item>
      </Table>
    </section>
    <section>
      <h4>Product Data</h4>
      { renderProductData() }
    </section>
    <section>
      <h4>Onix Data</h4>
      <Flex direction="row" gap="size-150">
        <ActionButton
          onPress={ downloadOnix } >
          Downoad Onix
        </ActionButton>

        { renderOnixButton() }
      </Flex>

      { renderOnix() }
    </section>
    </div>

  }

  return (
    <Layout>
      <Content variant='article' heading='Product'>
        { render() }
      </Content>
    </Layout>
  )
}
