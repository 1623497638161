
import React from 'react'
import { Picker, Item } from '@adobe/react-spectrum'

interface IFactoryProps {
  label: string,
  items: any[]
}

interface IFilterProps {
  filterValue: any,
  setFilter: any,
}

const mapFactoryProps = ({ label, items }: IFactoryProps) => ({
  label,
  items: items.map(el => {
    return { key: el?.key || el, value: el?.value || el}
  }),
})

const mapFilterProps = (props: IFilterProps) => ({
  selectedKey: props.filterValue,
  onSelectionChange: props.setFilter
})

export const pickerFactory = (factoryProps: IFactoryProps) =>
  (filterProps: IFilterProps) => {
    const factoryProps2 = mapFactoryProps(factoryProps)
    const filterProps2 = mapFilterProps(filterProps)
    return <Picker
      {...factoryProps2}
      {...filterProps2} >
        {({ key, value }) => <Item key={key}>{value}</Item>}
      </Picker>
  }
