import React from 'react'
import { Switch } from 'react-router-dom'
import { Route } from 'react-router-dom'

import Order from 'components/pages/Order'
import Orders from 'components/pages/Orders'
import Events from 'components/pages/Events'
import CashOuts from 'components/pages/CashOuts'
import CashOut from 'components/pages/CashOut'
import Dashboard from 'components/pages/Dashboard'
import OrderItems from 'components/pages/OrderItems'
import OrderItem from 'components/pages/OrderItem'
import Article from 'components/pages/Moderation/Article'
import Articles from 'components/pages/Moderation/Articles'
import CreateOrder from 'components/pages/CreateOrder'
import Invites from 'components/pages/Invites'
import Credits from 'components/pages/Credits'
import BookshopCredits from 'components/pages/BookshopCredits'
import BlockUsers from 'components/pages/BlockedUsers/BlockUsers'
import ReportedPosts from 'components/pages/Moderation/ReportedPosts'
import ReportedPost from 'components/pages/Moderation/ReportedPost'
import ReportedArticles from 'components/pages/Moderation/ReportedArticles'

import RelatedIdentifiers from 'components/pages/RelatedIdentifiers/RelatedIdentifiers'

import { Edition } from 'components/pages/Catalog/Edition'
import { Products } from 'components/pages/Catalog/Products'
import { Editions } from 'components/pages/Catalog/Editions'
import { Product } from 'components/pages/Catalog/Product'
import { BackupJobs } from 'components/pages/Catalog/BackupJobs'
import { BackupJob } from 'components/pages/Catalog/BackupJob'
import { Images } from 'components/pages/Images/Images'
import { Image } from 'components/pages/Catalog/Image'
import { Epubs } from 'components/pages/Catalog/Epubs'
import { Epub } from 'components/pages/Catalog/Epub'
import { Suppliers } from 'components/pages/Catalog/Suppliers'
import { Supplier } from 'components/pages/Catalog/Supplier'
import { Ftp } from 'components/pages/Catalog/Ftp'

import {
  ProfilesAddCredits,
  ProfilesCashouts,
  ProfilesCredits,
  ProfilesDeleteAccountLayout,
  ProfilesDetail,
  ProfilesEvents,
  ProfilesGetAddress,
  ProfilesChangeEmail,
  ProfilesIndex,
  ProfilesInvites,
  ProfilesOrderItems,
  ProfilesOrders,
  ProfilesVerifyEmail,
  ProfileChangeRole,
} from 'components/pages/Profiles'

import { RoutesProvider, useRoute } from './helper'
export { useRoutes, externalLinks } from './helper'

export default function Routes() {
  const RouteItems = (): Route[] => [
    useRoute('dashboard', { path: '/', component: Dashboard }),
    useRoute('order', { path: '/order/:id', component: Order }),
    useRoute('orders', { path: '/orders', component: Orders }),
    useRoute('events', { path: '/events', component: Events }),
    useRoute('invites', { path: '/invites', component: Invites }),
    useRoute('cashOut', { path: '/cashout/:id', component: CashOut }),
    useRoute('credits', { path: '/credits', component: Credits }),
    useRoute('cashOuts', { path: '/cashouts', component: CashOuts }),
    useRoute('article', { path: '/article/:id', component: Article }),
    useRoute('articles', { path: '/articles', component: Articles }),
    useRoute('orderItem', { path: '/order-item/:id', component: OrderItem }),
    useRoute('orderItems', { path: '/order-items', component: OrderItems }),

    useRoute('catalogProducts', {
      path: '/catalog/products',
      component: Products,
    }),
    useRoute('catalogProduct', {
      path: '/catalog/products/:id',
      component: Product,
    }),
    useRoute('catalogEditions', {
      path: '/catalog/editions',
      component: Editions,
    }),
    useRoute('catalogEdition', {
      path: '/catalog/editions/:id',
      component: Edition,
    }),
    useRoute('catalogBackupJobs', {
      path: '/catalog/imports',
      component: BackupJobs,
    }),
    useRoute('catalogBackupJob', {
      path: '/catalog/import/:id',
      component: BackupJob,
    }),
    useRoute('catalogImages', { path: '/catalog/images', component: Images }),
    useRoute('catalogImage', { path: '/catalog/images/:id', component: Image }),
    useRoute('catalogEpubs', { path: '/catalog/epubs', component: Epubs }),
    useRoute('catalogEpub', { path: '/catalog/epubs/:id', component: Epub }),
    useRoute('catalogSuppliers', {
      path: '/catalog/suppliers',
      component: Suppliers,
    }),
    useRoute('catalogSupplier', {
      path: '/catalog/suppliers/:id',
      component: Supplier,
    }),
    useRoute('catalogFtp', { path: '/catalog/ftp', component: Ftp }),

    useRoute('bookshopCredits', {
      path: '/bookshop-credits',
      component: BookshopCredits,
    }),
    useRoute('blockUsers', { path: '/block-users', component: BlockUsers }),
    useRoute('reportedPosts', {
      path: '/reported-posts',
      component: ReportedPosts,
    }),
    useRoute('reportedPost', {
      path: '/reported-posts/:id',
      component: ReportedPost,
    }),
    useRoute('reportedArticles', {
      path: '/reported-articles',
      component: ReportedArticles,
    }),
    useRoute('createOrder', {
      path: '/profiles/:username/create-order',
      component: CreateOrder,
    }),
    useRoute('profile', {
      path: '/profiles/:username',
      component: ProfilesDetail,
    }),
    useRoute('profiles', { path: '/profiles', component: ProfilesIndex }),
    useRoute('profileCredits', {
      path: '/profiles/:username/credits',
      component: ProfilesCredits,
    }),
    useRoute('profileAddCredits', {
      path: '/profiles/:username/credits/add',
      component: ProfilesAddCredits,
    }),
    useRoute('ProfileDeleteAccount', {
      path: '/profiles/:username/delete',
      component: ProfilesDeleteAccountLayout,
    }),
    useRoute('profileInvites', {
      path: '/profiles/:username/invites',
      component: ProfilesInvites,
    }),
    useRoute('profileOrderItems', {
      path: '/profiles/:username/order-items',
      component: ProfilesOrderItems,
    }),
    useRoute('profileOrders', {
      path: '/profiles/:username/orders',
      component: ProfilesOrders,
    }),
    useRoute('profileCashouts', {
      path: '/profiles/:username/cashouts',
      component: ProfilesCashouts,
    }),
    useRoute('profileEvents', {
      path: '/profiles/:username/events',
      component: ProfilesEvents,
    }),
    useRoute('profileChangeEmail', {
      path: '/profiles/:username/changeEmail',
      component: ProfilesChangeEmail,
    }),
    useRoute('profileVerifyEmail', {
      path: '/profiles/:username/verify',
      component: ProfilesVerifyEmail,
    }),
    useRoute('profileGetAddress', {
      path: '/profiles/:username/get-address',
      component: ProfilesGetAddress,
    }),
    useRoute('profileChangeRole', {
      path: '/profiles/:username/change-role',
      component: ProfileChangeRole,
    }),
    useRoute('relatedIdentifiers', {
      path: '/related-identifiers/',
      component: RelatedIdentifiers,
    }),
  ]

  return (
    <RoutesProvider>
      <Switch>
        {/* @ts-ignore HOTFIX   */}
        <RouteItems />
      </Switch>
    </RoutesProvider>
  )
}
