
import { useState, useEffect } from 'react'

import {
  format,
  addMonths,
  subMonths,
  eachDayOfInterval,
  eachWeekOfInterval,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek
} from 'date-fns'

export const useEachWeekOfMonth = (month: Date) => {
  const getWeeks = (month: Date) =>
    eachWeekOfInterval({
      start: startOfMonth(month),
      end: endOfMonth(month)
    })

  const getDays = (week: Date) =>
    eachDayOfInterval({
      start: startOfWeek(week),
      end: endOfWeek(week)
    })

  return getWeeks(month).map(getDays)
}

export const useCalendar = ({ selectedDay, onSelectionChange }: { selectedDay: Date, onSelectionChange: (date: Date) => void }) => {

  const now = new Date()
  const [date, setDate] = useState<Date>(new Date())
  const [selected, setSelected] = useState<Date>(selectedDay)

  useEffect(() => {
    setSelected(selectedDay)
  }, [selectedDay])

  return {
    now,
    date,
    selected,
    setSelected,
    month: format(date, 'MMMM'),
    year: format(date, 'yyyy'),
    prevMonth: () => setDate(subMonths(date, 1)),
    nextMonth: () => setDate(addMonths(date, 1)),
  }
}
