import React from 'react'
import { useAuth } from 'context/authContext'
import { Button, Text } from '@adobe/react-spectrum'
import LogOutIcon from '@spectrum-icons/workflow/LogOut'

interface LogoutProps {
  className?: string
}

export default function Logout({ className }: LogoutProps) {
  const { logout } = useAuth()

  const handleLogout = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    logout()
  }

  return (
    <form action="/" onSubmit={handleLogout} className={className}>
      <Button type="submit" variant="primary">
        <LogOutIcon />
        <Text>Logout</Text>
      </Button>
    </form>
  )
}
