import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import ChevronRight from '@spectrum-icons/ui/ChevronRightMedium'
import '@spectrum-css/breadcrumb/dist/index-vars.css'
import styles from './Breadcrumbs.module.css'

interface IBreadcrumbsItem {
  to: string
  className?: string
}

interface IBreadcrumbs {
  className?: string
}

export const Breadcrumbs: React.FC<IBreadcrumbs> = (props) => {
  let { children, className } = props
  className = classNames('spectrum-Breadcrumbs', className)
  return (
    <nav>
      <ul className={className}>{children}</ul>
    </nav>
  )
}

export const Item: React.FC<IBreadcrumbsItem> = (props) => {
  let { to, children, className } = props
  className = classNames('spectrum-Breadcrumbs-item', styles.item, className)
  return (
    <li className={className}>
      <Link to={to} className="spectrum-Breadcrumbs-itemLink">
        {/* @ts-ignore */}
        {children}
      </Link>
      <ChevronRight UNSAFE_className="spectrum-Breadcrumbs-itemSeparator" />
    </li>
  )
}
