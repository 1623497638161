import ArticlesTable from 'components/Tables/ArticlesTable'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { View } from '@adobe/react-spectrum'

const Articles = () => {
  return (
    <Layout>
      <Content heading="Articles">
        <View>
          <ArticlesTable />
        </View>
      </Content>
    </Layout>
  )
}

export default Articles
