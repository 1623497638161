import React from 'react';
import BasicTable from 'components/Table/BasicTable'
import columns from './columns'

const BookstoreCreditsTable = ({ bookstoreCredits }: { bookstoreCredits: any[] }) => {
  return (
    <section>
      <BasicTable columns={ columns } data={ bookstoreCredits } />
    </section>
  )
}

export default BookstoreCreditsTable;
