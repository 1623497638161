import config from 'config'

export class AuthFailure {
  constructor(message: string) {
    this.message = message
  }
  message: string
}

export class SigninSuccess {
  constructor(accessToken: string) {
    this.accessToken = accessToken
  }
  accessToken: string
}

export class MeSuccess {
  constructor(username: string, isAdmin: boolean) {
    this.username = username
    this.isAdmin = isAdmin
  }
  username: string
  isAdmin: boolean
}

const me = async (accessToken: string): Promise<MeSuccess | AuthFailure> => {
  try {
    // FIXME: This function runs twice on each page load

    const rawResponse = await fetch(config.meUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
    })
    const content = await rawResponse.json()
    if (content.username) {
      return new MeSuccess(content.username, content.isAdmin)
    } else {
      return new AuthFailure(content.message || 'User not found')
    }
  } catch(error) {
    if (error instanceof Error) {
      return new AuthFailure(error.message || 'Error fetching user')
    } else {
      return new AuthFailure('Error fetching user')
    }
  }
}

const signin = async (username: string, password: string): Promise<SigninSuccess | AuthFailure> => {
  try {
    const rawResponse = await fetch(config.signInUrl, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({username, password})
    })
    const content = await rawResponse.json()
    if (content.accessToken) {
      return new SigninSuccess(content.accessToken)
    } else {
      return new AuthFailure(content.message || 'Access token not found')
    }
  } catch(error) {
    if (error instanceof Error) {
      return new AuthFailure(error.message || 'Error fetching user')
    } else {
      return new AuthFailure('Error fetching user')
    }
  }
}

export {
  signin,
  me
}
