
import React, { useState, createContext, useContext, ReactNode } from 'react'
import ToastPortal from './ToastPortal'
import Toast, { Variants } from './Toast'
import { uniqueId } from 'lib/utils'

interface IToastContext {
  toasts: any,
  addToast: (props: any) => void
}

const defaultValue: IToastContext = {
  toasts: {},
  addToast: (props: any) => undefined
}

const ToastContext = createContext<IToastContext>(defaultValue)

export const useToast = () => {
  const ctx = useContext(ToastContext)
  if (!ctx) throw Error('The `useToast` hook must be called from a descendent of the `ToastProvider`.')
  return ctx
}

export const ToastProvider = ({ children, ...props }: any) => {
  const [toasts, setToasts] = useState<{ [id: string]: ReactNode }>({})

  const addToast = ({ variant, message, timeout = 5000 }: { variant: Variants | undefined, message: string, timeout: number }) => {
    const id = uniqueId('toast')
    const onClose = () => {  }
    const props = { key: id, variant, onClose, timeout}
    const newToast = <Toast { ...props }>{ message }</Toast>

    setToasts({
      ...toasts,
      [id]: newToast
    })
  }

  const toastContextValue:IToastContext = { toasts, addToast }

  return (
    <ToastContext.Provider value={ toastContextValue }>
      { children }
      <ToastPortal>
        { Object.keys(toasts).map(key => toasts[key]) }
      </ToastPortal>
    </ToastContext.Provider>
  )
}
