import { isString, FilterType } from 'lib/table'
import { textFieldFactory } from 'components/Table/FilterFactories'

const filters: FilterType[] = [
  {
    display: 'Article id',
    name: 'article_id',
    filter: textFieldFactory({
      label: 'Article Id',
      placeholder: 'Enter an article id',
      type: 'string',
    }),
    mapping: (value: string): string => value,
    validator: isString,
  },
  {
    display: 'Username',
    name: 'authorUsername',
    filter: textFieldFactory({
      label: 'Author Username',
      placeholder: 'Enter an username',
      type: 'string',
    }),
    mapping: (value: string): string => value,
    validator: isString,
  },
]

export default filters
