import React, { useState, useRef, useMemo, useCallback } from 'react'
import Table from 'components/Table/Table'
import columnsConfiguration from './columns'
import { useApolloClient } from '@apollo/client'
import { CASHOUT_LIST_QUERY } from 'api/cashouts'
import filterTypes from './filters'
import { FetchDataProps } from 'lib/table'

const CashOutTable: React.FC<{ username?: string }> = ({ username }) => {
  const client = useApolloClient()

  const [data, setData] = useState<any[]>([])
  const [filters, setFilters] = useState<any[]>(
    username ? [{ customer: username }] : []
  )
  const [sortBy, setSortBy] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [page, setPage] = useState(0)
  const fetchIdRef = useRef(0)

  const columns = useMemo(() => columnsConfiguration, [])

  const fetchData = useCallback(
    async ({ pageSize, pageIndex, sortBy, filters }: FetchDataProps) => {
      setFilters(filters)
      setSortBy(sortBy)
      setPage(pageIndex)

      const fetchId = ++fetchIdRef.current
      setLoading(true)
      try {
        const sort = sortBy[0]
        const sortString = sort
          ? `${sort.id} ${sort.desc ? 'DESC' : 'ASC'}`
          : null
        const results = await client.query({
          query: CASHOUT_LIST_QUERY,
          variables: {
            skip: pageSize * pageIndex,
            limit: pageSize,
            sort: sortString,
            filter: filters,
            type: 'BUI',
          },
        })
        if (fetchId === fetchIdRef.current) {
          setData(results.data.getCashOuts.list)
          const total = results.data.getCashOuts.count
          setPageCount(Math.ceil(total / pageSize))
          setLoading(false)
        }
      } catch (e) {
        console.error('Error fetching data', e)
        setError(true)
      }
    },
    [client]
  )

  return (
    <div>
      <Table
        columns={columns}
        filterTypes={filterTypes}
        data={data}
        loading={loading}
        fetchData={fetchData}
        pageCount={pageCount}
        page={page}
        filters={filters}
        setFilters={setFilters}
        sortBy={sortBy}
        error={error}
      />
    </div>
  )
}

export default CashOutTable
