import React, { useState, useEffect } from 'react'

import '@spectrum-css/pagination/dist/index-vars.css'
import '@spectrum-css/actionbutton/dist/index-vars.css'
import '@spectrum-css/textfield/dist/index-vars.css'
import ChevronLeft from '@spectrum-icons/ui/ChevronLeftMedium'
import ChevronRight from '@spectrum-icons/ui/ChevronRightMedium'

import { TextField, Picker, Item } from '@adobe/react-spectrum'
import './Pagination.css'


export default function Pagination(props: any) {
  const {
    table: {
      canNextPage,
      gotoPage,
      nextPage,
      pageCount,
      previousPage,
      canPreviousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    }
  } = props;

  const [page, setPage] = useState(pageIndex + 1)

  useEffect(() => {
    setPage(pageIndex + 1)
  }, [pageIndex])

  // hide pagination if paging not possible
  if (!canPreviousPage && !canNextPage) return null


  return (
    <div className="pagination">
      <Picker
        isQuiet
        label="Rows per page:"
        labelPosition="side"
        labelAlign="end"
        selectedKey={pageSize.toString()}
        onSelectionChange={selected => setPageSize(Number(selected))}>
        {[10, 20, 30, 40, 50].map(pageSize =>
          <Item key={pageSize}>{pageSize.toString()}</Item>
        )}
      </Picker>

      <div className="spectrum-Pagination spectrum-Pagination--explicit">
        <button
          disabled={!canPreviousPage}
          onClick={() => previousPage()}
          className="spectrum-ActionButton spectrum-ActionButton--sizeM spectrum-ActionButton--quiet spectrum-Pagination-prevButton">
          <ChevronLeft aria-label="last page" />
        </button>
        <div className="spectrum-Textfield spectrum-Pagination-textfield">
          <TextField
            aria-label="page picker"
            type="number"
            name="page"
            value={page}
            onChange={value => setPage(value)}
            onKeyUp={e => {
              if (e.key === 'Enter' || e.keyCode === 13) {
                const index = (page && page !== '0') ? Number(page) - 1 : -1
                if (index >= 0) gotoPage(index)
              }
            }}/>
        </div>
        <span className="spectrum-Body--secondary spectrum-Pagination-counter">of {pageCount} pages</span>
        <button
          disabled={!canNextPage}
          onClick={() => nextPage()}
          className="spectrum-ActionButton spectrum-ActionButton--sizeM spectrum-ActionButton--quiet spectrum-Pagination-nextButton">
          <ChevronRight aria-label="next page" />
        </button>
      </div>
    </div>
  )
}
