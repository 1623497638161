import { useState } from 'react'
import { ProgressBar } from '@adobe/react-spectrum'
import { useAuth } from 'context/authContext'
import { useParams } from 'react-router-dom'
import { useRoutes } from 'routes/helper'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { Breadcrumbs, Item } from 'components/modules/Breadcrumbs'
import { useEffect } from 'react'
import { fetchArticle } from 'api/details/article'
import ArticleTable from 'components/containers/ArticleTable/ArticleTable'
import { deleteArticle, undeleteArticle } from 'api/moderation/articles'
import { useToast } from 'components/modules/Toast'
import { ActionButton, AlertDialog, DialogTrigger } from '@adobe/react-spectrum'

export default function Article() {
  const { id } = useParams<{ id: string }>()
  const { accessToken } = useAuth()
  const {
    articlesPath,
    articlePath,
  } = useRoutes()

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<any | undefined>(undefined)
  const [error, setError] = useState<string | undefined>()
  const { addToast } = useToast()

  const loadData = async () => {
    try {
      const result: any = await fetchArticle(id, accessToken)
      setData(result)
      setLoading(false)
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message)
      } else {
        setError('Error loading data')
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      await loadData()
    })()
  }, [accessToken, id])

  const onDelete = async () => {
    try {
      const response = await deleteArticle(id, accessToken)

      const { success, message } = response

      if (success) {
        addToast({
          variant: 'positive',
          message,
          timeout: 6000,
        })
      }

      if (!success) {
        addToast({
          variant: 'negative',
          message,
          timeout: 6000,
        })
      }
      loadData()
    } catch (error) {
      addToast({
        variant: 'negative',
        message: 'Error deleting article',
        timeout: 6000,
      })
    }
  }

  const onUndelete = async () => {
    try {
      const response = await undeleteArticle(id, accessToken)

      const { success, message } = response

      if (success) {
        addToast({
          variant: 'positive',
          message,
          timeout: 6000,
        })
      }

      if (!success) {
        addToast({
          variant: 'negative',
          message,
          timeout: 6000,
        })
      }
      loadData()
    } catch (error) {
      addToast({
        variant: 'negative',
        message: 'Error deleting article',
        timeout: 6000,
      })
    }
  }

  const renderDelete = () => {
    if (!data.deletedAt) {
      return (
        <DialogTrigger>
          <ActionButton>Delete</ActionButton>
          <AlertDialog
            title="Undelete the current article"
            variant="destructive"
            primaryActionLabel="Confirm"
            cancelLabel="Cancel"
            autoFocusButton="cancel"
            onPrimaryAction={() => onDelete()}
          >
            Would you like to undelete this article?
          </AlertDialog>
        </DialogTrigger>
      )
    } else {
      return (
      <DialogTrigger>
        <ActionButton>Undelete</ActionButton>
        <AlertDialog
          title="Undelete the current article"
          variant="destructive"
          primaryActionLabel="Confirm"
          cancelLabel="Cancel"
          autoFocusButton="cancel"
          onPrimaryAction={() => onUndelete()}
        >
          Would you like to undelete this article?
        </AlertDialog>
      </DialogTrigger>
      )
    }
  }

  const renderArticleData = () => {
    if (loading) return <ProgressBar label="Loading…" isIndeterminate />
    if (error) return <p>{error}</p>
    if (!data) return <p>Not record found</p>

    return (
      <div>
        <section>
          <ArticleTable article={data} />
        </section>
        <section>
          { renderDelete() }
        </section>
      </div>
    )
  }

  return (
    <Layout>
      <Content variant="article" heading="article">
        <Breadcrumbs>
          <Item to={articlesPath()}>Articles</Item>
          <Item to={articlePath({ id })}>Articles</Item>
        </Breadcrumbs>
        {renderArticleData()}
      </Content>
    </Layout>
  )
}
