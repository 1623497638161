import React, { useState, useMemo, useEffect, Key } from 'react'
import config from 'config'
import Table from 'components/Table/Table'
import columnsConfiguration from './columns'
import { useToast } from 'components/modules/Toast'
import { useAuth } from 'context/authContext'
import { FetchDataProps } from 'lib/table'
import { Picker, Item, ActionButton, Text } from '@adobe/react-spectrum'

interface IBlockUsersTable {
  username: string
}

const BlockUsersTable = ({ username }: IBlockUsersTable) => {
  const { accessToken } = useAuth()
  const [filters, setFilters] = useState<any[]>([])
  const [, setSortBy] = useState<any[]>([])
  const [page, setPage] = useState(0)
  const [data, setData] = useState<any[]>([])
  const [pageCount, setPageCount] = useState(1)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [emptySearch, setEmptySearch] = useState(false)

  useEffect(() => {
    if (data.length === 0) {
      setEmptySearch(true)
    } else {
      setEmptySearch(false)
    }
  }, [data])

  const columns = useMemo(() => columnsConfiguration, [])

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sortBy, filters }: FetchDataProps) => {
      setFilters(filters)
      setSortBy(sortBy)
      setPage(pageIndex)
      setLoading(true)
      try {
        const url = `${config.blockSearchUrl}${username}`
        const res = await fetch(url, {
          method: 'GET',
          mode: 'cors',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
        const json = await res.json()
        setData(json)
        if (json.length === pageSize) setPageCount(pageCount + 1)
      } catch (e) {
        console.error('Error fetching data', e)
        setError(true)
      }
      setLoading(false)
    },
    [username, accessToken, pageCount, setData]
  )

  const QuickActions = ({ row, table }: any) => {
    const { addToast } = useToast()
    const [payload, setPayload] = useState({
      blockedProfile: row.original.username,
      blockedUntil: 0,
    })
    const { blockedUntil } = payload

    const blockUser = async () => {
      setLoading(true)
      try {
        await fetch(config.blockUrl, {
          method: 'POST',
          mode: 'cors',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(payload),
        })
        await fetchData(table.state)
        addToast({ message: 'User successfully blocked!', variant: 'positive' })
      } catch (err) {
        console.error(err)
        addToast({ message: 'Ups something went wrong!', variant: 'negative' })
      }
      setLoading(false)
    }

    return (
      <div>
        <Picker
          aria-label="Pick duration of block time"
          placeholder="Choose Block Duration"
          onSelectionChange={(key: Key) => {
            setPayload({ ...payload, blockedUntil: parseInt(key.toString()) })
          }}
        >
          <Item key={1}>One day</Item>
          <Item key={7}>One Week</Item>
          <Item key={30}>One Month</Item>
          <Item key={365}>One year</Item>
          <Item key={9125}>25 years</Item>
        </Picker>
        <ActionButton
          marginStart={20}
          isDisabled={blockedUntil === 0 || loading}
          onPress={blockUser}
        >
          Block User
        </ActionButton>
      </div>
    )
  }

  const renderQuickActions = (row: any, table: any) => {
    return <QuickActions row={row} table={table} />
  }

  return (
    <div>
      {emptySearch ? <Text>No user was found under this name</Text> : null}
      <Table
        columns={columns}
        page={page}
        pageCount={pageCount}
        data={data}
        sortBy={[]}
        loading={loading}
        filterTypes={[]}
        fetchData={fetchData}
        quickactions={renderQuickActions}
        error={error}
        filters={filters}
        setFilters={setFilters}
      />
    </div>
  )
}

export default BlockUsersTable
