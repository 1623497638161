import { Breadcrumbs, Item } from 'components/modules/Breadcrumbs'
import { Layout, Content } from 'components/layouts/LoggedIn'
import EventsTable from 'components/Tables/EventsTable'
import { useParams } from 'react-router-dom'
import { useRoutes } from 'routes'

const ProfileEventsLayout = () => {
  const { username } = useParams<{ username: string }>();
  const { profilePath, profilesPath, profileEventsPath } = useRoutes()
  return (
    <Layout>
      <Content variant='article' heading={ username }>
        <Breadcrumbs>
          <Item to={ profilesPath() }> Profiles </Item>
          <Item to={ profilePath({ username }) }> { username } </Item>
          <Item to={ profileEventsPath({ username }) }> events </Item>
        </Breadcrumbs>
        <ProfileEvents />
      </Content>
    </Layout>
  )
}

const ProfileEvents = () => {
  const { username } = useParams<{ username: string }>();
  return (
    <div>
      <p />
      <EventsTable username={ username } />
    </div>
  )
}

export default ProfileEventsLayout
