import { useReducer } from 'react'
import { useMutation } from '@apollo/client'
import { Breadcrumbs, Item } from 'components/modules/Breadcrumbs'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { ADD_CREDITS_MUTATION } from 'api/credits'
import { useParams } from 'react-router-dom'
import { useRoutes } from 'routes'
import { NumberField, TextField, ActionButton, Flex } from '@adobe/react-spectrum'
import { useToast } from 'components/modules/Toast'

const ProfileAddCreditsLayout = () => {
  const { username } = useParams<{ username: string }>();
  const { profilePath, profilesPath, profileCreditsPath, profileAddCreditsPath } = useRoutes()
  return (
    <Layout>
      <Content variant='article' heading='Add Credits'>
        <Breadcrumbs>
          <Item to={ profilesPath() }> Profiles </Item>
          <Item to={ profilePath({ username }) }> { username } </Item>
          <Item to={ profileCreditsPath({ username }) }> Credits </Item>
          <Item to={ profileAddCreditsPath({ username }) }> Add/Remove Credits </Item>
        </Breadcrumbs>
        <ProfileAddCredits />
      </Content>
    </Layout>
  )
}

const enum ActionKind {
  SET_CREDITS = 'SET_AMOUNT',
  SET_DESCRIPTION = 'SET_DESCRIPTION',
  RESET = 'RESET'
}

const initialState: State = {
  description: '',
  credits: 0
}

interface State {
  description: string
  credits: number
}

interface Action {
  type: ActionKind
  value?: any
}

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionKind.SET_CREDITS:
      return { ...state, credits: action.value }
    case ActionKind.SET_DESCRIPTION:
      return { ...state, description: action.value }
    case ActionKind.RESET:
      return initialState
  }
}

const ProfileAddCredits = () => {
  const { addToast } = useToast()
  const { username } = useParams<{ username: string }>();
  const [state, dispatch] = useReducer(reducer, initialState)
  const onCompleted = () => {
    dispatch({ type: ActionKind.RESET })
    addToast({ variant: 'positive', message: 'Credits updated', timeout: 2500 })
  }
  const onError = () => addToast({ variant: 'negative', message: 'Credits update failed' })
	const [ addCredits ] = useMutation(ADD_CREDITS_MUTATION, { onCompleted, onError })
  const disabled = (state.credits === 0 || state.description === '')

  const addCreditsClick = () => {
    const meta = {
      en: state.description,
      de: state.description
    }
    const credits = state.credits

    addCredits({ variables: { username, meta, credits  } })
  }

  return (
    <div>
      <p>Add credits to a profile. Credits can be taken by adding negative credits.</p>
      <Flex direction='column' gap='size-200'>
        <TextField
          onChange = { (value) => dispatch({ type: ActionKind.SET_DESCRIPTION, value: value }) }
          label='Description'
          value={ state.description }
        />
        <NumberField
          onChange = { (value) => dispatch({ type: ActionKind.SET_CREDITS, value: value }) }
          label='Credits'
          value={ state.credits }
        />
        <ActionButton
          width={ 150 }
          isDisabled={ disabled }
          onPress={ () => addCreditsClick() }>
          Add
        </ActionButton>
      </Flex>
    </div>
  )
}

export default ProfileAddCreditsLayout
