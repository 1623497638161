import { isString, isInteger, isDate, mapDate, FilterType } from 'lib/table'
import { textFieldFactory, pickerFactory, datePickerFactory } from 'components/Table/FilterFactories'

const filters: FilterType[] = [
  {
    display: 'Id',
    name: 'id',
    filter: textFieldFactory({
      label: 'id',
      placeholder: 'Enter id...'
    }),
    mapping: parseInt,
    validator: isInteger
  },
  {
    display: 'Username',
    name: 'username',
    filter: textFieldFactory({
      label: 'Username',
      placeholder: 'Enter username...'
    }),
    mapping: (value:string):string => value,
    validator: isString
  },
  {
    display: 'Event',
    name: 'event',
    filter: pickerFactory({
      label: 'Pick event',
      items: [
				'SIGNUP_BONUS',
				'PROFILE_COMPLETION',
				'INSTALL_CHROME_PLUGIN',
				'INSTALL_ANDROID_APP',
				'INSTALL_IOS_APP',
				'SHARE',
				'INVITE_SUCCESS',
				'RATE',
				'REVIEW',
				'REDEEM_VOUCHER',
				'PURCHASE',
				'COUPON_APPLIED',
				'MOJO',
				'SHIPPING',
				'CASH_OUT',
			]
    }),
    mapping: (value:string):string => value,
    validator: isString
  },
  {
    display: 'Credits Equal',
    name: 'credits_equal',
    field: 'credits',
    operation: 'eq',
    filter: textFieldFactory({
      label: 'id',
      placeholder: 'Credit value...'
    }),
    mapping: parseInt,
    validator: isInteger
  },
  {
    display: 'Credits Less Than',
    name: 'credits_lte',
    field: 'credits',
    operation: 'lte',
    filter: textFieldFactory({
      label: 'id',
      placeholder: 'Max credit value...'
    }),
    mapping: parseInt,
    validator: isInteger
  },
  {
    display: 'Credits More Than',
    name: 'credits_gte',
    field: 'credits',
    operation: 'gte',
    filter: textFieldFactory({
      label: 'id',
      placeholder: 'Min credit value...'
    }),
    mapping: parseInt,
    validator: isInteger
  },
  {
    display: 'Created After',
    name: 'since',
    field: 'createdAt',
    operation: 'gte',
    filter: datePickerFactory({
      label: 'Choose a date',
      placeholder: 'dd.mm.yyyy'
    }),
    mapping: mapDate,
    validator: isDate
  },
  {
    display: 'Created Before',
    name: 'after',
    field: 'createdAt',
    operation: 'lte',
    filter: datePickerFactory({
      label: 'Choose a date',
      placeholder: 'dd.mm.yyyy'
    }),
    mapping: mapDate,
    validator: isDate
  }
]

export default filters
