import React, { useState, useEffect, Key } from 'react'
import DeleteIcon from '@spectrum-icons/workflow/Delete'
import { formatValue } from 'lib/format'
import styles from './FilterModal.module.css'

import {
  Button,
  ButtonGroup,
  Content,
  Header,
  Dialog,
  Divider,
  Heading,
  Text,
  Picker,
  Item,
  Flex,
} from '@adobe/react-spectrum'

type FilterType = {
  key: string
  value?: any
}

type FiltersType = {
  [key: string]: any
}

interface FilterModalProps {
  filter?: FilterType
  filters: FiltersType
  setFilters: any
  filterTypes: any[]
  close: any
}

interface MapItem {
  name: string
  display: any
}

const FilterModal = (props: FilterModalProps) => {
  const { filter, filters, setFilters, filterTypes, close } = props

  const [canSave, setCanSave] = useState<boolean>(false)
  const [canUpdate, setCanUpdate] = useState<boolean>(!!filter)
  const [selectedFilter, setSelectedFilter] = useState<FilterType | undefined>(filter)

  const getFilterType = ({ key }: { key: string }) => filterTypes.find((el) => el.name === key)

  useEffect(() => {
    setSelectedFilter(filter)
    setCanUpdate(!!filter)
  }, [filter])

  useEffect(() => {
    if (!selectedFilter) return
    const { key, value } = selectedFilter
    const { validator } = filterTypes.find((el) => el.name === key)
    setCanSave(value && validator(value))
  }, [selectedFilter, filterTypes])

  const addFilter = ({ key, value }: { key: string, value: any }) => {
    const tmp = { ...filters, [key]: value }
    setFilters(tmp)
  }

  const deleteFilter = (filter: FilterType) => {
    if (!filter) return;
    const tmp = { ...filters }
    delete tmp[filter.key]
    setFilters(tmp)
  }

  const cancelHandler = () => {
    close()
  }

  const deleteHandler = () => {
    if (filter) {
      deleteFilter(filter)
    }
    close()
  }

  const updateHandler = () => {
    if (!canSave) return
    if (selectedFilter) {
      const { key, value } = selectedFilter
      const { mapping } = getFilterType(selectedFilter)
      addFilter({ key, value: mapping(value) })
    }
    close()
  }

  const selectFilterHandler = (key: Key) => {
    setSelectedFilter({ key: key.toString() })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    updateHandler()
  }

  const renderFilterValue = () => {
    if (!selectedFilter) return null
    const { key, value } = selectedFilter
    const { filter: Filter } = getFilterType(selectedFilter)
    return (
      <form action="/" onSubmit={handleSubmit}>
        <Filter
          filterValue={value}
          setFilter={(value: any) => setSelectedFilter({ key, value })}
        />
      </form>
    )
  }

  return (
    <Dialog>
      <Heading>{canUpdate ? 'Update filter' : 'Add new filter'}</Heading>
      {filter && (
        <Header>
          {getFilterType(filter).display} {formatValue(filter.value)}
        </Header>
      )}
      <Divider />
      <Content>
        <Flex direction="row" gap="size-100" alignItems="end">
          <Picker
            isDisabled={canUpdate}
            items={filterTypes}
            selectedKey={selectedFilter?.key}
            onSelectionChange={selectFilterHandler}
            label="Choose filter"
          >
            {(item: MapItem) => <Item key={item.name}>{item.display}</Item>}
          </Picker>
          {renderFilterValue()}
        </Flex>
      </Content>

      <ButtonGroup>
        {canUpdate && (
          <Button
            variant="negative"
            onPress={deleteHandler}
            UNSAFE_className={styles.deleteBtn}
            isQuiet
          >
            <DeleteIcon />
            <Text>Delete filter</Text>
          </Button>
        )}
        <Button variant="secondary" onPress={cancelHandler}>
          Cancel
        </Button>
        <Button variant="cta" onPress={updateHandler} isDisabled={!canSave}>
          Confirm
        </Button>
      </ButtonGroup>
    </Dialog>
  )
}

export default FilterModal
