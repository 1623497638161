import * as Cells from 'components/Table/Cells'

const columns = [
  {
    Header: 'id',
    accessor: 'id',
    disableSortBy: true,
    cellRenderer: Cells.BackupJobLinkCell
  },
  {
    Header: 'Supplier Id',
    accessor: 'supplier_id',
    disableSortBy: true,
    cellRenderer: Cells.SupplierLinkCell
  },
  {
    Header: 'FTP Id',
    accessor: 'dropbox_id',
    disableSortBy: true,
  },
  {
    Header: 'Filename',
    accessor: 'filename',
    disableSortBy: true,
  },
  {
    Header: 'Type',
    accessor: 'type',
    disableSortBy: true,
  },
  {
    Header: 'Processed',
    accessor: 'processed',
    disableSortBy: true,
    cellRenderer: Cells.ProcessedCell
  },
  {
    Header: 'Time',
    accessor: 'download_time',
    disableSortBy: true,
    cellRenderer: Cells.DateCell
  },
]

export default columns
