import React, { useState } from 'react'
import AddIcon from '@spectrum-icons/workflow/Add'
import { useToast } from 'components/modules/Toast'
import { searchISBN } from 'api/books'
import { Edition } from 'types/edition'

import {
  ProgressCircle,
  TextField,
  ActionButton,
  Text,
  Flex,
} from '@adobe/react-spectrum'

export const AddItem = ({ items, setItems }: any) => {
  const [isbn, setIsbn] = useState<string>('9783955307400')
  const [loading, setLoading] = useState<boolean>(false)
  const { addToast } = useToast()

  const addItem = async () => {
    try {
      setLoading(true)
      const editions: Edition[] = await searchISBN(isbn)
      const length = editions.length

      if (length === 0)
        addToast({
          message: `Edition not found with ISBN ${isbn}`,
          variant: 'error',
        })
      if (length === 1)
        addToast({
          message: `ISBN found, adding item to order`,
          variant: 'info',
        })
      if (length > 1)
        addToast({
          message: `Mulitple editions with ${isbn} found, taking first`,
          variant: 'info',
        })

      if (length) setItems([...items, { edition: editions[0], quantity: 1 }])
      setLoading(false)
    } catch (e) {
      console.log('error', e)
      addToast({ message: 'Error searching for ISBN', variant: 'error' })
      setLoading(false)
    }
  }

  const LoadingIcon = () => (
    <ProgressCircle
      size="S"
      marginEnd="size-150"
      marginStart="size-150"
      aria-label="Loading…"
      isIndeterminate
    />
  )

  return (
    <Flex direction="row" gap="size-150">
      <TextField
        isReadOnly={loading}
        label="ISBN"
        placeholder="Enter ISBN..."
        labelPosition="side"
        value={isbn}
        onChange={setIsbn}
      />
      <ActionButton onPress={addItem} isDisabled={loading}>
        {loading ? <LoadingIcon /> : <AddIcon />}
        <Text> Add to list </Text>
      </ActionButton>
    </Flex>
  )
}
