import '@spectrum-css/card/dist/index-vars.css'
import { ReactNode } from 'react'

const cardStyle = {
  width: '100%',
  background: 'var(--spectrum-global-color-gray-75)',
}

export const Preview = ({ children }: { children: ReactNode }) => <div className='spectrum-Card-preview' children={ children } />
export const Body = ({ children }: { children: ReactNode }) => <div className='spectrum-Card-body' children={ children } />
export const Header = ({ children }: { children: ReactNode }) => <div className='spectrum-Card-header' children={ children } />
export const Heading = ({ children }: { children: ReactNode }) => <div className='spectrum-Card-title spectrum-Heading spectrum-Heading--sizeXS' children={ children } />
export const Content = ({ children }: { children: ReactNode }) => <div className='' children={ children } />

export const Card = ({ heading, children }: { children: ReactNode, heading: String }) => {
  return (
    <div className='spectrum-Card' tabIndex={0} role='figure' style={ cardStyle }>
      <Body>
        { heading &&
          <Header>
            <Heading children={ heading } />
          </Header>
        }
        <Content>
          { children }
        </Content>
      </Body>
    </div>

  )
}

export default Card
