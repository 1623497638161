
import { useState, useEffect, Key } from 'react'
import { Layout, Content } from 'components/layouts/LoggedIn'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { Breadcrumbs, Item as BreadcrumbsItem } from 'components/modules/Breadcrumbs'
import { useRoutes } from 'routes'
import { Table, Item } from 'components/modules/KeyValueTable'
import { formatDate } from 'lib/format'
import { useToast } from 'components/modules/Toast'
import {
  CASHOUT_DETAILS_QUERY,
  UPDATE_CASH_OUT_MUTATION
} from 'api/cashouts'

import { ActionGroup, Item as ActionItem, ProgressBar } from '@adobe/react-spectrum'

const useUpdateCashoutData = () => {
  const { addToast } = useToast()
  const onCompleted = () => addToast({ variant: 'positive', message: 'Record successfully updated', timeout: 2500 })
  const onError = () => addToast({ variant: 'negative', message: 'Record update failed' })
  return useMutation(UPDATE_CASH_OUT_MUTATION, { onCompleted, onError })
}

const useCashOutData = (id: string) => {
  const { loading, error, data } = useQuery(CASHOUT_DETAILS_QUERY, {
    variables: {
      filter: { id: parseInt(id) },
      type: 'BUI'
    }
  })
  return { loading, error, data: data?.getCashOuts?.list[0]}
}

const Status = ({ selectedStatus = '', onSelectionChange }: { selectedStatus: string, onSelectionChange: (selected: string) => void }) => {
  const [selected, setSelected] = useState<string>(selectedStatus)

  useEffect(() => {
    setSelected(selectedStatus)
  }, [selectedStatus])

  const updateStatus = (selected: Key) => {
    setSelected(selected.toString())
    if (onSelectionChange) onSelectionChange(selected.toString())
  }

  return (
    <ActionGroup isEmphasized onAction={ updateStatus } selectionMode='single' selectedKeys={ [selected] }>
      <ActionItem key='pending'> Pending </ActionItem>
      <ActionItem key='failed'> Failed </ActionItem>
      <ActionItem key='completed'> Completed </ActionItem>
      <ActionItem key='filed'> Filed </ActionItem>
    </ActionGroup>
  )
}


export default function CashOut() {
  const { id } = useParams<{ id: string }>()
  const { cashOutPath, cashOutsPath } = useRoutes()
  const { loading, error, data: cashOut } = useCashOutData(id)
  const [updateCashOutData] = useUpdateCashoutData()

  const updateStatus = (status: string) => {
    const variables = { id: parseInt(id), status: status.toUpperCase() }
    updateCashOutData({ variables })
  }

  const renderCashOutData = () => {
    if (loading) return <ProgressBar label='Loading…' isIndeterminate />
    if (error) return <div>Error fetching data</div>
    if (!cashOut) return <div>Cashout Item not found.</div>

    return (
      <Table>
        <Item label='id'>{ id }</Item>
        <Item label='Username'>{ cashOut.profile.username }</Item>
        <Item label='Display Name'>{ cashOut.profile.displayName }</Item>
        <Item label='Email'>{ cashOut.profile.email }</Item>
        <Item label='Amount'>{ cashOut.amount }</Item>
        <Item label='Credits'>{ cashOut.credits }</Item>
        <Item label='Created'>{ formatDate(cashOut.createdAt) } </Item>
        <Item label='Updated'>{ formatDate(cashOut.updatedAt) }</Item>
        <Item label='Status'>
          <Status selectedStatus={ cashOut.status } onSelectionChange={ updateStatus } />
        </Item>
      </Table>
    )
  }

  return (
    <Layout>
      <Content variant='article' heading='Cashouts'>

        <Breadcrumbs>
          <BreadcrumbsItem to={ cashOutsPath() } children={ 'Cash-outs' } />
          <BreadcrumbsItem to={ cashOutPath({ id }) } children={ id } />
        </Breadcrumbs>

        { renderCashOutData() }
      </Content>
    </Layout>
  )
}
